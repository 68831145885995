<script setup lang="ts"></script>

<template>
  <div>
    <slot />
  </div>
</template>

<style scoped lang="scss">
div {
  @apply w-12 h-12 rounded-full flex justify-center items-center select-none font-bold text-2xl cursor-pointer;
  background-color: var(--m-light);
  color: var(--m-dark);
}

div:hover {
  background-color: var(--m-blue-2);
}

div:active {
  background-color: var(--m-blue-3);
}
</style>
