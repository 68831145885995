<script lang="ts">
import { RouteNames } from "../../../../utils/constants.ts";

export function getRouteSignupVerifyEmail() {
  return {
    name: RouteNames.SIGNUP_EMAIL_VERIFY
  };
}
</script>
<script setup lang="ts">
import VueCountdown from "@chenfengyuan/vue-countdown";

import { onMounted, Ref, ref, watch } from "vue";
import { useUserStore } from "../../../../store";

import LinkButton from "../../../../components/buttons/LinkButton.vue";
import OtpInput from "../../../../components/form/OtpInput.vue";
import Signup from "../../../../components/layout/GuestLayout/Signup.vue";
import { IChallengeResponse } from "../../../../repositories/dto/user/IChallengeResponse.ts";
import { IRepositoryError } from "../../../../repositories/dto/errors/IRepositoryError.ts";
import { ISolutionResponse } from "../../../../repositories/dto/user/ISolutionResponse.ts";
import Spinner from "../../../../components/icons/Spinner.vue";
import { error, success } from "../../../../utils/toast.ts";


const userStore = useUserStore();

const challengeCode: Ref<string | null> = ref(null);
const solutionCode: Ref<string> = ref("");
const inLoading: Ref<boolean> = ref(false);
const codeResendTime: Ref<number> = ref(0);
const counting: Ref<boolean> = ref(true);
const solutionLength = 6;

watch(solutionCode, async () => {
  if (solutionCode.value.length !== solutionLength) {
    return;
  }

  await verifySolution(challengeCode.value!, solutionCode.value);
});

onMounted(async () => {
  await getChallenge();
});

async function getChallenge(previousChallenge: string | null = null) {
  inLoading.value = true;
  let challenge = await userStore.getChallenge("email", previousChallenge);
  inLoading.value = false;

  if (challenge.hasOwnProperty("timeout")) {
    challenge = challenge as IChallengeResponse;
    challengeCode.value = challenge.code;
    codeResendTime.value = challenge.timeout;
    success("Email sent");
  } else {
    challenge = challenge as IRepositoryError;
    error(challenge.description);
  }
}

async function verifySolution(challenge: string, _solutionCode: string) {
  inLoading.value = true;
  let solution = await userStore.verifySolution(challenge, _solutionCode);
  inLoading.value = false;

  if (solution.hasOwnProperty("verified")) {
    solution = solution as ISolutionResponse;
    if (solution.verified) {
      success("Email verified");
      await userStore.getState();
    } else {
      challengeCode.value = solution.challenge.code;
      codeResendTime.value = solution.challenge.timeout;
      solutionCode.value = '';
      error("Error verifying email");
    }

  } else {
    solution = solution as IRepositoryError;
    error(solution.description);
  }
}

const resendCode = async () => {
  await getChallenge(challengeCode.value!);
};
</script>

<template>
  <Signup class="relative">
    <div
      class="mx-auto mt-[40px] w-[636px] flex-col justify-center items-center gap-8 flex"
    >
      <div class="flex-col justify-start items-center gap-8 flex">
        <div
          class="text-center text-zinc-800 text-4xl font-bold font-['Sora'] leading-[38px]"
        >
          Verify Your Email
        </div>
        <div class="text-center text-zinc-800 text-base/[38px] font-normal font-['Poppins']">
          We’ve just emailed you to
          <span class="text-blue-500">
            {{ userStore.auth?.email?.email }}
          </span>
          with a verification code
        </div>
        <div class="justify-center items-start gap-8 inline-flex">
          <OtpInput :length="solutionLength" v-model="solutionCode" />
        </div>
        <div class="">
          <span class="text-zinc-800 text-base font-normal font-['Poppins'] leading-[38px]">
            Didn’t receive a code? You can send a code again in
          </span>
          <span class="text-blue-500 text-base font-normal font-['Poppins'] leading-[38px]">
            <VueCountdown
              v-if="counting"
              :time="15000"
              @end="counting = false;"
              v-slot="{ totalSeconds, totalMinutes }">
              {{ totalMinutes.toString().padStart(2, 0) }}:{{ totalSeconds.toString().padStart(2, 0) }}
            </VueCountdown>
            <span v-else>
              <LinkButton @click="resendCode">Resend</LinkButton>
            </span>
          </span>
        </div>
      </div>
    </div>
    <div v-if="inLoading" class="absolute top-0 left-0 w-full h-full backdrop-blur-sm bg-white/30 text-center">
      <div class="flex justify-center items-center w-full h-full">
        <Spinner></Spinner>
      </div>
    </div>
  </Signup>
</template>

<style scoped lang="scss">

</style>
