<script setup lang="ts">
import { ref } from "vue";
import SortIcon from "./table/SortIcon.vue";
import { ISortDirection } from "../interfaces/ISortDirection";

type IProps = {
  rows: any[];
  totalEntries?: number;
  currentPage?: number;
  maxPages?: number;
  columnTitles: any;
  columns: string[];
  overrides?: any;
  showPagination?: boolean;
};

const props = withDefaults(defineProps<IProps>(), {
  columnTitles: {},
  overrides: {},
  showPagination: false
});

let lastColumnKey;
const columns: { [key: string]: string } = props.columns.reduce((columns: any, column: string) => {
  columns[column] = props.columnTitles[column] ?? column;
  lastColumnKey = column;
  return columns;
}, {});

const rows = props.rows.map((row: any) => {
  return Object.keys(columns).map((column: any) => {
    const fn = props.overrides.hasOwnProperty(column)
      ? props.overrides[column]
      : (v: any) => v;
    if (column.includes(".")) {
      let _parts = column.split(".");

      let _val = row;
      do {
        let _part = _parts.shift();
        _val = _val[_part] ?? null;
      } while (_parts.length > 0 && _val != null);
      return fn(_val);
    }
    return fn(row[column] ?? null);
  });
});

const sortBy = ref<string>("id");
const sortDirection = ref<ISortDirection>("desc");

</script>

<template>
  <div class="flex flex-col gap-6">
    <table class="min-w-full leading-normal">
      <thead class="">
      <tr>
        <th
          v-for="(column, key) of columns"
          class="bg-[var(--m-grey-background)] py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
        >
          <div class="flex items-center justify-between px-5" :class="{'borderr': lastColumnKey !== key}">
            <div>{{ column }}</div>
            <SortIcon :sort-key="key as string" v-model:sort-by="sortBy" v-model:sort-direction="sortDirection" />
          </div>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(row, i) of rows" :key="i">
        <td
          class="border-b border-gray-200 text-sm"
          v-for="(col, j) in row"
          :key="i + '.' + j"
        >
          <div v-html="col" class="px-3 my-3" :class="{'borderr': row.length > (j+1)}"></div>
        </td>
      </tr>
      </tbody>
    </table>
    <div v-if="props.showPagination"
         class="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between">
      <span class="text-xs xs:text-sm text-gray-900">
        Showing 1 to 4 of 50 Entries
      </span>
      <div class="inline-flex mt-2 xs:mt-0">
        <button
          class="text-sm text-indigo-50 transition duration-150 hover:bg-indigo-500 bg-indigo-600 font-semibold py-2 px-4 rounded-l"
        >
          Prev
        </button>
        &nbsp; &nbsp;
        <button
          class="text-sm text-indigo-50 transition duration-150 hover:bg-indigo-500 bg-indigo-600 font-semibold py-2 px-4 rounded-r"
        >
          Next
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
th {
  position: relative;
}

th:after {
  content: "";
  position: absolute;
  right: 0;
  width: 100%;
}

th:first-child {
  @apply rounded-l-[8px];
}

th:last-child {
  @apply rounded-r-[8px];
  border-right: 0;
}

table {
  color: var(--m-dark);
  @apply text-sm/[21px];
}

.borderr {
  border-right: 1px solid var(--m-neutral-400);
}
</style>
