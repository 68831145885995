import {Moment} from "moment";

export enum LSAccountStatus {
  Requested = "requested",
  Active = "active",
  Suspended = "suspended",
  Blocked = "blocked",
  Disabled = "disabled",
  Deleted = "deleted",
}

export enum LSTransactionStatus {
  Created = "created",
  Signed = "signed",
  Sent = "sent",
  Executed = "Executed",
  Rejected = "rejected",
  Onhold = "onhold",
}

export enum LSTransactionType {
  European = "european",
  Intracountry = "intracountry",
  Internal = "internal",
  Fee = "fee",
  Own = "own",
}

export enum LSTransactionDirection {
  In = "in",
  Out = "out",
}

export type LSGetAccountsParams = {
  type?: "fiat" | "crypto";
  full?: boolean;
  status?: LSAccountStatus[];
};

export type LSFiatAccountDetails = {
  name: string;
  iban: string;
  swift: string;
  countryCode: string;
};

export type LSAccount = {
  id: string;
  currency: string;
  type: "fiat" | "crypto";
  status: LSAccountStatus;
  balance: LSBalance;
  name: string;
  createdAt: string;
  fiat?: LSFiatAccountDetails;
};

export type LSBalance = {
  totalAmount: number;
  onholdAmount: number;
  availableAmount: number;
  currencyCode: string;
};

export type LSGetTransactionsParams = {
  accountId?: string;
  limit?: number;
  page?: number;
  before?: string;
  after?: string;
  status?: LSTransactionStatus[];
  orderBy?: string;
};

export type LSAmount = {
  amount: number;
  currency: string;
};

export type LSTransactionDocumentsShort = {
  documentId: string;
  name: string;
};

export type LSTransactionShort = {
  transactionId: string;
  type: LSTransactionType;
  createdAt: Moment;
  signedAt: Moment | null;
  executedAt: Moment | null;
  fromAccountId?: string;
  toAccountId?: string;
  direction: LSTransactionDirection;
  status: LSTransactionStatus;
  description: string;
  amount: LSAmount;
  feeAmount: LSAmount;
  documents: LSTransactionDocumentsShort[];
  from: LSFiatAccountDetails;
  to: LSFiatAccountDetails;
};

export type LSList<T> = {
  list: T[];
  meta: {
    records: number;
    currentPage: number;
    lastPage: number;
    minEntry: number;
    maxEntry: number;
  };
};

export type LSAccountsList = LSAccount[];

export default interface ILedgerRepository {
  getAccounts(params: LSGetAccountsParams): Promise<LSAccountsList>;
  getAccount(accountId: string): Promise<LSAccount>;
  getTransactions(
    params: LSGetTransactionsParams,
  ): Promise<LSList<LSTransactionShort>>;
  getTotalBalance(accountGroup: string, currency: string): Promise<LSBalance>;
}
