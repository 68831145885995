<script setup lang="ts">

import {IconNotification} from "@iconify-prerendered/vue-carbon";
import IconButton from "../components/buttons/IconButton.vue";

import {useUserStore} from "../store";
import {storeToRefs} from "pinia";

const className = "w-1.5 h-1.5 bg-[var(--m-red)] rounded-full m-auto absolute top-1 right-1";

const userStore = useUserStore();
const { getUser } = storeToRefs(userStore);

</script>

<template>
  <IconButton v-if="getUser?.profile">
    <div
        class="relative cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
    >
      <IconNotification class="inline flex-1 font-bold text-2xl" />
      <div :class="className" class="animate-ping duration-200"></div>
      <div :class="className" class="shadow-lg"></div>
    </div>
  </IconButton>
</template>

<style scoped lang="scss">

</style>