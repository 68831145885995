<script lang="ts">
import {RouteNames} from "../../../../utils/constants.ts";

export function getRouteSignupPhone() {
  return {
    name: RouteNames.SIGNUP_PHONE
  };
}
</script>
<script setup lang="ts">
import Signup from "../../../../components/layout/GuestLayout/Signup.vue";
import {Ref, ref, watch} from "vue";

import {useUserStore} from "../../../../store/index";
import {error, success} from "../../../../utils/toast.ts";
import MButton from "../../../../components/buttons/MButton.vue";
import Spinner from "../../../../components/icons/Spinner.vue";
import {TextInput} from "../../../../components/form";
import {IRepositoryError} from "../../../../repositories/dto/errors/IRepositoryError.ts";

const userStore = useUserStore();

const phone = ref("");
const countryCode = ref("371");

const allowContinue = ref(false);

const inLoading: Ref<boolean> = ref(false);

watch([phone, inLoading], ([newPhone, newInLoading]) => {
  allowContinue.value = newPhone.length > 5 && !newInLoading;
});

const onSignup = async () => {
  inLoading.value = true;
  const result = await userStore.addPhone(countryCode.value, phone.value);
  inLoading.value = false;

  console.log(result);

  if (result.hasOwnProperty('timeout')) {
    success("SMS sent");
  } else {
    const _result = result as IRepositoryError;
    error("Error sending sms, " + _result.description);
  }
};
</script>

<template>
  <Signup>
    <div class="mx-auto w-[636px] flex-col justify-start items-center gap-8 flex">
      <div class="flex-col justify-start items-center gap-8 inline-flex">
        <div class="flex-col justify-start items-center gap-8 flex">
          <div class="text-center text-[#25262a] text-4xl font-bold font-['Sora'] leading-[38px]">
            {{ $t("Your Phone Number") }}
          </div>
          <div class="text-center text-[#25262a] text-base font-normal font-['Poppins'] leading-[38px]">
            Please enter your phone number. Will send you an SMS with a verification code
          </div>
          <div class="w-[400px] justify-center items-start gap-4 inline-flex">

            <!-- FLAG -->
            <div class="px-4 py-2 bg-[#edeff2] rounded-lg flex-col justify-start items-start gap-2.5 inline-flex">
              <div class="justify-start items-center gap-2 inline-flex">
                <div class="w-[34px] h-[19.84px] justify-center items-center flex">
                  <div class="w-[34px] h-[19.84px] relative">
                    <div class="w-[34px] h-[19.84px] left-0 top-0 absolute">
                    </div>
                  </div>
                </div>
                <div class="text-[#25262a] text-base font-normal font-['Poppins'] leading-[38px]">
                  +{{ countryCode }}
                </div>
                <div class="w-6 h-6 px-[2.52px] pt-[6.87px] pb-[6.90px] justify-center items-center flex"></div>
              </div>
            </div>
            <!-- /FLAG -->

            <TextInput
                name="phone"
                v-model="phone"
                type="phone"
                placeholder="20202020"
                :full="true"
            />
          </div>
          <MButton :disabled="!allowContinue" @click="onSignup" class="w-full" size2="medium" kind="primary">
            Send Me the Code
          </MButton>
        </div>
      </div>
    </div>
    <div v-if="inLoading" class="absolute top-0 left-0 w-full h-full backdrop-blur-sm bg-white/30 text-center">
      <div class="flex justify-center items-center w-full h-full">
        <Spinner></Spinner>
      </div>
    </div>
  </Signup>
</template>

<style scoped lang="scss"></style>
