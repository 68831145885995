<script setup lang="ts">
import { storeToRefs } from "pinia";
import { onBeforeMount, onErrorCaptured, ref, watch } from "vue";
import { RouteLocationRaw, RouterView, useRouter } from "vue-router";

import type { Ref } from "vue";
import AppLayout from "./components/layout/AppLayout.vue";
import ProgressLoader from "./components/layout/ProgressLoader.vue";
import { useAppStore, useUserStore, useConfigStore } from "./store";
import { error } from "./utils/toast.ts";
import { getRouteXDashboard } from "./views/Auth/Dashboard.vue";
import { getRouteSignupVerifyPhone } from "./views/Guest/Signup/Onboard/PhoneVerify.vue";
import { getRouteSignupPhone } from "./views/Guest/Signup/Onboard/Phone.vue";
import { getRouteSignupVerifyEmail } from "./views/Guest/Signup/Onboard/EmailVerify.vue";
import { UserDTO } from "./repositories/dto/user/UserDTO.ts";
import {getRouteLogin} from "./views/Guest/Auth/AuthForm.vue";
import Spinner from "./components/icons/Spinner.vue";

const appStore = useAppStore();
const configStore = useConfigStore();
const userStore = useUserStore();

const { getIsLoading } = storeToRefs(appStore);
const { getCurrentStep, getUser, getIsAuthenticated } = storeToRefs(userStore);


const ready: Ref<boolean> = ref(false);

onBeforeMount(async () => {
  appStore.beginDeterminateLoading();

  await configStore.init();
  await userStore.init();

  appStore.endDeterminateLoading();
  ready.value = true;
});

const router = useRouter();

watch([
  getUser,
  getCurrentStep,
  getIsAuthenticated,
], async ([user, currentStep, isAuthenticated]: [null | UserDTO, string | null, boolean], [, , isAuthenticatedOld]: [null | UserDTO, string | null, boolean]) => {
  console.log("EntryPoint [user, currentStep, isAuthenticated]", [user, currentStep, isAuthenticated]);

  if (!isAuthenticated && isAuthenticatedOld) {
    await router.push(getRouteLogin());
    return;
  }

  if (isAuthenticated && user != null) {
    console.log("EntryPoint user != null", user);
    let route: RouteLocationRaw = getRouteXDashboard();
    switch (currentStep) {
      case "email-verify":
        route = getRouteSignupVerifyEmail();
        break;
      case "phone":
        route = getRouteSignupPhone();
        break;
      case "phone-verify":
        route = getRouteSignupVerifyPhone();
        break;
    }

    console.log("EntryPoint router.push", route);
    await router.push(route);
    console.log("EntryPoint router.pushed", route);
  }

});

// Top level error handler
onErrorCaptured((e: Error) => {
  error("Error " + e.message); // ToastOptions
});
</script>

<template>
  <ProgressLoader v-if="getIsLoading" />

  <AppLayout>
    <RouterView v-if="ready" />
  </AppLayout>

  <div v-if="false" class="absolute top-0 left-0 w-full h-full backdrop-blur-sm bg-white/30 text-center">
    <div class="flex justify-center items-center w-full h-full">
      <Spinner></Spinner>
    </div>
  </div>
</template>

<style scoped></style>
