import { createI18n } from "vue-i18n";
import { App } from "vue";
import en from "../lang/en.ts";


// Type-define 'en-US' as the master schema for the resource
type MessageSchema = typeof en

const i18n = createI18n<[MessageSchema], "en">({
  allowComposition: true,
  locale: "en",
  messages: {
    "en": en
  }
});

export default function initializeI18n(app: App) {
  app.use(i18n);
}
