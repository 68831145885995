import FiatCurrency from "./FiatCurrency.ts";
import CryptoCurrency from "./CryptoCurrency.ts";
import {
    IconBtc,
    IconEth, IconEur, IconGbp, IconUsd,
    IconUsdt,
    IconXrp,
} from "@iconify-prerendered/vue-cryptocurrency-color";
import Currency from "./Currency.ts";
import {IconUnknown} from "@iconify-prerendered/vue-carbon";

export default {
    CURRENCY_EUR: new FiatCurrency("EUR", 2, "Euro", IconEur, "€"),//"#003399"
    CURRENCY_USD: new FiatCurrency("USD", 2, "Dollar", IconUsd, "$"),//"#6CDE07"
    CURRENCY_GBP: new FiatCurrency("GBP", 2, "British Pounds", IconGbp, "£"),//"#BC3FE0"

    CURRENCY_BTC: new CryptoCurrency("BTC", 8, "Bitcoin", IconBtc, ""),
    CURRENCY_ETH: new CryptoCurrency("ETH", 8, "Ethereum", IconEth, ""),
    CURRENCY_USDT: new CryptoCurrency("USDT", 8, "TetherUS", IconUsdt, ""),
    CURRENCY_XRP: new CryptoCurrency("XRP", 8, "Ripple", IconXrp, ""),

    CURRENCY_UNKNOWN: new Currency("XXX", 0, "Unknown", IconUnknown, "XXX"),

    codeToCurrency(currencyCode: string): Currency {
        switch (currencyCode.toLowerCase()) {
            case 'eur':
                return this.CURRENCY_EUR;
            case 'usd':
                return this.CURRENCY_USD;
            case 'gbp':
                return this.CURRENCY_GBP;
            case 'btc':
                return this.CURRENCY_BTC;
            case 'eth':
                return this.CURRENCY_ETH;
            case 'usdt':
                return this.CURRENCY_USDT;
            case 'xrp':
                return this.CURRENCY_XRP;
            default:
                return this.CURRENCY_UNKNOWN;
        }
    }
};
