import IAuthRepository from "../interfaces/IAuthRepository.ts";
import { IAuthorization, IEmailState } from "../dto/auth/Authorization.ts";
import { SignInInfo, SignUpInfo } from "../dto/auth";
import { DeepPartial } from "chart.js/dist/types/utils";
import { useStorage } from "@vueuse/core";
import { Ref, watch } from "vue";
import { ClientLegalType } from "../dto/client/ClientDTO.ts";

let session: Ref<null | string> = useStorage("authRepoMockSession", null, sessionStorage, { mergeDefaults: true });
console.log("authRepoMockSession init", session.value);
watch(session, (newSession) => {
  console.log("authRepoMockSession newSession", newSession);
});

export function getMockSession(): IAuthorization | null {
  return session.value != null ? JSON.parse(session.value) : null;
}

export function updateMockSession(data: DeepPartial<IAuthorization>): IAuthorization | null {

  const _val = ((session.value && JSON.parse(session.value)) || {}) as IAuthorization;
  session.value = JSON.stringify(Object.assign(_val, data));
  return session.value != null ? JSON.parse(session.value) : null;
}

export default class AuthRepositoryMock implements IAuthRepository {
  async signIn(data: SignInInfo): Promise<IAuthorization> {
    const keyId = (Math.random() * 10e20).toString();
    const auth: IAuthorization = {
      email: {
        id: keyId,
        email: data.email,
        createdAt: new Date(),
        verifiedAt: new Date(),
        verified: true
      } as IEmailState,
      phone: {
        id: keyId,
        phoneShort: '20202020',
        phoneCountry: '371',
        phoneFull: '37120202020',
        verified: true,
        verifiedAt: new Date,
        createdAt: new Date,
      },
      isAuthenticated: true,
      next_factor_required: false,
      next_factor_type: null,
      authKeyId: keyId,
      scope: [],
      user: {
        id: "user-id",
        profile: {
          id: "natural profile id",
          firstName: data.email,
          lastName: "Lastname",
          state: "ok",
          createdAt: new Date(),
          updatedAt: new Date()
        },
        clients: [
          {
            flags: [],
            id: "1",
            profile: {
              id: "1",
              firstName: "Michael",
              lastName: "Hansen",
              fullName: "Michael Hansen",
              state: "state",
              createdAt: new Date,
              updatedAt: new Date,
              verified: true
            },
            legalType: ClientLegalType.natural,
            state: "active",
            createdAt: new Date,
            updatedAt: new Date
          },
          {
            flags: [],
            id: "2",
            profile: {
              id: "profile-id",
              fullName: "Tradewell",
              legalName: "Tradewell",
              legalNumber: "40302010",
              verified: true
            },
            legalType: ClientLegalType.business,
            state: "active",
            createdAt: new Date,
            updatedAt: new Date
          }
        ],
        restriction_reason: null,
        lang_code: "en_US"
      }
    };

    session.value = JSON.stringify(auth);

    await new Promise((r) => setTimeout(() => r(true), 1000));

    return Promise.resolve(auth);
  }

  async signUp(data: SignUpInfo): Promise<IAuthorization> {
    const keyId = (Math.random() * 10e20).toString();
    const auth: IAuthorization = {
      email: {
        id: keyId,
        email: data.email,
        createdAt: new Date(),
        verifiedAt: null,
        verified: false
      } as IEmailState,
      phone: null,
      isAuthenticated: true,
      next_factor_required: false,
      next_factor_type: null,
      authKeyId: keyId,
      scope: [],
      user: {
        id: "user-id",
        profile: null,
        clients: [],
        restriction_reason: null,
        lang_code: "en_US"
      }
    };

    session.value = JSON.stringify(auth);

    return Promise.resolve(auth);
  }

  async resetPassword(email: string): Promise<boolean> {
    if (email === "") {
    }
    return true;
  }

  async logout() :Promise<void> {
    session.value = null;
  }
}
