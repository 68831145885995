<script setup lang="ts">

import {RouteLocationRaw, useRoute} from "vue-router";

import {getRouteXAccountsCard} from "../views/Auth/accounts/Cards.vue";
import {getRouteXAccountsCrypto} from "../views/Auth/accounts/Crypto.vue";
import {getRouteXAccountsFiat} from "../views/Auth/accounts/Fiat.vue";
import {getRouteXDashboard} from "../views/Auth/Dashboard.vue";
import {ref, watch} from "vue";
import {useUserStore} from "../store";
import {storeToRefs} from "pinia";
import {OnboardingStep} from "../store/onboardingStore.ts";

type IMenuItem = {
  title: string;
  src: RouteLocationRaw;
};
const menu: IMenuItem[] = [
  {title: "Dashboard", src: getRouteXDashboard()},
  {title: "Cash Accounts", src: getRouteXAccountsFiat()},
  {title: "Crypto Wallets", src: getRouteXAccountsCrypto()},
  {title: "Cards", src: getRouteXAccountsCard()}
];

const route = useRoute();

const currentRoute = ref(route.name);
watch(
    () => route.name,
    (src) => (currentRoute.value = src)
);

const userStore = useUserStore();
const {currentStep} = storeToRefs(userStore);

</script>

<template>
  <RouterLink
      v-if="currentStep === OnboardingStep.DONE"
      :to="menuItem.src"
      v-for="menuItem of menu"
      :class="menuItem.src == currentRoute ? 'text-blue-500' : ''"
  >
    {{ menuItem.title }}
  </RouterLink>
</template>

<style scoped lang="scss">

</style>