<script setup lang="ts">
import { SVGAttributes } from "vue";

interface IconProps extends/* @vue-ignore */ SVGAttributes {
}

const props = defineProps<IconProps>();
</script>

<template>
  <svg
    v-bind="props"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.2375 11.175H9.75L12 8.88751C12.3375 8.55001 12.3375 8.02501 12 7.68751C11.6625 7.35001 11.1375 7.35001 10.8 7.68751L7.1625 11.4C6.825 11.7375 6.825 12.2625 7.1625 12.6L10.8 16.3125C10.95 16.4625 11.175 16.575 11.4 16.575C11.625 16.575 11.8125 16.5 12 16.35C12.3375 16.0125 12.3375 15.4875 12 15.15L9.75 12.8625H16.2375C16.6875 12.8625 17.0625 12.4875 17.0625 12.0375C17.1 11.55 16.725 11.175 16.2375 11.175Z"
      fill="currentColor"
    />
    <path
      d="M12 0.674988C5.7375 0.674988 0.637497 5.73749 0.637497 12C0.637497 18.2625 5.7375 23.325 12 23.325C18.2625 23.325 23.3625 18.2625 23.3625 12C23.3625 5.73749 18.2625 0.674988 12 0.674988ZM12 21.675C6.675 21.675 2.325 17.325 2.325 12C2.325 6.67499 6.675 2.32499 12 2.32499C17.325 2.32499 21.675 6.67499 21.675 12C21.675 17.325 17.325 21.675 12 21.675Z"
      fill="currentColor"
    />
  </svg>
</template>

<style scoped lang="scss"></style>
