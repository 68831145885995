<script setup lang="ts">

import PurpleBg from "../../assets/images/block/magicpattern-mesh-gradient-1717067370817.png";
import BaseCurrencies from "../../libs/ledger/BaseCurrencies.ts";
import {onMounted, ref, watch} from "vue";
import FiatCurrency from "../../libs/ledger/FiatCurrency.ts";
import {Balance} from "../../repositories/dto/ledger/balance/Balance.ts";
import {formatAmount} from "../../utils/formatters.ts";
import {LedgerService} from "../../services";
import LoadingWidget from "../LoadingWidget.vue";
import {IconChevronDown} from "@iconify-prerendered/vue-carbon"

const blockBgStyle = {
  backgroundImage: "url(" + PurpleBg + ")",
};

const fiatCurrency = ref<FiatCurrency>(BaseCurrencies.CURRENCY_EUR);

const SERVICE_REF_TOTAL_CRYPTO_BALANCE = 'totalCryptoBalance';

const ledgerService = new LedgerService<Balance>();
const {state, value: balance, error} = ledgerService.getRefs(SERVICE_REF_TOTAL_CRYPTO_BALANCE, {
  available: 0,
  currency: fiatCurrency.value,
  onhold: 0,
  total: 0,
});

function requestBalance() {
  ledgerService.getTotalCryptoBalance(SERVICE_REF_TOTAL_CRYPTO_BALANCE, fiatCurrency.value);
}

onMounted(() => {
  requestBalance();
})

watch(error, (val) => {
  if (val) {
    setTimeout(requestBalance, 1000).unref;
  }
})
</script>

<template>
  <LoadingWidget :state-ref="state">
    <div
        class="h-full w-full relative rounded-2xl p-6 flex flex-col gap-4 text-xl text-[var(--m-light)] bg-right-bottom bg-cover bg-no-repeat"
        :style="blockBgStyle">
      <div class="flex gap-2 items-center font-['Sora'] font-bold">
        <component :is="fiatCurrency.icon" class="w-[38px] h-[38px]"/>
        {{ fiatCurrency.name }} Account
        <IconChevronDown/>
      </div>
      <span class="text-5xl/[38px]">
        {{ formatAmount(balance.available, balance.currency, true) }}
      </span>
    </div>
  </LoadingWidget>
</template>

<style scoped lang="scss">

</style>