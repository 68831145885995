<script setup lang="ts">
import { IconMoon, IconSun } from "@iconify-prerendered/vue-carbon";

import { useDark, useToggle } from "@vueuse/core";
import IconButton from "../components/buttons/IconButton.vue";

const isDark = useDark();
const toggleDark = useToggle(isDark);
</script>

<template>
  <IconButton @click="toggleDark()">
    <component
      :is="isDark ? IconSun : IconMoon"
      class=""
    />
  </IconButton>
</template>

<style scoped lang="scss"></style>
