<script setup lang="ts">
import MButton from "./MButton.vue";
import IconArrowLeftCircle from "../icons/IconArrowLeftCircle.vue";
import {RouteLocationRaw} from "vue-router";

type IProps = {
  class?: string
  style?: string
  to?: RouteLocationRaw,
}
const props = defineProps<IProps>();

</script>

<template>
  <MButton v-bind="props" kind="secondary" size2="medium">
    <IconArrowLeftCircle class="inline"></IconArrowLeftCircle>
    Back
  </MButton>
</template>

<style scoped lang="scss"></style>
