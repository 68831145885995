<script setup lang="ts">

import {computed, ComputedRef, Ref} from "vue";

import {
  IconLogout,
  IconChevronDown,
  IconSettings,
  IconUser
} from "@iconify-prerendered/vue-carbon";
import {useUserStore} from "../store";
import {
  ClientDTO
} from "../repositories/dto/client/ClientDTO.ts";
import {legalTypeToText} from "../utils/utils.ts";
import {storeToRefs} from "pinia";
import MButton from "../components/buttons/MButton.vue";
import AccountBlockClient from "../components/layout/Header/AccountBlockClient.vue";
import BasicDropdown from "../components/basic/BasicDropdown.vue";
import BasicAvatar from "../components/basic/BasicAvatar.vue";


const userStore = useUserStore();
const {getUser} = storeToRefs(userStore);
const {currentClientUuid} = userStore;
const {logout} = userStore;

const clients: Ref<ClientDTO[]> = computed(() => getUser.value?.clients ?? []);

console.log("AccountBlock.clients", clients);
const currentClient: ComputedRef<ClientDTO> = computed(() => clients.value.filter((client) => client.id === currentClientUuid)[0]);

</script>

<template>
  <div class="group relative ml-4 flex-shrink-0" v-if="getUser?.profile">
    <BasicDropdown>
      <template v-slot:button>
        <button
            type="button"
            class="relative flex rounded-full text-sm items-center gap-2"
            id="user-menu-button"
            aria-expanded="false"
            aria-haspopup="true"
        >
          <div class="text-right text-[#25262a] text-sm font-normal font-['Poppins'] leading-[38px]">
            {{ currentClient?.profile?.fullName }}
          </div>
          <BasicAvatar :client="currentClient" v-if="currentClient"/>
          <IconChevronDown class="w-4 h-4 color-red"/>
        </button>
      </template>
      <template v-slot:content>
        <div class="w-full">
          <div
              class="mx-auto mt-6 pb-8 border-b border-[var(--m-neutral-400)] flex-col justify-center items-center gap-1 flex text-center font-['Poppins'] leading-[38px]text-[#25262a] text-base/[38px] font-normal">
            <div class="flex items-center">
              <BasicAvatar size="big" :client="currentClient!"/>
            </div>
            <div class="text-[#25262a] text-base font-medium">
              {{ currentClient?.profile?.fullName }}
            </div>
            <div class="text-[#757c89] text-sm font-normal">
              {{ legalTypeToText(currentClient?.legalType!) }}
            </div>
          </div>

          <div
              class="w-full mt-4 pb-4 flex-col justify-around items-start border-b border-[var(--m-neutral-400)] gap-4 inline-flex">
            <div class="flex items-center gap-3">
              <IconUser class="w-6 h-6"/>
              Profile
            </div>
            <div class="flex items-center gap-3">
              <IconSettings class="w-6 h-6"></IconSettings>
              Settings
            </div>
          </div>
          <div
              class="w-full mt-4 pb-4 flex-col border-b border-[var(--m-neutral-400)] gap-4 inline-flex ">
            <AccountBlockClient v-for="client of clients" :key="client.id" :selected="client.id === currentClientUuid"
                                :client="client"/>

            <MButton kind="dashed" size2="medium">
              Add Account
            </MButton>
          </div>

          <div class="w-full justify-center items-center inline-flex">
            <div class="grow pt-4 basis-0 justify-center items-center self-stretch gap-3 flex" @click="logout">
              Log Out
              <IconLogout class="w-6 h-6"/>
            </div>
          </div>
        </div>
      </template>
    </BasicDropdown>
  </div>


</template>

<style scoped lang="scss">

</style>