<script setup lang="ts">

</script>

<template>
  <button type="button"><slot /></button>
</template>

<style scoped lang="scss">
button {
  color: var(--m-dark);
  font-weight: 600;
  font-size: 14px;
}
button.active {
  color: var(--m-primary-base);
  text-decoration: underline;
}
button.disabled {
  color: var(--m-primary-grey);
  cursor: not-allowed;
}
button:hover {
  text-decoration: underline;
}
</style>