import { NaturalProfileDTO } from "./NaturalProfileDTO.ts";

export enum ClientFlags {
  self = 0,
  deleted = 1,
  verified = 2,
  restricted = 4,
}

export enum ClientLegalType {
  natural = 0,
  business = 1,
}

export type ClientProfileDTO = (ClientBusinessProfileDTO | NaturalProfileDTO) & {
  id: string;
  fullName: string;
  verified: boolean;
};

export type ClientDTO = {
  flags: ClientFlags[];
  id: string;
  profile: ClientProfileDTO;
  legalType: ClientLegalType;
  state: string;
  createdAt: Date;
  updatedAt: Date;
};

export type ClientBusinessProfileDTO = {
  legalName: string;
  legalNumber: string;
};
