import IAuthRepository from "../interfaces/IAuthRepository.ts";
import { IAuthorization, IEmailState } from "../dto/auth/Authorization.ts";
import { SignInInfo, SignUpInfo } from "../dto/auth";


export default class AuthRepository implements IAuthRepository {
  async signIn(data: SignInInfo): Promise<IAuthorization> {
    const keyId = (Math.random() * 10e20).toString();
    const auth: IAuthorization = {
      email: {
        id: keyId,
        email: data.email,
        createdAt: new Date(),
        verifiedAt: new Date(),
        verified: true
      } as IEmailState,
      phone: null,
      isAuthenticated: true,
      next_factor_required: false,
      next_factor_type: null,
      authKeyId: keyId,
      scope: [],
      user: {
        id: "user-id",
        profile: {
          id: "natural profile id",
          firstName: data.email,
          lastName: "Lastname",
          state: "ok",
          createdAt: new Date(),
          updatedAt: new Date()
        },
        clients: [],
        restriction_reason: null,
        lang_code: "en_US"
      }
    };

    return Promise.resolve(auth);
  }

  async signUp(data: SignUpInfo): Promise<IAuthorization> {
    const keyId = (Math.random() * 10e20).toString();
    const auth: IAuthorization = {
      email: {
        id: keyId,
        email: data.email,
        createdAt: new Date(),
        verifiedAt: null,
        verified: false
      } as IEmailState,
      phone: null,
      isAuthenticated: true,
      next_factor_required: false,
      next_factor_type: null,
      authKeyId: keyId,
      scope: [],
      user: {
        id: "user-id",
        profile: null,
        clients: [],
        restriction_reason: null,
        lang_code: "en_US"
      }
    };

    return Promise.resolve(auth);
  }

  async resetPassword(email: string): Promise<boolean> {
    if (email === "") {
    }
    return true;
  }

  async logout(): Promise<void> {}
}
