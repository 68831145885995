<script setup lang="ts">
import { RouteLocationRaw } from "vue-router";

type IProps = {
  to?: RouteLocationRaw;
};
const props = defineProps<IProps>();
</script>

<template>
  <RouterLink class="link-btn" v-if="props.to" :to="props.to">
    <slot />
  </RouterLink>
  <button v-else class="link-btn">
    <slot />
  </button>
</template>

<style scoped lang="scss">
.link-btn {
  @apply text-base font-medium font-['Poppins'] leading-[38px];
  color: var(--m-primary-base);
}
.link-btn:hover {
  color: var(--m-blue-7);
}
.link-btn:active {
  color: var(--m-blue-8);
}
.link-btn:disabled {
  color: var(--m-neutral-800);
}
</style>
