<script lang="ts">
import { RouteNames } from "../../../utils/constants.ts";

export function getRouteXAccountsFiat() {
  return {
    name: RouteNames.X_ACCOUNTS_FIAT,
  };
}
</script>
<script setup lang="ts">
import BreadCrumbs from "../../../components/BreadCrumbs.vue";
import BasicCard from "../../../components/BasicCard.vue";
import MButton from "../../../components/buttons/MButton.vue";
import IconTopRight from "../../../components/icons/IconTopRight.vue";
import IconCirclePlus from "../../../components/icons/IconCirclePlus.vue";
import IconDollarExchange from "../../../components/icons/IconDollarExchange.vue";
import CashAccountBalanceCardWidget from "../../../widgets/cash/CashAccountBalanceCardWidget.vue";
import CashTransactionTab from "../../../widgets/cash/CashTransactionTab.vue";
import CashAccountDetailsTab from "../../../widgets/cash/CashAccountDetailsTab.vue";
import {getRouteXAccountsFiatTopUp} from "./fiat/TopUpBalance.vue";
import {getRouteXAccountsFiatSend} from "./fiat/Send.vue";
import TransactionsTableWidget from "../../../widgets/TransactionsTableWidget.vue";
import InputSearch from "../../../components/form/InputSearch.vue";
import { IconArrowLeft, IconArrowRight, IconMoney } from "@iconify-prerendered/vue-carbon";
import {IBalanceBlockItem} from "./Cards.vue";

const tabs = [
  {
    key: 'cash-accounts-transactions',
    title: 'Transactions',
    node: CashTransactionTab,
  },
  {
    key: 'cash-accounts-account-details',
    title: 'Account Details',
    node: CashAccountDetailsTab,
  },
];


const BalanceBlocks: IBalanceBlockItem[] = [
  {
    text: "Payments",
    amount: "€ 372.00",
    icon: IconMoney()
  },
  {
    text: "Deposits",
    amount: "€ 302.00",
    icon: IconArrowLeft()
  },
  {
    text: "Withdrawals",
    amount: "€ 72.00",
    icon: IconArrowRight()
  }
];
</script>

<template>
  <div class="flex flex-col gap-6">
    <BreadCrumbs :title="$t('Cash Accounts')"/>

    <div class="flex gap-6 h-[180px]">
      <div class="w-[384px]">
        <CashAccountBalanceCardWidget />
      </div>
      <div class="flex-grow">
        <BasicCard title="Payments and Transfers" class="justify-between">
          <div class="flex gap-4">
            <MButton
                kind="primary"
                size2="medium"
                :to="getRouteXAccountsFiatTopUp()"
            >
              <IconCirclePlus />
              Top Up
            </MButton>
            <MButton
                kind="secondary"
                size2="medium"
                :to="getRouteXAccountsFiatSend()"
            >
              Send Cash
              <IconTopRight/>
            </MButton>
            <MButton
                kind="default"
                size2="medium"
                :to="getRouteXAccountsFiatSend()"
            >
              <IconDollarExchange />
              Exchange Cash
            </MButton>
          </div>
        </BasicCard>
      </div>
    </div>

    <BasicCard :tabs="tabs">

    </BasicCard>
  </div>

  <BreadCrumbs :title="$t('Cash accounts')" class="mb-5"></BreadCrumbs>

  <div class="w-full h-full bg-white rounded-2xl p-6 flex flex-col gap-6">

    <div class="w-full flex justify-between items-center">
      <div class="flex items-center gap-3">
        <h2 class="font-[Sora] font-bold text-2xl/[38px]">Transactions</h2>
      </div>
      <InputSearch class="w-[253px]" placeholder="Search" />
    </div>

    <div class="bg-[var(--m-grey-background)] rounded-lg h-[124px] flex items-center p-6 gap-16">
      <div v-for="block in BalanceBlocks"
           class="flex gap-4 justify-center items-center select-none font-['Sora'] font-bold">
        <div class="py-2.5 flex">
          <div class="h-full w-full rounded-full bg-[var(--m-blue-2)]">
            <component :is="block.icon" class="w-8 h-8 text-[var(--m-primary-base)] m-4"></component>
          </div>
        </div>
        <div class="flex flex-col gap-2">
          <span class="text-base/[38px] text-[var(--m-primary-base)]">{{ block.text }}</span>
          <span class="text-2xl/[38px]">{{ block.amount }}</span>
        </div>
      </div>
    </div>

    <TransactionsTableWidget :show-filters="true" :show-pagination="true" />

  </div>
</template>

<style scoped lang="scss"></style>
