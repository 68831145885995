<script setup lang="ts">
import {SVGAttributes} from "vue";

interface IconProps extends/* @vue-ignore */ SVGAttributes {
}

const props = defineProps<IconProps>();
</script>

<template>
  <svg v-bind="props" width="140" height="130" viewBox="0 0 140 130" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.3" clip-path="url(#clip0_1260_1678)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M87 0L130.5 11.6651L162.335 43.5L174 87L162.335 130.5L130.5 162.335L87 174L43.5 162.335L11.6651 130.5L0 87L11.6651 43.5L43.5 11.6651L87 0ZM50.6908 24.1114L24.1114 50.6908L14.3816 87L24.1114 123.309L50.6908 149.889L87 159.618L123.309 149.889L149.889 123.309L159.618 87L149.889 50.6908L123.309 24.1114L87 14.3816L50.6908 24.1114Z" fill="#467DF5"/>
      <path d="M56.8163 78.1224L87 95.8776L117.184 78.1224L87 28.4082L56.8163 78.1224Z" fill="#467DF5"/>
      <path d="M37.3035 78.1225L56.8164 89.2828L87.0001 106.531L117.184 89.2828L136.714 78.1225L125.937 133.305L113.633 102.98L87.0001 145.592L60.3674 102.98L48.0631 133.305L37.2858 78.1225H37.3035Z" fill="#467DF5"/>
    </g>
    <defs>
      <clipPath id="clip0_1260_1678">
        <rect width="174" height="174" fill="white"/>
      </clipPath>
    </defs>
  </svg>

</template>

<style scoped lang="scss"></style>
