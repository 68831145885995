import { createRouter, createWebHistory } from "vue-router";

import { useAppStore, useUserStore } from "../store/index";
import { routes } from "./routes.ts";
import { RouteNames } from "../utils/constants.ts";
import {storeToRefs} from "pinia";
import {getRouteXDashboard} from "../views/Auth/Dashboard.vue";
import {getRouteLogin} from "../views/Guest/Auth/AuthForm.vue";

/**
 * @function createProps
 * Parses the route query and params to generate vue props
 * @param {object} route The route object
 * @returns {object} a Vue props object
 * /
 function createProps(route: { query: any; params: any }): object {
 return { ...route.query, ...route.params };
 }*/

export default function getRouter() {
  const router = createRouter({
    history: createWebHistory(),
    routes
  });

  router.beforeEach(async (to, from, next) => {
    console.log("ROUTER", "start", { to, from });
    const userStore = useUserStore();
    const appStore = useAppStore();

    const { getUser } = storeToRefs(userStore);
    appStore.beginDeterminateLoading();

    const isAuthenticated = getUser.value !== null;

    if (to.path === "/") {
      const route = isAuthenticated ? getRouteXDashboard() : getRouteLogin();
      console.log("ROUTER", "path = / redirect to ", route);
      await router.replace(route);
      return;
    }

    // Backend Redirection Handler
    if (to.query?.r) {
      console.log("ROUTER", "query.r", to.query.r);
      await router.replace({
        path: to.query.r ? to.query.r.toString() : to.path,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
        query: (({ r, ...q }) => q)(to.query)
      });
      return;
    }

    let redirectTo: string | null = null;
    if (getUser.value !== null && to.meta.requiresAuth === false) {
      redirectTo = RouteNames.X_DASHBOARD;
    } else if (getUser.value == null && to.meta.requiresAuth) {
      redirectTo = RouteNames.AUTH_LOGIN;
    }

    if (redirectTo !== null) {
      console.log("ROUTER", "REDIRECT TO", {
        to_path: to.path,
        redirectTo: redirectTo,
        getUser: getUser.value,
        requiresAuth: to.meta.requiresAuth
      });
      next({ name: redirectTo });
    } else {
      next();
    }
  });

  router.afterEach((to) => {
    const appStore = useAppStore();

    // Update document title
    document.title = to.meta.title
      ? `Moneterum - ${to.meta.title}`
      : "Moneterum";

    appStore.endDeterminateLoading();
  });

  return router;
}
