<script setup lang="ts">

import MButton from "../../components/buttons/MButton.vue";
import PurpleBg from "../../assets/images/block/magicpattern-mesh-gradient-1717067370817.png";
import {RouteNames} from "../../utils/constants.ts";
import IconCirclePlus from "../../components/icons/IconCirclePlus.vue";
import CardWalletLiner from "../../components/icons/CardWalletLiner.vue";
import MoneterumLogoMonoDark from "../../components/icons/MoneterumLogoMonoDark.vue";
import MoneterumMarkDark from "../../components/icons/MoneterumMarkDark.vue";
import IconCashAccounts from "../../components/icons/IconCashAccounts.vue";
import IconCryptoAccounts from "../../components/icons/IconCryptoAccounts.vue";


const blockBgStyle = {
  backgroundImage: "url(" + PurpleBg + ")",
  backgroundRepeat: "no-repeat",
};
</script>

<template>

  <div class="h-[242px] justify-between items-center gap-6 flex">
    <div
        class="w-96 h-full inline-flex shadow rounded-[20px] p-6 bg-right-bottom"
        :style="blockBgStyle"
    >
      <div class="w-full flex flex-col justify-between">
        <div class="flex flex-1 flex-col gap-6">
          <div class="text-white text-2xl/[38px] font-bold font-['Sora']">
            Total Balance
          </div>
          <div class="text-white text-5xl font-medium font-['Poppins']">
            €20,100.99
          </div>
        </div>

        <MButton :to="RouteNames.X_FIAT_TOPUP" size2="medium" kind="text">
          <IconCirclePlus/>
          Top Up
        </MButton>
      </div>
    </div>

    <div class="w-96 h-[242px] relative rounded-[20px] bg-top-left p-6 text-[var(--m-light)]" :style="blockBgStyle">
      <div class="flex-col justify-start items-start gap-2.5 inline-flex">
        <div class="relative">
          <CardWalletLiner class="text-[var(--m-primary-base)]"/>
          <MoneterumLogoMonoDark class="absolute top-4 left-4"/>
        </div>
        <div class="absolute bottom-8 left-14 flex-col flex text-xl/[38px] font-medium font-['Poppins']">
          <IconCashAccounts />
          Cash Accounts
          <div class="text-2xl">
            €20,100.99
          </div>
        </div>
      </div>
      <MoneterumMarkDark class="absolute bottom-0 right-0 w-[130px] h-[130px]"/>
    </div>

    <div class="w-96 h-[242px] relative rounded-[20px] bg-bottom p-6 text-[var(--m-light)]" :style="blockBgStyle">
      <div class="flex-col justify-start items-start gap-2.5 inline-flex">
        <div class="relative">
          <CardWalletLiner class="text-[var(--m-crypto-color)]"/>
          <MoneterumLogoMonoDark class="absolute top-4 left-4"/>
        </div>
        <div class="absolute bottom-8 left-14 flex-col flex text-xl/[38px] font-medium font-['Poppins']">
          <IconCryptoAccounts />
          Crypto Wallets
          <div class="text-2xl">
            €0.00
          </div>
        </div>
      </div>
      <MoneterumMarkDark class="absolute bottom-0 right-0 w-[130px] h-[130px]"/>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>