<script setup lang="ts">
import { onMounted, onUnmounted, Ref, ref } from "vue";

const open: Ref<boolean> = ref(false);

type IProps = {
  placement?: "right" | "left";
};

const props = withDefaults(defineProps<IProps>(), {
  placement: "right"
});

const onEscape = (e: any) => {
  if (e.key === "Esc" || e.key === "Escape") {
    open.value = false;
  }
};
onMounted(() => {
  document.addEventListener("keydown", onEscape);
});
onUnmounted(() => {
  document.removeEventListener("keydown", onEscape);
});
</script>

<template>
  <div class="relative">
    <button
      class="relative flex items-center focus:outline-none select-none"
      @click="open = !open"
    >
      <slot name="button"></slot>
    </button>

    <!-- to close when clicked on space around it-->
    <button
      class="fixed inset-0 h-full w-full cursor-default"
      v-if="open"
      @click="open = false"
      tabindex="-1"
    ></button>

    <!--dropdown menu-->
    <transition
      enter-active-class="transition-all duration-200 ease-out"
      leave-active-class="transition-all duration-750 ease-in"
      enter-class="opacity-0 scale-75"
      enter-to-class="opacity-100 scale-100"
      leave-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-75"
    >
      <div
        class="absolute w-[367px] bg-white rounded-2xl shadow-lg border py-6 px-6 text-sm mt-4"
        :class="props.placement === 'right' ? 'right-0' : 'left-0'"
        v-if="open">
        <slot name="content" />
      </div>
    </transition>
  </div>
</template>

<style scoped lang="scss"></style>
