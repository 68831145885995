<script setup lang="ts">
import { RouteLocationRaw } from "vue-router";

type IProps = {
  to: RouteLocationRaw;
};
const props = withDefaults(defineProps<IProps>(), {});
import userCheck from "../../assets/icons/user/user-check.svg";
</script>

<template>
  <RouterLink :to="props.to" class="reg-account">
    <div class="w-16 h-16 icon bg-neutral-50">
      <img :src="userCheck" style="color: red" alt="Icon" />
    </div>
    <div
      class="w-[204px] flex justify-center items-center text-zinc-800 text-2xl/[38px] font-bold font-['Sora']"
    >
      <slot />
    </div>
  </RouterLink>
</template>

<style scoped lang="scss">
.icon {
  @apply w-12 h-12 rounded-full flex justify-center items-center select-none font-bold text-2xl cursor-pointer;
  background-color: var(--m-light);
  color: var(--m-primary-base);
}

.reg-account {
  @apply w-full bg-indigo-100 rounded-2xl justify-center items-center gap-6 inline-flex p-10;
  height: 153px;
  background-color: var(--m-blue-1);
}

.reg-account:hover {
  @apply cursor-pointer;
  background-color: var(--m-blue-2);
}

.reg-account:active {
  background-color: var(--m-blue-3);
}

.reg-account:disabled {
  background-color: var(--m-neutral-200);
  color: var(--m-neutral-700);
  border: var(--m-neutral-700) 1px solid;
}
</style>
