//import injectionTarget from "../core/di/DecoratorInjectionTarget.ts";
import type ISessionRepository from "../repositories/interfaces/ISessionRepository.ts";
import { IAuthorization } from "../repositories/dto/auth/Authorization.ts";
import Container from "../core/di/DependencyContainer.ts";

//@injectionTarget()
export default class SessionService {
  sessionRepository: ISessionRepository;

  constructor(
    //@inject("sessionRepository") sessionRepository?: ISessionRepository
  ) {
    let sessionRepository: ISessionRepository | null = Container.get("sessionRepository");
    // ensures userRepository exists and no checks for undefined are required throughout the class
    if (sessionRepository == null) {
      throw Error("No SessionRepository provided or injected.");
    }
    this.sessionRepository = sessionRepository;
  }

  async info(): Promise<IAuthorization> {
    try {
      return await this.sessionRepository.info();
    } catch (error: any) {
      switch (error.type) {
        default:
      }
    }

    return {
      email: null,
      phone: null,
      authKeyId: "",
      scope: [],
      isAuthenticated: false,
      next_factor_required: false,
      next_factor_type: null,
      user: null,
    };
  }
}
