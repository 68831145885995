<script lang="ts">
import { RouteNames } from "../../../utils/constants.ts";

export function getRouteLogin() {
  return {
    name: RouteNames.AUTH_LOGIN,
  };
}
</script>

<script setup lang="ts">
import { Ref, ref } from "vue";
import { useUserStore } from "../../../store/index";

import { getRoutePasswordReset } from "./PasswordReset.vue";
import { IconLogin } from "@iconify-prerendered/vue-carbon";
import MButton from "../../../components/buttons/MButton.vue";
import { TextInput } from "../../../components/form/index";

const email: Ref<string> = ref("");
const password: Ref<string> = ref("");

const userStore = useUserStore();

const inLogin: Ref<boolean> = ref(false);

const onLogin = async () => {
  inLogin.value = true;
  await userStore.signIn(email.value, password.value);
  inLogin.value = false;
};
</script>

<template>
  <div class="flex-col justify-start items-center gap-8 inline-flex">
    <div class="flex-col justify-start items-start gap-8 flex">
      <TextInput
        name="e-mail"
        v-model="email"
        :label="$t('auth.field.email.title')"
        type="email"
        :disabled="inLogin"
        :placeholder="$t('auth.field.email.placeholder')"
      />
      <TextInput
        name="password"
        v-model="password"
        :label="$t('auth.field.password.title')"
        type="password"
        :placeholder="$t('auth.field.password.placeholder')"
        :disabled="inLogin"
      />
    </div>
    <div class="justify-start items-center gap-10 inline-flex">
      <div class="justify-start items-center gap-8 flex">
        <div class="w-36 h-3.5 pt-0.5 pb-px justify-center items-center flex">
          <RouterLink
            :to="getRoutePasswordReset()"
            class="text-center text-blue-500 text-base font-medium font-['Poppins'] leading-[38px]"
          >
            {{ $t("auth.login.reset-password-link") }}
          </RouterLink>
        </div>
      </div>
    </div>
    <MButton @click="onLogin" :disabled="inLogin">
      <IconLogin class="inline" />
      Login
    </MButton>
  </div>
</template>

<style scoped lang="scss"></style>
