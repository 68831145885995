<script setup lang="ts">
import Logo from "../../assets/logo.svg";
import {ref} from "vue";
import {getRouteXDashboard} from "../../views/Auth/Dashboard.vue";
import HeaderMenu from "../../widgets/HeaderMenuWidget.vue";
import HeaderLogoutButtomWidget from "../../widgets/HeaderLogoutButtomWidget.vue";
import NotificationsBellWidget from "../../widgets/NotificationsBellWidget.vue";
import AccountBlockProfileWidget from "../../widgets/AccountBlockProfileWidget.vue";
import DarkModeWidget from "../../widgets/DarkModeWidget.vue";

const isXDashboard = ref(false);

</script>

<template>
  <header>
    <nav class="h-20 mb-6" :class="{'shadow': isXDashboard}">
      <div class="mx-auto gap-4 h-full w-[1200px] items-center flex space-between">
        <div class="flex-shrink flex items-center justify-start">
          <RouterLink :to="getRouteXDashboard()">
            <img
                :src="Logo"
                alt="Moneterum"
                width="auto"
                height="100%"
            />
          </RouterLink>
        </div>
        <div
            class="flex-1 flex items-center justify-center gap-3 text-center uppercase text-sm/[21px] font-semibold font-['Poppins'] text-nowrap"
        >
          <HeaderMenu/>
        </div>

        <DarkModeWidget class=""/>

        <div class="flex space-x-5 justify-center items-center pl-2">
          <NotificationsBellWidget/>

          <AccountBlockProfileWidget/>

          <HeaderLogoutButtomWidget/>
        </div>
      </div>
    </nav>
  </header>
</template>

<style lang="scss" scoped>
</style>
