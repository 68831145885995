//import injectionTarget from "../core/di/DecoratorInjectionTarget";
import type IAuthRepository from "../repositories/interfaces/IAuthRepository";
import { SignInInfo, SignUpInfo } from "../repositories/dto/auth";
import { IAuthorization } from "../repositories/dto/auth/Authorization";
import Container from "../core/di/DependencyContainer.ts";

//@injectionTarget()
export default class AuthService {
  authRepository: IAuthRepository;

  constructor(
    //@inject("authRepository") authRepository?: IAuthRepository
  ) {
    let authRepository: IAuthRepository | null = Container.get("authRepository");
    // ensures userRepository exists and no checks for undefined are required throughout the class
    if (authRepository == null) {
      throw Error("No AuthRepository provided or injected.");
    }
    this.authRepository = authRepository;
  }

  async signIn(
    email: string,
    password: string
  ): Promise<IAuthorization | Error> {
    const signInInfo: SignInInfo = {
      email: email,
      password: password
    };

    try {
      return await this.authRepository.signIn(signInInfo);
    } catch (error: any) {
      switch (error.type) {
        default:
          return new Error(error.type);
      }
    }
  }

  async signUp(
    email: string,
    password: string
  ): Promise<IAuthorization | Error> {
    const signUpInfo: SignUpInfo = {
      email: email,
      password: password,
      passwordConfirmation: password
    };

    try {
      return await this.authRepository.signUp(signUpInfo);
    } catch (error: any) {
      switch (error.type) {
        default:
          return new Error(error.type);
      }
    }
  }

  async logout(): Promise<void | Error> {
    try {
      return await this.authRepository.logout();
    } catch (error: any) {
      switch (error.type) {
        default:
          return new Error(error.type);
      }
    }
  }
}
