<script setup lang="ts">
import {SVGAttributes} from "vue";

interface IconProps extends/* @vue-ignore */ SVGAttributes {
}

const props = defineProps<IconProps>();
</script>

<template>
  <svg v-bind="props" width="69" height="15" viewBox="0 0 69 15" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1260_1661)">
      <mask id="mask0_1260_1661" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="69" height="15">
        <path d="M68.5714 0H0V15H68.5714V0Z" fill="white"/>
      </mask>
      <g mask="url(#mask0_1260_1661)">
        <path d="M22.7877 7.17093C22.7142 6.48368 22.6591 6.00766 22.6209 5.74133C22.5826 5.89593 22.4938 6.22348 22.3545 6.72399C22.2152 7.22144 22.0897 7.64235 21.978 7.98674L21.0688 10.65H19.8107L18.9152 7.98674C18.7285 7.4296 18.5418 6.7898 18.3581 6.06888L18.2632 5.74133L18.0061 7.98674L17.6678 10.8735H16.2627L17.1489 3.9796H18.8357L19.8413 6.98419C19.9775 7.43113 20.0831 7.78623 20.155 8.05256C20.2729 8.4995 20.3693 8.83011 20.4459 9.04439C20.5255 8.82858 20.6234 8.49797 20.7413 8.05256C20.8147 7.78623 20.9173 7.43113 21.0505 6.98419L22.0561 3.9796H23.7321L24.6321 10.8735H23.227L22.8749 7.98674L22.7892 7.17093H22.7877Z" fill="white"/>
        <path d="M27.6015 5.66174C28.4433 5.66174 29.0877 5.89133 29.5362 6.35051C29.9847 6.80663 30.2081 7.46174 30.2081 8.31582C30.2081 9.1699 29.9847 9.82806 29.5362 10.2903C29.0877 10.7526 28.4433 10.9837 27.6015 10.9837C26.7597 10.9837 26.1275 10.7541 25.6775 10.2949C25.226 9.83265 25.001 9.17296 25.001 8.31582C25.001 7.45867 25.2244 6.80663 25.6729 6.35051C26.1214 5.89133 26.7642 5.66174 27.6015 5.66174ZM27.6015 6.75306C26.8484 6.75 26.4719 7.27041 26.4719 8.31429C26.4719 9.35816 26.8484 9.88163 27.6015 9.88163C28.3545 9.88163 28.7357 9.35969 28.7357 8.31429C28.7357 7.26888 28.3576 6.75 27.6015 6.75306Z" fill="white"/>
        <path d="M32.2914 6.48674C32.7429 5.96174 33.3352 5.69847 34.0684 5.69847C34.6026 5.69847 35.0128 5.85459 35.3021 6.16837C35.5914 6.47908 35.7352 6.91837 35.7352 7.48776V10.8719H34.2735V7.56735C34.2735 7.27041 34.2153 7.05459 34.0975 6.92143C33.9796 6.78827 33.7914 6.72245 33.5312 6.72245C33.2954 6.72245 33.0766 6.77602 32.8745 6.88317C32.671 6.98725 32.5072 7.12959 32.3832 7.31021V10.8704H30.9169V5.77653H32.1368L32.2944 6.48368L32.2914 6.48674Z" fill="white"/>
        <path d="M37.7663 8.59898C37.8428 9.46684 38.2898 9.9 39.1102 9.9C39.3643 9.9 39.6199 9.87092 39.877 9.81429C40.1342 9.75459 40.4189 9.6597 40.7296 9.52959L41.0816 10.4357C40.3071 10.7939 39.5908 10.9699 38.9326 10.9668C38.126 10.9668 37.4923 10.7327 37.0316 10.2643C36.5709 9.79592 36.3413 9.14541 36.3413 8.31276C36.3413 7.4801 36.5663 6.83725 37.0178 6.37653C37.4694 5.91123 38.1015 5.67857 38.9143 5.67857C40.4418 5.67857 41.2056 6.54031 41.2056 8.26531V8.59745H37.7663V8.59898ZM39.8617 7.75408C39.8204 7.04541 39.5081 6.69031 38.9235 6.69031C38.599 6.69031 38.3433 6.77908 38.1566 6.95664C37.9729 7.13112 37.852 7.39592 37.7954 7.75408H39.8632H39.8617Z" fill="white"/>
        <path d="M45.0964 10.6791C44.6556 10.8597 44.2331 10.95 43.8291 10.95C42.7041 10.95 42.1423 10.4158 42.1423 9.34592V6.80204H41.299V5.78113H42.2372L42.5188 4.32398H43.5949V5.78113H44.9908V6.80204H43.5949V9.14235C43.5949 9.40255 43.6423 9.58776 43.7372 9.69796C43.8352 9.80817 44.002 9.8648 44.2377 9.8648C44.4382 9.8648 44.6525 9.825 44.8806 9.74541L45.0949 10.6806L45.0964 10.6791Z" fill="white"/>
        <path d="M46.7281 8.59898C46.8046 9.46684 47.2516 9.9 48.072 9.9C48.3261 9.9 48.5817 9.87092 48.8388 9.81429C49.096 9.75459 49.3806 9.6597 49.6914 9.52959L50.0434 10.4357C49.2689 10.7939 48.5526 10.9699 47.8944 10.9668C47.0878 10.9668 46.4541 10.7327 45.9934 10.2643C45.5327 9.79592 45.3031 9.14541 45.3031 8.31276C45.3031 7.4801 45.5281 6.83725 45.9796 6.37653C46.4312 5.91123 47.0618 5.67857 47.8761 5.67857C49.4036 5.67857 50.1674 6.54031 50.1674 8.26531V8.59745H46.7281V8.59898ZM48.8235 7.75408C48.7822 7.04541 48.4699 6.69031 47.8852 6.69031C47.5608 6.69031 47.3051 6.77908 47.1184 6.95664C46.9347 7.13112 46.8138 7.39592 46.7572 7.75408H48.825H48.8235Z" fill="white"/>
        <path d="M52.2031 6.48674C52.5245 5.96174 52.973 5.69847 53.5515 5.69847C53.6755 5.69847 53.7796 5.70612 53.8653 5.72296C53.9541 5.73521 54.0673 5.76123 54.2036 5.79949L54.0367 7.07143C53.7643 6.97347 53.5224 6.92602 53.3127 6.92908C53.0847 6.92908 52.8903 6.97041 52.7311 7.05306C52.5719 7.13266 52.4388 7.25817 52.3316 7.43266V10.875H50.8638V5.78112H52.0255L52.2015 6.48827L52.2031 6.48674Z" fill="white"/>
        <path d="M55.9974 9.08418C55.9974 9.375 56.0556 9.58775 56.1735 9.72092C56.2913 9.85408 56.4796 9.9199 56.7398 9.9199C56.9679 9.9199 57.1837 9.86939 57.3872 9.76837C57.5939 9.66428 57.7576 9.52041 57.877 9.33673V5.78112H59.3449V10.875H58.1357L57.9689 10.1633C57.7531 10.4235 57.499 10.6194 57.2066 10.7525C56.9143 10.8857 56.5929 10.9515 56.2393 10.9515C55.6867 10.9515 55.2643 10.7985 54.9719 10.4908C54.6796 10.1832 54.5342 9.74234 54.5342 9.16683V5.78265H55.9959V9.08724L55.9974 9.08418Z" fill="white"/>
        <path d="M61.8505 6.48674C62.2761 5.96174 62.8424 5.69847 63.5511 5.69847C63.9169 5.69847 64.2291 5.775 64.4893 5.92653C64.7526 6.075 64.95 6.29235 65.0801 6.57704C65.322 6.2801 65.5883 6.0597 65.8807 5.91735C66.173 5.77194 66.5021 5.69847 66.8664 5.69847C67.4128 5.69847 67.8337 5.85459 68.1291 6.16837C68.4245 6.47908 68.5715 6.91837 68.5715 7.48776V10.8719H67.1097V7.56735C67.1097 7.27041 67.0516 7.05459 66.9337 6.92143C66.8159 6.78827 66.6276 6.72245 66.3674 6.72245C66.1317 6.72245 65.9174 6.77449 65.7245 6.87857C65.5301 6.97959 65.3694 7.12347 65.2393 7.31021C65.2393 7.32245 65.2424 7.35306 65.2485 7.40051C65.2546 7.4449 65.2577 7.49694 65.2577 7.55663V10.8704H63.796V7.56582C63.796 7.26888 63.7378 7.05306 63.6199 6.9199C63.5021 6.78674 63.3138 6.72092 63.0536 6.72092C62.8255 6.72092 62.6128 6.77449 62.4153 6.88163C62.2179 6.98572 62.0618 7.12806 61.9439 7.30868V10.8689H60.4822V5.775H61.7021L61.8551 6.48214L61.8505 6.48674Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 0L11.25 1.00561L13.9944 3.75L15 7.5L13.9944 11.25L11.25 13.9944L7.5 15L3.75 13.9944L1.00561 11.25L0 7.5L1.00561 3.75L3.75 1.00561L7.5 0ZM4.3699 2.07857L2.07857 4.3699L1.2398 7.5L2.07857 10.6301L4.3699 12.9214L7.5 13.7602L10.6301 12.9214L12.9214 10.6301L13.7602 7.5L12.9214 4.3699L10.6301 2.07857L7.5 1.2398L4.3699 2.07857Z" fill="white"/>
        <path d="M4.89795 6.73469L7.49999 8.2653L10.102 6.73469L7.49999 2.44897L4.89795 6.73469Z" fill="white"/>
        <path d="M3.21576 6.7347L4.89791 7.69679L7.49995 9.18368L10.102 7.69679L11.7857 6.7347L10.8566 11.4918L9.79587 8.87755L7.49995 12.551L5.20403 8.87755L4.14332 11.4918L3.21423 6.7347H3.21576Z" fill="white"/>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1260_1661">
        <rect width="69" height="15" fill="white"/>
      </clipPath>
    </defs>
  </svg>

</template>

<style scoped lang="scss"></style>
