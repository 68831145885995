import type {RouteRecordRaw} from "vue-router";
import {RouteNames} from "../utils/constants.ts";

export const routes: Array<RouteRecordRaw> = [
        {
            path: "/auth",
            children: [
                {
                    path: "login",
                    name: RouteNames.AUTH_LOGIN,
                    component: () => import("../views/Guest/Auth/AuthForm.vue"),
                    meta: {title: "Login", requiresAuth: false}
                },
                {
                    path: "reset-password",
                    name: RouteNames.AUTH_PASSWORD_RESET,
                    component: () => import("../views/Guest/Auth/PasswordReset.vue"),
                    meta: {title: "Reset password", requiresAuth: false}
                },
                {
                    path: "signup",
                    name: RouteNames.SIGNUP_EMAIL,
                    component: () => import("../views/Guest/Signup/Onboard/Email.vue"),
                    meta: {title: "Signup", requiresAuth: false}
                },
                {
                    path: "onboard",
                    meta: {requiresAuth: true},
                    children: [
                        {
                            path: "email-verify",
                            name: RouteNames.SIGNUP_EMAIL_VERIFY,
                            component: () => import("../views/Guest/Signup/Onboard/EmailVerify.vue"),
                            meta: {title: "Email verify", requiresAuth: true}
                        },
                        {
                            path: "phone",
                            name: RouteNames.SIGNUP_PHONE,
                            component: () => import("../views/Guest/Signup/Onboard/Phone.vue"),
                            meta: {title: "Phone", requiresAuth: true}
                        },
                        {
                            path: "phone-verify",
                            name: RouteNames.SIGNUP_PHONE_VERIFY,
                            component: () => import("../views/Guest/Signup/Onboard/PhoneVerify.vue"),
                            meta: {title: "Phone verify", requiresAuth: true}
                        },
                        {
                            path: "personal",
                            name: RouteNames.ONBOARDING_PERSON_PROFILE,
                            component: () => import("../views/Guest/Signup/Onboard/PersonProfile.vue"),
                            meta: {title: "Personal profile", requiresAuth: true}
                        },
                    ]
                },
            ]
        },
        {
            path: "/x",
            meta: {requiresAuth: true},
            children: [
                {
                    path: "dashboard",
                    name: RouteNames.X_DASHBOARD,
                    component: () => import("../views/Auth/Dashboard.vue"),
                    meta: {title: "Dashboard", requiresAuth: true},
                    children: []
                },
                {
                    path: "accounts",
                    meta: {title: "Accounts", requiresAuth: true},
                    children: [
                        {
                            path: "crypto",
                            name: RouteNames.X_ACCOUNTS_CRYPTO,
                            component: () => import("../views/Auth/accounts/Crypto.vue"),
                            meta: {title: "Crypto wallets", requiresAuth: true},
                        },
                        {
                            path: "buy",
                            name: RouteNames.X_BUY_CRYPTO,
                            component: () => import("../views/Auth/accounts/crypto/BuyCrypto.vue"),
                            meta: {title: "Send crypto", requiresAuth: true},
                            children: []
                        },
                        {
                            path: "send",
                            name: RouteNames.X_SEND_CRYPTO,
                            component: () => import("../views/Auth/accounts/crypto/SendCrypto.vue"),
                            meta: {title: "Send crypto", requiresAuth: true},
                            children: []
                        },
                        {
                            path: "fiat",
                            name: RouteNames.X_ACCOUNTS_FIAT,
                            component: () => import("../views/Auth/accounts/Fiat.vue"),
                            meta: {title: "Cash accounts", requiresAuth: true},
                        },
                        {
                            path: "topup",
                            name: RouteNames.X_FIAT_TOPUP,
                            component: () =>
                                import("../views/Auth/accounts/fiat/TopUpBalance.vue"),
                            meta: {title: "Top Up", requiresAuth: true},
                            children: []
                        },
                        {
                            path: "send",
                            name: RouteNames.X_SEND_CASH,
                            component: () => import("../views/Auth/accounts/fiat/Send.vue"),
                            meta: {title: "Send cash", requiresAuth: true},
                            children: []
                        },
                        {
                            path: "card",
                            name: RouteNames.X_ACCOUNTS_CARD,
                            component: () => import("../views/Auth/accounts/Cards.vue"),
                            meta: {title: "Cards", requiresAuth: true},
                        },
                        {
                            path: "order",
                            name: RouteNames.X_CARD_ORDER,
                            component: () =>
                                import("../views/Auth/accounts/card/OrderCard.vue"),
                            meta: {title: "Order card", requiresAuth: true},
                            children: []
                        }
                    ]
                }
            ]
        },
        {
            path: "/forbidden",
            name:
            RouteNames.FORBIDDEN,
            component:
                () => import("../views/Forbidden.vue"),
            meta:
                {
                    title: "Forbidden"
                }
        }
        ,
        {
            path: "/:pathMatch(.*)*",
            name:
                "NotFound",
            component:
                () => import("../views/NotFound.vue"),
            meta:
                {
                    title: "Not Found"
                }
        }
    ]
;
