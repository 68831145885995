<script setup lang="ts">
import MButton from "./MButton.vue";
import IconArrowRightCircle from "../icons/IconArrowRightCircle.vue";

type IProps = {
  class?: string
  style?: string
}
const props = defineProps<IProps>();
</script>

<template>
  <MButton v-bind="props" kind="primary" size2="medium">
    Next
    <IconArrowRightCircle class="inline"></IconArrowRightCircle>
  </MButton>
</template>

<style scoped lang="scss"></style>
