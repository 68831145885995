<script setup lang="ts">


import Copyable from "../../components/Copyable.vue";

type AccountDetails = {
  name: string;
  bic: string;
  iban: string;
  address: string;
}

const accountDetails: AccountDetails = {
  name: "Michael Hansen",
  bic: "MNTRM1XXX",
  iban: "MT83 9999 8888 7777",
  address: "Kielecka 41A / 8, 02-530, Varšava, Polija",
}
type IDetail = {
  label: string;
  value: string;
}

const details: IDetail[] = [
  {label: 'Name', value: accountDetails.name},
  {label: 'BIC-Code', value: accountDetails.bic},
  {label: 'Iban', value: accountDetails.iban},
  {label: 'Address', value: accountDetails.address},
];

</script>

<template>
  <div class="flex flex-col gap-6 rounded-lg p-6 layer font-['Sora']">
    <h2>Account Details</h2>
    <div class="grid gap-8 grid-cols-1 md:grid-cols-2">
      <div v-for="detail of details" :key="detail.label">
        <div class="label">{{ detail.label.toUpperCase() }}</div>
        <div><Copyable class="value" :to-copy="detail.value">{{ detail.value }}</Copyable></div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
div.layer {
  background-color: var(--m-grey-background);
}

h2 {
  font-weight: 700;
  font-size: 24px;
}

.label {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 4px;
  color: var(--m-neutral-900);
}
.value {
  font-weight: 500;
  font-size: 20px;
  color: var(--m-dark);
}
</style>