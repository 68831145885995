<script setup lang="ts">
import {SVGAttributes} from "vue";

interface IconProps extends/* @vue-ignore */ SVGAttributes {
}

const props = defineProps<IconProps>();
</script>

<template>
  <svg
      v-bind="props"
      width="22" height="22" viewBox="0 0 22 22" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M7.68637 3.00008L10.293 0.393483C10.6835 0.00295344 11.3167 0.00295344 11.7072 0.393483L14.3138 3.00008H18.0001C18.5524 3.00008 19.0001 3.4478 19.0001 4.00008V7.68637L21.6067 10.293C21.9972 10.6835 21.9972 11.3167 21.6067 11.7072L19.0001 14.3138V18.0001C19.0001 18.5524 18.5524 19.0001 18.0001 19.0001H14.3138L11.7072 21.6067C11.3167 21.9972 10.6835 21.9972 10.293 21.6067L7.68637 19.0001H4.00008C3.4478 19.0001 3.00008 18.5524 3.00008 18.0001V14.3138L0.393483 11.7072C0.00295344 11.3167 0.00295344 10.6835 0.393483 10.293L3.00008 7.68637V4.00008C3.00008 3.4478 3.4478 3.00008 4.00008 3.00008H7.68637ZM5.00008 5.00008V8.5148L2.5148 11.0001L5.00008 13.4854V17.0001H8.5148L11.0001 19.4854L13.4854 17.0001H17.0001V13.4854L19.4854 11.0001L17.0001 8.5148V5.00008H13.4854L11.0001 2.5148L8.5148 5.00008H5.00008ZM11.0001 15.0001C8.79094 15.0001 7.00008 13.2092 7.00008 11.0001C7.00008 8.79094 8.79094 7.00008 11.0001 7.00008C13.2092 7.00008 15.0001 8.79094 15.0001 11.0001C15.0001 13.2092 13.2092 15.0001 11.0001 15.0001ZM11.0001 13.0001C12.1047 13.0001 13.0001 12.1047 13.0001 11.0001C13.0001 9.8955 12.1047 9.0001 11.0001 9.0001C9.8955 9.0001 9.0001 9.8955 9.0001 11.0001C9.0001 12.1047 9.8955 13.0001 11.0001 13.0001Z"
        fill="currentColor"/>
  </svg>
</template>

<style scoped lang="scss"></style>
