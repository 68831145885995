import { toast } from "vue3-toastify";
import { ToastTimeout } from "./constants.ts";

export function success(message: string): void {
  toast(message, {
    autoClose: ToastTimeout.SUCCESS,
    type: "success"
  });
}

export function warning(message: string): void {
  toast(message, {
    autoClose: ToastTimeout.WARNING,
    type: "warning"
  });
}

export function info(message: string): void {
  toast(message, {
    autoClose: ToastTimeout.INFO,
    type: "info"
  });
}

export function error(message: string): void {
  toast(message, {
    autoClose: ToastTimeout.ERROR,
    type: "error"
  });
}
