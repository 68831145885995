<script setup lang="ts">
import {SVGAttributes} from "vue";

interface IconProps extends/* @vue-ignore */ SVGAttributes {
}

const props = defineProps<IconProps>();
</script>

<template>
  <svg
      v-bind="props" width="23" height="23" viewBox="0 0 23 23" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.9636 6.30908H11.9273C11.4909 6.30908 11.0909 6.67272 11.0909 7.14545C11.0909 7.61817 11.4545 7.98181 11.9273 7.98181H11.9636C12.4 7.98181 12.8 7.61817 12.8 7.14545C12.8 6.67272 12.4364 6.30908 11.9636 6.30908Z" fill="currentColor"/>
    <path d="M11.9637 9.18164C11.5273 9.18164 11.1273 9.54528 11.1273 10.018V15.618C11.1273 16.0544 11.4909 16.4544 11.9637 16.4544C12.4 16.4544 12.8 16.0907 12.8 15.618V9.98164C12.8 9.54528 12.4364 9.18164 11.9637 9.18164Z" fill="currentColor"/>
    <path d="M11.9636 0.0180664C5.89092 0.0180664 0.945465 4.92716 0.945465 10.9999C0.945465 17.0726 5.89092 22.0181 11.9636 22.0181C18.0364 22.0181 22.9455 17.0726 22.9455 10.9999C22.9455 4.92716 18.0364 0.0180664 11.9636 0.0180664ZM11.9636 20.3817C6.80001 20.3817 2.58183 16.1635 2.58183 10.9999C2.58183 5.83625 6.80001 1.61807 11.9636 1.61807C17.1273 1.61807 21.3091 5.83625 21.3091 10.9999C21.3091 16.1635 17.1273 20.3817 11.9636 20.3817Z" fill="currentColor"/>
  </svg>
</template>

<style scoped lang="scss"></style>
