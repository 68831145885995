/**
 * Default string delimiter
 */
export const DELIMITER = "/";

/**
 * Route names
 */
export const RouteNames = Object.freeze({
  AUTH_LOGIN: "auth.login",
  AUTH_LOGOUT: "auth.logout",
  AUTH_SIGNUP: "auth.signup",
  AUTH_PASSWORD_RESET: "auth.password_reset",
  SIGNUP_EMAIL: "signup.email",
  SIGNUP_EMAIL_VERIFY: "signup.email.verify",
  SIGNUP_PHONE: "signup.phone",
  SIGNUP_PHONE_VERIFY: "signup.phone.verify",
  ONBOARDING_PERSON_PROFILE: "onboarding.person.profile",
  ONBOARDING_PERSON_PROFILE_VERIFY: "onboarding.person.profile.verify",
  ONBOARDING_CLIENT_PROFILE: "onboarding.client.profile",
  CALLBACK: "callback",
  DEVELOPER: "developer",
  FORBIDDEN: "forbidden",
  HOME: "home",
  X_DASHBOARD: "dashboard",
  X_ACCOUNTS_CRYPTO: "account.crypto",
  X_ACCOUNTS_FIAT: "account.fiat",
  X_ACCOUNTS_CARD: "account.cards",
  X_SEND_CASH: "account.fiat.send",
  X_SEND_CRYPTO: "account.crypto.send",
  X_CARD_ORDER: "account.card.order",
  X_FIAT_TOPUP: "account.fiat.topup",
  X_BUY_CRYPTO: "account.crypto.buy",
  X_PROFILE: "profile",
  LOGIN: "login",
  LOGOUT: "logout",
  SECURED: "secured",
  STYLINGS: "stylings",
});

/**
 * Application storage keys
 */
export const StorageKey = Object.freeze({
  AUTH: "entrypoint",
  CONFIG: "config",
});

/**
 * Default toast message display times
 */
export const ToastTimeout = Object.freeze({
  ERROR: 5000,
  INFO: 3000,
  SUCCESS: 3000,
  WARNING: 5000,
});
