<script setup lang="ts">

import TextInput from "./TextInput.vue";
import {IconCalendar} from "@iconify-prerendered/vue-carbon"

const model = defineModel<string>({
  required: true,
});

</script>

<template>
  <TextInput placeholder="DD/MM/YYYY" name="fromDate" class="w-[181px]" v-model="model" :icon-append="IconCalendar"/>
</template>

<style scoped lang="scss">

</style>