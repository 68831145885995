import {format, parseJSON} from "date-fns";
import Currency from "../libs/ledger/Currency.ts";

function _dateFnsFormat(value: string, formatter: string) {
    const formatted = "";
    try {
        if (value) {
            return format(parseJSON(value), formatter);
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(`_dateFnsFormat: Error parsing ${value} to ${error}`);
    }
    return formatted;
}

/**
 * @function formatDate
 * Converts a date to an 'MMMM D YYYY' formatted string
 * @param {String} value A string representation of a date
 * @returns {String} A string representation of `value`
 */
export function formatDate(value: string) {
    return _dateFnsFormat(value, "MMMM d yyyy");
}

/**
 * @function formatDateLong
 * Converts a date to an 'MMMM D yyyy, h:mm:ss a' formatted string
 * @param {String} value A string representation of a date
 * @returns {String} A string representation of `value`
 */
export function formatDateLong(value: string) {
    return _dateFnsFormat(value, "MMMM d yyyy, h:mm:ss a");
}

export function toKebabCase(str: string | null) {
    const strs =
        str &&
        str.match(
            /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g,
        );
    return strs ? strs.join("-").toLocaleLowerCase() : "";
}

export function formatAmount(amount: number, currency: Currency, showCurrency: boolean = false): string {
    console.log('Currency:', currency);
    const delim = Math.pow(10, currency.exponent);
    const delims = (amount / delim);
    const _major = Math.floor(delims).toLocaleString();
    const _minor = delims.toFixed(currency.exponent).split('.')[1];
    const _currency = (showCurrency ? ((currency.hasOwnProperty('symbol') ? currency['symbol'] : currency.code) + ' ') : '');
    return _currency + _major + '.' + _minor;
}
