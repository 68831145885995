<script setup lang="ts">
import { IconCheckmark } from "@iconify-prerendered/vue-carbon";

// Props
type Props = {
  helpText?: string;
  label?: string;
  name: string;
  placeholder?: string;
  disabled?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  helpText: "",
  type: "text",
  label: "",
  placeholder: "",
  disabled: false
});

const model = defineModel<boolean>();

</script>

<template>
  <div
    v-bind="props"
    class="text-zinc-800 text-[13px]/[19.5px] font-normal font-['Poppins'] flex gap-3 align-center select-none"
    @click="model = !model"
  >
    <div class="checkbox-box w-6 h-6 bg-white rounded border border-stone-300">
      <IconCheckmark class="checkbox-box-mark" v-if="model" />
    </div>
    <span>
      <slot />
    </span>
  </div>
</template>

<style scoped lang="scss">
.checkbox-box {
  background-color: var(--m-light);
  border: 1px solid var(--m-neutral-700);
}

.checkbox-box:hover {
  border: 1px solid var(--m-primary-base);
}

.checkbox-box-mark {
  color: var(--m-primary-base);
  width: 100%;
  height: 100%;
}
</style>
