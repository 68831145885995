//import injectionTarget from "../core/di/DecoratorInjectionTarget.ts";
import {IUserState} from "../repositories/dto/user/IUserState.ts";
import type IUserRepository from "../repositories/interfaces/IUserRepository.ts";
import {IChallengeResponse} from "../repositories/dto/user/IChallengeResponse.ts";
import {ISolutionResponse} from "../repositories/dto/user/ISolutionResponse.ts";
import {IRepositoryError} from "../repositories/dto/errors/IRepositoryError.ts";
import Container from "../core/di/DependencyContainer.ts";

//@injectionTarget()
export default class UserService {
    userRepository: IUserRepository;

    constructor(
        //@inject("userRepository") userRepository?: IUserRepository
    ) {
        let userRepository: IUserRepository | null = Container.get("userRepository");
        // ensures userRepository exists and no checks for undefined are required throughout the class
        if (userRepository == null) {
            throw Error("No UserRepository provided or injected.");
        }
        this.userRepository = userRepository;
    }

    async getState(): Promise<IUserState | null> {
        try {
            return await this.userRepository.getState();
        } catch (error: any) {
            console.error('Error getting state', error);
            return null;
        }
    }

    async getChallenge(challengeType: string, challengeCode: string | null): Promise<IChallengeResponse | IRepositoryError> {
        try {
            return await this.userRepository.getChallenge(challengeType, challengeCode);
        } catch (error: any) {
            return {
                code: error.code ?? 100000,
                type: error.type ?? "ERROR_CHALLENGE_COMMON",
                description: error.description ?? "Unexpected error occured"
            } as IRepositoryError;
        }
    }

    async verifySolution(challengeCode: string, solutionCode: string): Promise<ISolutionResponse | IRepositoryError> {
        try {
            return await this.userRepository.verifySolution(challengeCode, solutionCode);
        } catch (error: any) {
            return {
                code: error.code ?? 100000,
                type: error.type ?? "ERROR_CHALLENGE_COMMON",
                description: error.description ?? "Unexpected error occured"
            } as IRepositoryError;
        }
    }

    async addPhone(countryCode: string, phone: string): Promise<IChallengeResponse | IRepositoryError> {
        try {
            return await this.userRepository.addPhone(countryCode, phone);
        } catch (error: any) {
            console.log('user.service.error', error)
            return {
                code: error.code ?? 100000,
                type: error.type ?? "ERROR_CHALLENGE_COMMON",
                description: error.description ?? "Unexpected error occured"
            } as IRepositoryError;
        }
    }
}
