import { UserDTO } from "../dto/user/UserDTO.ts";
import IUserRepository from "../interfaces/IUserRepository.ts";
import { IUserState, IUserStep } from "../dto/user/IUserState.ts";
import { OnboardingStep } from "../../store/onboardingStore.ts";
import { getMockSession, updateMockSession } from "./AuthRepositoryMock.ts";
import { ClientLegalType } from "../dto/client/ClientDTO.ts";
import { IChallengeResponse } from "../dto/user/IChallengeResponse.ts";
import { ISolutionResponse } from "../dto/user/ISolutionResponse.ts";
import { useStorage } from "@vueuse/core";
import { Ref } from "vue";
import { IRepositoryError } from "../dto/errors/IRepositoryError.ts";
import { IAuthorization } from "../dto/auth/Authorization.ts";

type IChallengeStorage = {
  type: string,
  code: string,
  solution: string,
  time: number
}

export default class UserRepositoryMock implements IUserRepository {
  createPersonProfile(): Promise<UserDTO> {
    return Promise.resolve({} as UserDTO);
  }

  getState(): Promise<IUserState> {
    const userState = getMockSession();
    console.log("UserRepositoryMock.getState() %s ", "userState", userState);

    if (!userState || !userState.user) {
      return Promise.resolve({} as IUserState);
    }

    function getStep(id: string, validated: boolean = false): IUserStep {
      return {
        key: id,
        isValid: validated,
        isMandatory: true
      };
    }

    const state: IUserState = {
      person: userState.user,
      steps: [],
      currentStep: OnboardingStep.EMAIL_PASSWORD,
      restrictionReason: null
    };

    if (userState.user.clients.length > 0) {
      const user = userState.user;
      const client = userState.user.clients[0];

      if (client.legalType === ClientLegalType.natural) {
        state.steps = [];
        state.currentStep = OnboardingStep.DONE;
      } else {
        if (!user.profile) {
          state.steps = [
            getStep(OnboardingStep.APPLICATION_PERSONAL),
            getStep(OnboardingStep.APPLICATION_COMPANY),
            getStep(OnboardingStep.APPLICATION_COMPANY_QUESTIONNAIRE),
            getStep(OnboardingStep.APPLICATION_COMPANY_OWNERS)
          ];
          state.currentStep = OnboardingStep.APPLICATION_PERSONAL;
        } else if (!client.profile) {
          state.steps = [
            getStep(OnboardingStep.APPLICATION_PERSONAL, true),
            getStep(OnboardingStep.APPLICATION_COMPANY),
            getStep(OnboardingStep.APPLICATION_COMPANY_QUESTIONNAIRE),
            getStep(OnboardingStep.APPLICATION_COMPANY_OWNERS)
          ];
          state.currentStep = OnboardingStep.APPLICATION_COMPANY;
        } else if (!client.profile) {
          state.steps = [
            getStep(OnboardingStep.APPLICATION_PERSONAL, true),
            getStep(OnboardingStep.APPLICATION_COMPANY, true),
            getStep(OnboardingStep.APPLICATION_COMPANY_QUESTIONNAIRE),
            getStep(OnboardingStep.APPLICATION_COMPANY_OWNERS)
          ];
          state.currentStep = OnboardingStep.APPLICATION_COMPANY_QUESTIONNAIRE;
        } else if (!client.profile) {
          state.steps = [
            getStep(OnboardingStep.APPLICATION_PERSONAL, true),
            getStep(OnboardingStep.APPLICATION_COMPANY, true),
            getStep(OnboardingStep.APPLICATION_COMPANY_QUESTIONNAIRE, true),
            getStep(OnboardingStep.APPLICATION_COMPANY_OWNERS)
          ];
          state.currentStep = OnboardingStep.APPLICATION_COMPANY_OWNERS;
        } else {
          state.steps = [
            getStep(OnboardingStep.APPLICATION_PERSONAL, true),
            getStep(OnboardingStep.APPLICATION_COMPANY, true),
            getStep(OnboardingStep.APPLICATION_COMPANY_QUESTIONNAIRE, true),
            getStep(OnboardingStep.APPLICATION_COMPANY_OWNERS, true)
          ];
          state.currentStep = OnboardingStep.DONE;
        }
      }

      return Promise.resolve(state);
    }

    if (!userState.email?.verified) {
      state.steps = [
        getStep(OnboardingStep.EMAIL_VERIFY),
        getStep(OnboardingStep.PHONE),
        getStep(OnboardingStep.PHONE_VERIFY)
      ];
      state.currentStep = OnboardingStep.EMAIL_VERIFY;
    } else if (!userState.phone) {
      state.steps = [
        getStep(OnboardingStep.EMAIL_VERIFY, true),
        getStep(OnboardingStep.PHONE),
        getStep(OnboardingStep.PHONE_VERIFY)
      ];
      state.currentStep = OnboardingStep.PHONE;
    } else if (!userState.phone.verified) {
      state.steps = [
        getStep(OnboardingStep.EMAIL_VERIFY, true),
        getStep(OnboardingStep.PHONE, true),
        getStep(OnboardingStep.PHONE_VERIFY)
      ];
      state.currentStep = OnboardingStep.PHONE_VERIFY;
    } else {
      state.steps = [
        getStep(OnboardingStep.EMAIL_VERIFY, true),
        getStep(OnboardingStep.PHONE, true),
        getStep(OnboardingStep.PHONE_VERIFY, true)
      ];
      state.currentStep = OnboardingStep.DONE;
    }

    return Promise.resolve(state);
  }

  updatePersonProfile(): Promise<UserDTO> {
    return Promise.resolve({} as UserDTO);
  }

  async getChallenge(challengeType: string, challengeCode: string | null): Promise<IChallengeResponse | IRepositoryError> {
    const _challengeCode = challengeCode ? challengeCode : (Math.random() * 10e6).toFixed();
    const _solution = challengeType === "email" ? "123456" : "000000";
    const storage: Ref<IChallengeStorage> = useStorage("userStore-getChallenge", {
      type: challengeType,
      code: _challengeCode,
      solution: _solution,
      time: Date.now()
    });

    if (storage.value.type !== challengeType) {
      storage.value = {
        type: challengeType,
        code: _challengeCode,
        solution: _solution,
        time: Date.now()
      };
    }

    await new Promise((r) => setTimeout(() => r(true), 2000));

    let timeout = (Date.now() - (storage.value.time + 60));
    timeout = timeout < 0 ? 0 : timeout;

    return Promise.resolve({
      type: storage.value.type,
      code: storage.value.code,
      timeout: timeout
    } as IChallengeResponse);
  }

  async verifySolution(challengeCode: string, solutionCode: string): Promise<ISolutionResponse | IRepositoryError> {
    const storage: Ref<IChallengeStorage> = useStorage("userStore-getChallenge", {}) as Ref<IChallengeStorage>;

    await new Promise((r) => setTimeout(() => r(true), 2000));

    const state: IAuthorization | null = getMockSession();
    let verified = challengeCode === storage.value.code && storage.value.solution === solutionCode;
    console.log("challengeCode (%s) === storage.value.code (%s) && storage.value.solution (%s) === solutionCode (%s)", challengeCode, storage.value.code, storage.value.solution, solutionCode);
    if (verified) {
      if (state != null) {
        const fld = storage.value.type === "sms" ? "phone" : "email";
        if (state.hasOwnProperty(fld)) {
          Object.assign(state[fld] ?? {}, {
            verified: true,
            verifiedAt: new Date()
          });

          updateMockSession(state);
        } else {
          verified = false;
        }
      }
    }

    return {
      verified: verified,
      challenge: {
        type: storage.value.type,
        code: storage.value.code,
        timeout: 10000
      }
    } as ISolutionResponse;
  }

  async addPhone(countryCode: string, phone: string): Promise<IChallengeResponse | IRepositoryError> {
    const state = getMockSession();
    updateMockSession(Object.assign(state ?? {}, {
      phone: {
        id: "keyId",
        phoneShort: phone,
        phoneCountry: countryCode,
        phoneFull: countryCode + "" + phone,
        verified: false,
        verifiedAt: null,
        createdAt: new Date
      }
    }));

    return this.getChallenge("phone", null);
  }
}
