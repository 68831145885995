import { IAuthorization } from "../dto/auth/Authorization.ts";
import ILedgerRepository, {
  LSAccount,
  LSAccountsList, LSBalance,
  LSList,
  LSTransactionDirection,
  LSTransactionShort,
  LSTransactionStatus,
  LSTransactionType
} from "../interfaces/ILedgerRepository.ts";
import moment from "moment";

export default class LedgerRepositoryMock implements ILedgerRepository {
  static sessions: Map<string, IAuthorization> = new Map<
    string,
    IAuthorization
  >();

  getAccount(accountId: string): Promise<LSAccount> {
    return Promise.resolve({ id: accountId } as LSAccount);
  }

  getAccounts(/*params: LSGetAccountsParams*/): Promise<LSAccountsList> {
    return Promise.resolve([{}] as LSAccount[]);
  }

  async getTransactions(
    /*params: LSGetTransactionsParams,*/
  ): Promise<LSList<LSTransactionShort>> {
    await new Promise((resolve) => setTimeout(resolve, 3000));
    return {
      list: [
        {
          transactionId: "trx-1",
          type: LSTransactionType.Own,
          createdAt: moment("2024-02-12 12:34:45"),
          signedAt: moment("2024-02-12 12:34:45"),
          executedAt: moment("2024-02-12 12:34:45"),
          fromAccountId: "acc-1",
          toAccountId: "acc-2",
          direction: LSTransactionDirection.Out,
          status: LSTransactionStatus.Created,
          description: "Description",
          amount: {
            amount: 12700,
            currency: "EUR"
          },
          feeAmount: {
            amount: 23,
            currency: "EUR"
          },
          documents: [],
          from: {
            name: "Janis Ozolins",
            iban: "",
            swift: "",
            countryCode: ""
          },
          to: {
            name: "Janis Ozolins",
            iban: "",
            swift: "",
            countryCode: ""
          }
        },{
          transactionId: "trx-1",
          type: LSTransactionType.Own,
          createdAt: moment("2024-02-12 12:34:45"),
          signedAt: moment("2024-02-12 12:34:45"),
          executedAt: moment("2024-02-12 12:34:45"),
          fromAccountId: "acc-1",
          toAccountId: "acc-2",
          direction: LSTransactionDirection.Out,
          status: LSTransactionStatus.Created,
          description: "Description",
          amount: {
            amount: 12700,
            currency: "EUR"
          },
          feeAmount: {
            amount: 23,
            currency: "EUR"
          },
          documents: [],
          from: {
            name: "Janis Ozolins",
            iban: "",
            swift: "",
            countryCode: ""
          },
          to: {
            name: "Janis Ozolins",
            iban: "",
            swift: "",
            countryCode: ""
          }
        },{
          transactionId: "trx-1",
          type: LSTransactionType.Own,
          createdAt: moment("2024-02-12 12:34:45"),
          signedAt: moment("2024-02-12 12:34:45"),
          executedAt: moment("2024-02-12 12:34:45"),
          fromAccountId: "acc-1",
          toAccountId: "acc-2",
          direction: LSTransactionDirection.Out,
          status: LSTransactionStatus.Created,
          description: "Description",
          amount: {
            amount: 12700,
            currency: "EUR"
          },
          feeAmount: {
            amount: 23,
            currency: "EUR"
          },
          documents: [],
          from: {
            name: "Janis Ozolins",
            iban: "",
            swift: "",
            countryCode: ""
          },
          to: {
            name: "Janis Ozolins",
            iban: "",
            swift: "",
            countryCode: ""
          }
        }
      ],
      meta: {
        records: 1, currentPage: 1, lastPage: 1,   minEntry: 0, maxEntry: 1
      }
    };
  }

  async getTotalBalance(accountGroup: string, currency: string): Promise<LSBalance> {
    await new Promise((resolve) => setTimeout(resolve, 3000));

    if (accountGroup === 'crypto') {
      return {
        totalAmount: 0,
        onholdAmount: 0,
        availableAmount: 0,
        currencyCode: currency,
      };
    }

    return {
      totalAmount: 2010099,
      onholdAmount: 0,
      availableAmount: 0,
      currencyCode: currency,
    };
  }
}
