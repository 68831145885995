import { App, markRaw } from "vue";
import { createPinia, Pinia } from "pinia";
import { createPersistedState } from "pinia-plugin-persistedstate";
import { ConfigService } from "../services";
import { isDevelopment } from "../store/config.ts";
import Container from "./di/DependencyContainer.ts";
import AuthRepositoryMock from "../repositories/mocks/AuthRepositoryMock.ts";
import SessionRepositoryMock from "../repositories/mocks/SessionRepositoryMock.ts";
import AuthRepository from "../repositories/implementations/AuthRepository.ts";
import SessionRepository from "../repositories/implementations/SessionRepository.ts";
import initializeI18n from "./initializeI18n.ts";
import getRouter from "../router";
import LedgerRepositoryMock from "../repositories/mocks/LedgerRepositoryMock.ts";

import Vue3Toastify, { type ToastContainerOptions } from "vue3-toastify";
import UserRepositoryMock from "../repositories/mocks/UserRepositoryMock.ts";
import { Router } from "vue-router";

/**
 * @function initializeServices
 * Initializes and mounts the service singletons
 * Services must load in the following order: config, auth, then app.
 * @param {Function} [next=undefined] Optional callback function
 */
async function initializeServices(): Promise<void> {
  await ConfigService.init();

  if (isDevelopment()) {
    Container.register("authRepository", new AuthRepositoryMock());
    Container.register("sessionRepository", new SessionRepositoryMock());
    Container.register("ledgerRepository", new LedgerRepositoryMock());
    Container.register("userRepository", new UserRepositoryMock());
  } else {
    Container.register("authRepository", new AuthRepository());
    Container.register("sessionRepository", new SessionRepository());
    Container.register("ledgerRepository", new LedgerRepositoryMock());
    Container.register("userRepository", new UserRepositoryMock());
  }
}

export default async function initializeApp(app: App): Promise<{ router: Router, store: Pinia }> {
  await initializeServices();

  const store = createPinia();

  store.use(
    createPersistedState({
      key: (id) => `rootapi.${id}`
    })
  );
  store.use(({ store }) => {
    store.router = markRaw(router);
  });

  const router = getRouter();

  app.use(store);

  app.use(router);

  initializeI18n(app);

  app.use(Vue3Toastify, {
    autoClose: 3000
  } as ToastContainerOptions);

  /*
    app.use(PrimeVue, {
      unstyled: true,
      pt: {
        inputtext: {
          root: "inputtext-default",
        },
        password: {
          panel: "inputtext-default",
          input: "p-password-input w-2/3",
          showIcon: "p-icon p-input-icon",
        },
      },
    });*/


  return { router, store };
}
