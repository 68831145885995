<script setup lang="ts">

import {LedgerService} from "../services";
import {onMounted, onUnmounted, ref, Ref, watch} from "vue";
import {IconDataError, IconLoop, IconQuery, IconDownload, IconChevronDown} from "@iconify-prerendered/vue-carbon";
import CustomTable from "../components/CustomTable.vue";
import InputSelect from "../components/form/InputSelect.vue";
import {IStringArray} from "../interfaces/IStringArray.ts";
import IconEmptyList from "../components/icons/IconEmptyList.vue";
import {Moment} from "moment";
import {formatAmount} from "../utils/formatters.ts";
import InputCalendar from "../components/form/InputCalendar.vue";
import MButton from "../components/buttons/MButton.vue";

const ledgerService = new LedgerService();

enum LoadingStatus {
  loading = "loading",
  loaded = "loaded",
  errored = "errored",
  blank = "blank",
}

const rows: Ref<any[]> = ref([]);
const loadingStatus: Ref<LoadingStatus> = ref(LoadingStatus.blank);

type IProps = {
  showFilters?: boolean;
  showPagination?: boolean;
  limit?: number;
};
const props = withDefaults(defineProps<IProps>(), {
  showFilters: false,
  showPagination: false,
  limit: 20,
});

let interval: NodeJS.Timeout | null = null;
onMounted(() => {
  requestRows();
});

onUnmounted(() => {
  interval && clearTimeout(interval);
});

watch(loadingStatus, () => {
  if (loadingStatus.value === LoadingStatus.loading) {
    return;
  }
  //interval = setTimeout(requestRows, 2000);
});

async function requestRows() {
  loadingStatus.value = LoadingStatus.loading;
  try {
    const list = await ledgerService.getTransactions({});
    if (list.list.length > 0) {
      rows.value = list.list;
      loadingStatus.value = LoadingStatus.loaded;
    } else {
      loadingStatus.value = LoadingStatus.blank;
    }
  } catch (error) {
    loadingStatus.value = LoadingStatus.errored;
    console.log("Error:", error);
  }
}

const columns = ["createdAt", "from.name", "description", "status", "amount"];
const columnsTitles = {
  createdAt: "Date/time",
  "from.name": "Name",
  description: "Description",
  status: "Status",
  "amount.amount": "Amount",
};
const overrides = {
  createdAt: (createdAt: Moment) => {
    return createdAt.format('DD.MM.YYYY') + '<br /><span class="time-text">' + createdAt.format('HH:mm') + '</span>';
  },
  status: (status: string) => {
    const _class = (status == 'created') ? 'bg-green-600 text-green-600' : 'bg-red-600 text-red-600'
    return '<span class="' + _class + 'inline-flex items-center rounded-lg px-3 py-2 text-base font-normal bg-opacity-10">' +
        status + "</span>";
  },
  'amount': (amount: any) => {
    const _class = (amount > 0) ? 'text-green-600' : 'text-red-600'
    return '<span class="' + _class + 'inline-flex items-center text-base font-normal">' +
        formatAmount(amount.amount, amount.currency) + "</span>";
  },
};

const predefinedList = {
  'last7days': 'Last 7 days',
  'last1month': 'Last 1 month',
  'last1year': 'Last 1 year',
} as IStringArray;
const selectedFilter = ref<string>('last7days');

const filterDateFrom = ref();
const filterDateTo = ref();

</script>

<template>
  <div class="flex flex-col gap-6">
    <div
        v-if="loadingStatus == LoadingStatus.blank"
        class="justify-center items-center flex h-full"
    >
      <IconQuery class="w-12 h-12" style="color: var(--m-neutral-800)"/>
    </div>
    <div
        v-if="loadingStatus == LoadingStatus.loading"
        class="justify-center items-center flex h-full"
    >
      <IconLoop
          class="w-12 h-12 animate-pulse"
          style="color: var(--m-neutral-800)"
      />
    </div>
    <div
        v-if="loadingStatus == LoadingStatus.errored"
        class="justify-center items-center flex h-full"
    >
      <IconDataError class="w-12 h-12 animate-pulse" style="color: red"/>
    </div>

    <div class="flex justify-between items-center gap-6" v-if="props.showFilters">
      <div class="flex gap-4 items-center text-xl/[38px] text-[var(--m-dark)]">
        <InputSelect class="w-[257px]" :list="predefinedList" v-model="selectedFilter"/>
        From
        <InputCalendar v-model="filterDateFrom" />
        to
        <InputCalendar v-model="filterDateTo" />
      </div>
      <div class="w-[168px]">
        <MButton kind="secondary" size2="medium">
        <IconDownload /> Export <IconChevronDown />
      </MButton>
      </div>
    </div>
    <CustomTable
        v-if="rows.length > 0"
        :rows="rows"
        :column-titles="columnsTitles"
        :columns="columns"
        :overrides="overrides"
        :show-pagination="showPagination"
    />
    <div
        v-if="LoadingStatus.loaded && rows.length === 0"
        class="justify-center items-center flex h-full"
    >
      <IconEmptyList class="w-12 h-12" style="color: var(--m-neutral-800)"/>
    </div>
  </div>
</template>

<style scoped lang="scss">
.time-text {
  color: var(--m-neutral-800);
}
</style>