export default {
  auth: {
    field: {
      email: {
        title: "E-mail",
        placeholder: "Enter Your Email",
      },
      password: {
        title: "Password",
        placeholder: "Enter Your Password",
      },
    },
    login: {
      "page-title": "Login",
      "page-subtitle": "We make it easy for everyone to access their account",
      "reset-password-link": "Forgot password?",
      "signup-text": "Don‘t Have an Account?",
      "signup-link": "Sign Up",
    },
    signup: {
      "page-title": "Sign Up",
      "page-subtitle": "We make it easy for everyone to access their account",
      "login-text": "Already have an Account?",
      "login-link": "Log In",
    },
  },
  footer: {
    links: {
      "terms-conditions": "Terms & Conditions",
      "privacy-policy": "Privacy policy",
      "cookies-policy": "Cookie policy",
    },
  },
  "Show all": "Show all",
  Dashboard: "Dashboard",
  "menu.projects": "Projects",
  "menu.about": "About",
  "menu.about-us": "About Us",
  "menu.careers": "Careers",
  "menu.statistics": "Statistics",
  "menu.help": "Help",
  "menu.styleguide": "Styleguide",
  "menu.news": "News",
  "menu.contact": "Contact Details",
  "menu.help-support": "Help &amp; Support",
  "menu.affiliate-program": "Affiliate Program",
  "account.total-balance": "Total balance",
  "menu.overview": "Overview",
  "menu.investments": "Investments",
  "menu.statement": "Statement",
  "menu.funding": "Funding",
  "menu.profile": "Profile",
  "menu.logout": "Logout",
  "site.short-headline": "Business crowdfunding platform",
  "auth.login": "Login",
  "auth.register": "Create account",
  "overview.title": "Overview",
  "overview.balance.account": "Account balance",
  "overview.balance.add-funds": "Add funds",
  "overview.balance.available-funds": "Available funds",
  "overview.balance.invested-funds": "Invested funds",
  "overview.balance.pending-funds": "Pending funds",
  "overview.balance.pending-funds.tooltip":
    "Money that is currently in the process of being credited to your account. Pending payments can include both current and finished investments.",
  "overview.balance.in-recovery": "In recovery",
  "overview.return.title": "Net annual return",
  "overview.return.title.tooltip":
    "Net annualised return (NAR) measures the actual rate of return of all your investments since you started.",
  "overview.return.invest": "Invest",
  "overview.return.profit": "Profit",
  "overview.return.late-fees": "Late Fees",
  "overview.return.bad-debt": "Bad Debt",
  "overview.return.service-fees": "Service Fees",
  "overview.return.service-fees.tooltip":
    "Service fees include currency exchange fees, secondary market fees, inactivity fees, as well as historical fees.",
  "overview.return.affiliate-rewards": "Affiliate Rewards",
  "overview.portfolio.title": "Portfolio",
  "overview.portfolio.investments": "Investments",
  "overview.portfolio.current": "Current",
  "overview.portfolio.currentTooltip":
    "These loans are up to date on all outstanding payments.",
  "overview.portfolio.late1": "1-15 Days Late",
  "overview.portfolio.late1Tooltip":
    "Payments for this loan are 1 to 15 days late.",
  "overview.portfolio.late2": "16-30 Days Late",
  "overview.portfolio.late2Tooltip":
    "Payments for this loan are 16 to 30 days late.",
  "overview.portfolio.late3": "31-60 Days Late",
  "overview.portfolio.late3Tooltip":
    "Payments for this loan are 31 to 60 days late.",
  "overview.portfolio.late4": "60+ Days Late",
  "overview.portfolio.late4Tooltip":
    "Payments for this loan are more than 60 days late. There is a reasonable expectation that the borrower will be able to bring the loan back to current status.",
  "auth.sign-in-to": "Sign in to your account",
  "auth.or": "Or",
  "auth.create-account": "Create account",
  "auth.email": "Email address",
  "auth.password": "Password",
  "auth.oops": "Oops!",
  "auth.remember-me": "Remember me",
  "auth.forgot-password": "Forgot your password?",
  "auth.sign-in": "Sign in",
  "auth.continue-with-oauth": "Or continue with",
  "auth.sign-in-facebook": "Sign in with Facebook",
  "auth.sign-in-twitter": "Sign in with Twitter",
  "auth.sign-in-gitHub": "Sign in with GitHub",
  "project.investment.title": "Start investing",
  "project.buyback-guarantee": "Buyback guarantee",
  "project.available-funds-investment": "Your available funds",
  "investment.amount": "Amount",
  "project.enter-amount": "Enter the amount",
  "project.investment-agreement-confirmation-text":
    "I confirm that I have read and agree with",
  "project.investment-agreement": "Investment Agreement",
  "project.invest": "Invest",
  "project.schedule.status.final": "Final payment",
  "project.schedule.status.scheduled": "Scheduled",
  "project.schedule.status.paid": "Paid",
  "project.schedule.title": "Project schedule",
  "project.schedule.date": "Date",
  "project.schedule.principal": "Principal",
  "project.schedule.interest": "Interest",
  "project.schedule.total": "Total",
  "project.schedule.status": "Status",
  "account.investments.title": "Investments",
  "account.investments.country": "Country",
  "account.investments.country_short": "Ctry",
  "account.investments.id": "ID",
  "account.investments.loan": "Loan",
  "account.investments.type": "type",
  "account.investments.interest": "Interest",
  "account.investments.rate": "rate",
  "account.investments.amount": "Amount",
  "account.investments.final": "Final",
  "account.investments.payment": "payment",
  "": "",
};
