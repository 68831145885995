<script setup lang="ts">
import MCard from "../BasicCard.vue";
import {
  IconQuery,
  IconLoop,
  IconDataError
} from "@iconify-prerendered/vue-carbon";
import { Ref, ref } from "vue";
import CustomTable from "../CustomTable.vue";

enum LoadingStatus {
  loading = "loading",
  loaded = "loaded",
  errored = "errored",
  blank = "blank",
}

const rows = ref([]);
const loadingStatus: Ref<LoadingStatus> = ref(LoadingStatus.blank);
</script>

<template>
  <MCard title="Portfolio" class="h-[294px]">
    <div
      v-if="loadingStatus == LoadingStatus.blank"
      class="justify-center items-center flex h-full"
    >
      <IconQuery class="w-12 h-12" style="color: var(--m-neutral-800)" />
    </div>
    <div
      v-if="loadingStatus == LoadingStatus.loading"
      class="justify-center items-center flex h-full"
    >
      <IconLoop
        class="w-12 h-12 animate-spin animate-pulse"
        style="color: var(--m-neutral-800)"
      />
    </div>
    <div
      v-if="loadingStatus == LoadingStatus.errored"
      class="justify-center items-center flex h-full"
    >
      <IconDataError class="w-12 h-12 animate-pulse" style="color: red" />
    </div>
    <CustomTable v-if="loadingStatus == LoadingStatus.loaded" :rows="rows" :columns="['id']" />
  </MCard>
</template>

<style scoped lang="scss"></style>
