<script setup lang="ts">
import {SVGAttributes} from "vue";

interface IconProps extends/* @vue-ignore */ SVGAttributes {
}

const props = defineProps<IconProps>();
</script>

<template>
  <svg v-bind="props" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.825 11.1372H8.17498C7.72498 11.1372 7.34998 11.5122 7.34998 11.9997C7.34998 12.4497 7.72498 12.8247 8.21248 12.8247H15.8625C16.3125 12.8247 16.6875 12.4497 16.6875 11.9622C16.65 11.5122 16.275 11.1372 15.825 11.1372Z" fill="currentColor"/>
    <path d="M12 0.674805C5.73749 0.674805 0.674988 5.7373 0.674988 11.9998C0.674988 18.2623 5.77499 23.3623 12.0375 23.3623C18.3 23.3623 23.4 18.2623 23.4 11.9998C23.3625 5.7373 18.2625 0.674805 12 0.674805ZM12 21.6748C6.67499 21.6748 2.36249 17.3248 2.36249 11.9998C2.36249 6.67481 6.67499 2.3623 12 2.3623C17.325 2.3623 21.675 6.7123 21.675 12.0373C21.675 17.3248 17.325 21.6748 12 21.6748Z" fill="currentColor"/>
  </svg>
</template>

<style scoped lang="scss"></style>
