<script setup lang="ts">

import Spinner from "../components/icons/Spinner.vue";
import {RequestState} from "../services/BaseService.ts";

type IProps = {
  stateRef: RequestState
}

const props = defineProps<IProps>();

</script>

<template>
  <div class="w-full h-full relative">
    <slot/>
    <div v-if="props.stateRef === RequestState.Loading"
         class="absolute top-0 left-0 w-full h-full backdrop-blur-sm bg-white/30 rounded-[20px] text-center">
      <div class="flex justify-center items-center w-full h-full">
        <slot name="loader">
          <Spinner></Spinner>
        </slot>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>