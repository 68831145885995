<script setup lang="ts">
import { Component } from "vue";

type IProps = {
  title: string;
  buttons?: Component[];
  accounts?: string[];
};

const props: IProps = withDefaults(defineProps<IProps>(), {});
</script>

<template>
  <div class="w-full h-[86px] relative bg-white rounded-2xl shadow">
    <div class="mx-6 flex align-between item-center h-full">
      <div
        class="my-auto text-zinc-800 text-4xl/[38px] font-bold font-['Sora'] flex flex-1"
      >
        {{ props.title }}
      </div>
      <div class="justify-start items-center gap-4 flex-1 flex">
        <slot name="buttons" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
