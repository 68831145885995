<script setup lang="ts">

import PurpleBg from "../../assets/images/block/magicpattern-mesh-gradient-1717067370817.png";
import IconInfo from "../../components/icons/IconInfo.vue";
import BaseCurrencies from "../../libs/ledger/BaseCurrencies.ts";
import {onMounted, ref} from "vue";
import FiatCurrency from "../../libs/ledger/FiatCurrency.ts";
import {Balance} from "../../repositories/dto/ledger/balance/Balance.ts";
import {formatAmount} from "../../utils/formatters.ts";
import MButton from "../../components/buttons/MButton.vue";
import IconCirclePlus from "../../components/icons/IconCirclePlus.vue";
import IconCircleMinus from "../../components/icons/IconCircleMinus.vue";
import {LedgerService} from "../../services";
import LoadingWidget from "../LoadingWidget.vue";


const blockBgStyle = {
  backgroundImage: "url(" + PurpleBg + ")",
};

const fiatCurrency = ref<FiatCurrency>(BaseCurrencies.CURRENCY_EUR);

const SERVICE_REF_TOTAL_CRYPTO_BALANCE = 'totalCryptoBalance';

const ledgerService = new LedgerService<Balance>();
const {state, value: balance} = ledgerService.getRefs(SERVICE_REF_TOTAL_CRYPTO_BALANCE, {
  available: 0,
  currency: fiatCurrency.value,
  onhold: 0,
  total: 0,
});

function requestBalance() {
  ledgerService.getTotalCryptoBalance(SERVICE_REF_TOTAL_CRYPTO_BALANCE, fiatCurrency.value);
}

onMounted(() => {
  console.log('requestBalance')
  requestBalance();
  console.log('/requestBalance')
})
</script>

<template>
  <LoadingWidget :state-ref="state">
    <div
        class="h-full w-full relative rounded-2xl p-6 flex flex-col justify-between text-xl text-[var(--m-light)] bg-right-bottom bg-cover bg-no-repeat"
        :style="blockBgStyle">
      <div class="flex gap-2 items-center font-['Sora'] font-bold">
        Total Balance
        <IconInfo/>
      </div>
      <span class="text-5xl/[38px]">
        {{ formatAmount(balance.available, balance.currency, true) }}
      </span>
      <div class="flex gap-3">
        <MButton kind="primary" size2="medium">
          <IconCirclePlus/>
          Buy
        </MButton>
        <MButton kind="text" size2="medium" :disabled="true">
          <IconCircleMinus/>
          Sell
        </MButton>
      </div>
    </div>
  </LoadingWidget>
</template>

<style scoped lang="scss">

</style>