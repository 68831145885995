import { createApp } from "vue";
import { App } from "vue";

import "./assets/styles.scss";

import initializeApp from "./core/initializeApp";
import Entrypoint from "./Entrypoint.vue";


async function run(app: App): Promise<void> {
  const { router } = await initializeApp(app);
  router.isReady().then(() => {
    app.mount("#app");
  });
}

run(createApp(Entrypoint));
