<script lang="ts">
import {RouteNames} from "../../../../utils/constants.ts";

export function getRouteXAccountsFiatTopUp() {
  return {
    name: RouteNames.X_FIAT_TOPUP,
  };
}
</script>
<script setup lang="ts">
import BreadCrumbs from "../../../../components/BreadCrumbs.vue";
import BasicCard from "../../../../components/BasicCard.vue";
</script>

<template>
  <div>
    <BreadCrumbs :title="$t('Top Up Balance')" class="mb-5"></BreadCrumbs>
    <BasicCard title="Choose the Source of Funds" sidebar-title="Choose an Account">
      <template v-slot:sidebar>
      </template>
    </BasicCard>
  </div>
</template>

<style scoped lang="scss"></style>
