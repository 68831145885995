<script setup lang="ts">
import { SVGAttributes } from "vue";

interface IconProps extends/* @vue-ignore */ SVGAttributes {
}

const props = defineProps<IconProps>();
</script>

<template>
  <svg
    v-bind="props"
    width="24"
    height="24"
    viewBox="0 0 25 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.7 7.68745C13.3625 7.34995 12.8375 7.34995 12.5 7.68745C12.1625 8.02495 12.1625 8.54995 12.5 8.88745L14.75 11.175H8.2625C7.8125 11.175 7.4375 11.55 7.4375 12C7.4375 12.45 7.8125 12.825 8.2625 12.825H14.75L12.5 15.1125C12.1625 15.45 12.1625 15.975 12.5 16.3125C12.65 16.4625 12.875 16.5375 13.1 16.5375C13.325 16.5375 13.55 16.4625 13.7 16.275L17.375 12.5625C17.7125 12.225 17.7125 11.7 17.375 11.3625L13.7 7.68745Z"
      fill="currentColor"
    />
    <path
      d="M12.5 0.675049C6.23745 0.675049 1.13745 5.73755 1.13745 12C1.13745 18.2626 6.23745 23.3251 12.5 23.3251C18.7624 23.3251 23.8624 18.2626 23.8624 12C23.8624 5.73755 18.7624 0.675049 12.5 0.675049ZM12.5 21.6751C7.17495 21.6751 2.82495 17.3251 2.82495 12C2.82495 6.67505 7.17495 2.32505 12.5 2.32505C17.8249 2.32505 22.1749 6.67505 22.1749 12C22.1749 17.3251 17.8249 21.6751 12.5 21.6751Z"
      fill="currentColor"
    />
  </svg>
</template>

<style scoped lang="scss"></style>
