<script lang="ts">
import {RouteNames} from "../../../utils/constants.ts";

export function getRouteXAccountsCrypto() {
  return {
    name: RouteNames.X_ACCOUNTS_CRYPTO,
  };
}
</script>
<script setup lang="ts">
import BreadCrumbs from "../../../components/BreadCrumbs.vue";
import TransactionsTableWidget from "../../../widgets/TransactionsTableWidget.vue";
import BasicCard from "../../../components/BasicCard.vue";
import MButton from "../../../components/buttons/MButton.vue";
import CryptoTotalBalanceWidget from "../../../widgets/crypto/CryptoTotalBalanceWidget.vue";
import IconTopRight from "../../../components/icons/IconTopRight.vue";

</script>

<template>
  <div class="flex flex-col gap-6">
    <BreadCrumbs :title="$t('Crypto Wallets')"/>

    <div class="flex gap-6 h-[221px]">
      <div class="w-[384px]">
        <CryptoTotalBalanceWidget class="">
          Total balance
        </CryptoTotalBalanceWidget>
      </div>
      <div class="flex-grow">
        <BasicCard title="Transfers" class="justify-between">
          <div class="flex gap-4">
            <MButton
                kind="secondary"
                size2="medium"
                :to="RouteNames.X_SEND_CRYPTO"
            >
              Send Crypto
              <IconTopRight/>
            </MButton>
            <MButton
                kind="secondary"
                size2="medium"
                :to="RouteNames.X_SEND_CRYPTO"
            >
              Receive Crypto
            </MButton>
            <MButton
                kind="default"
                size2="medium"
                :to="RouteNames.X_SEND_CASH"
            >
              Exchange
            </MButton>
          </div>
        </BasicCard>
      </div>
    </div>

    <BasicCard title="Portfolio">
      <TransactionsTableWidget/>
    </BasicCard>
  </div>

</template>

<style scoped lang="scss"></style>
