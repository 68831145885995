<script setup lang="ts">
import {ref, VNode} from "vue";
import IconEye from "../icons/IconEye.vue";
import IconEyeCrossed from "../icons/IconEyeCrossed.vue";

// Props
type Props = {
  helpText?: string;
  label?: string;
  name: string;
  placeholder?: string;
  type: string;
  error?: string | null;
  disabled?: boolean;
  full?: boolean;
  iconPrepend?: VNode;
  iconAppend?: VNode;
};

const props = withDefaults(defineProps<Props>(), {
  helpText: "",
  type: "text",
  label: "",
  placeholder: "",
  disabled: false,
  full: false,
});


const showPass = ref(false);

const model = defineModel<string>();

const classes = "inputfield text-base/[38px] w-full"
    + (props.iconPrepend ? ' pl-12' : ' pl-4')
    + (props.iconAppend ? ' pr-12' : ' pr-4');
</script>

<template>
    <div>
      <label v-if="label" class="text-gray-500" :for="name">{{ label }}</label>
      <div class="relative">
        <div
            v-if="props.iconPrepend"
            class="absolute inset-y-0 start-0 flex items-center z-20 px-3 text-gray-400 rounded-e-md dark:text-neutral-600"
        >
          <component class="w-6 h-6" :is="props.iconPrepend"/>
        </div>

        <input
            :id="name"
            v-model.trim="model"
            :aria-describedby="`${name}-help`"
            :name="name"
            :placeholder="placeholder"
            :disabled="disabled"
            :type="showPass ? 'text' : props.type"
            :class="classes"
        />
        <div
            v-if="props.iconAppend"
            class="absolute inset-y-0 end-0 flex items-center z-20 px-3 text-gray-400 rounded-e-md dark:text-neutral-600"
        >
          <component class="w-6 h-6" :is="props.iconAppend"/>
        </div>
        <button
            type="button"
            @click="showPass = !showPass"
            v-if="props.type === 'password'"
            class="absolute inset-y-0 end-0 flex items-center z-20 px-3 cursor-pointer text-gray-400 rounded-e-md dark:text-neutral-600"
        >
          <IconEye v-if="showPass"/>
          <IconEyeCrossed v-else/>
        </button>
      </div>
      <div class="text-[14px]/[38px] color-red pt-0" v-if="props.error">
        {{ props.error }}
      </div>
      <small :id="`${name}-help`">{{ helpText }}</small>
    </div>
</template>

<style lang="scss" scoped>
.inputfield {
  @apply text-lg py-4 font-poppins rounded-lg;

  font-size: 16px;
  line-height: 38px;
  background-color: var(--m-neutral-200);
  color: var(--m-neutral-800);
  height: 54px;
}

.color-red {
  color: var(--m-red);
}

.field * {
  display: block;
}

label {
  color: var(--m-neutral-900);
}

label:disabled {
  color: var(--m-neutral-700);
}

.inputfield::-moz-placeholder,
.inputfield::placeholder {
  color: var(--m-neutral-800);
}

.inputfield {
  //color: var(--m-dark);
  //background-color: var(--m-neutral-200);
}

.inputfield:hover {
  background: var(--m-neutral-300);
}

.inputfield:disabled {
  color: var(--m-neutral-700);
  background: var(--m-neutral-100);
}


</style>
