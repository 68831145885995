<script setup lang="ts">

import PurpleBg from "../assets/images/block/magicpattern-mesh-gradient-1717067370817.png";
import {onMounted, ref, watch} from "vue";
import {Balance} from "../repositories/dto/ledger/balance/Balance.ts";
import Currencies from "../libs/ledger/BaseCurrencies.ts"
import {formatAmount} from "../utils/formatters.ts";
import IconCcBrandMastercard from "./icons/cc-brands/IconCcBrandMastercard.vue";
import IconButton from "./buttons/IconButton.vue";
import IconEye from "./icons/IconEye.vue";
import IconCog from "./icons/IconCog.vue";
import IconEyeCrossed from "./icons/IconEyeCrossed.vue";
import Spinner from "./icons/Spinner.vue";
import {ICardDetails} from "../repositories/dto/card/ICardDetails.ts";
import {CardBrandType} from "../repositories/dto/card/CardBrandType.ts";
import {CardState} from "../repositories/dto/card/CardState.ts";

type IProps = {
  cardUuid: string;
}

enum CardDetailsState {
  Hidden,
  Requested,
  Failed,
  Available
}

enum CardInfoState {
  None,
  Requested,
  Failed,
  Available
}

const blockBgStyle = {
  backgroundImage: "url(" + PurpleBg + ")",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover"
};

const props = defineProps<IProps>();

let cardDetailsHidden: ICardDetails = {
  brand: CardBrandType.Unknown,
  cvc: '•••',
  pan: '••••  ••••  ••••  ••••',
  expiry: '••/••',
  state: CardState.Unknown,
};

let cardDetailsLoaded: ICardDetails = cardDetailsHidden;

const details = ref<ICardDetails>(cardDetailsHidden);

const balance = ref<Balance>({
  available: 0,
  currency: Currencies.CURRENCY_EUR,
  onhold: 0,
  total: 0,
});

const cardInfoState = ref<CardInfoState>(CardInfoState.None);

const cardDetailsState = ref<CardDetailsState>(CardDetailsState.Hidden);

const isDataShown = ref<boolean>(false);
/*
const SERVICE_REF_CARD_DETAILS = 'card-details-' + props.cardUuid;
const SERVICE_REF_CARD_BALANCE = 'card-balance-' + props.cardUuid;

const cardService = new LedgerService<ICardDetails>();
const {state, value: balance} = cardService.getRefs(SERVICE_REF_CARD_DETAILS, {
  brand: 'mastercard',
  cvc: '•••',
  pan: '••••  ••••  ••••  ••••',
  expiry: '••/••',
});

const ledgerService = new LedgerService<Balance>();
const {state, value: balance} = ledgerService.getRefs(SERVICE_REF_CARD_BALANCE, {
  available: 0,
  currency: Currencies.CURRENCY_EUR,
  onhold: 0,
  total: 0,
});
*/
async function requestCardInfo() {
  cardInfoState.value = CardInfoState.Requested;
  await new Promise((resolve) => setTimeout(resolve, 2000));

  cardDetailsHidden.pan = '••••  ••••  ••••  5445';
  balance.value = {
    available: 2010099,
    currency: Currencies.CURRENCY_EUR,
    onhold: 0,
    total: 0,
  };

  cardInfoState.value = CardInfoState.Available;
}

async function requestCardDetails() {
  cardDetailsState.value = CardDetailsState.Requested;
  await new Promise((resolve) => setTimeout(resolve, 2000));
  cardDetailsLoaded = {
    brand: CardBrandType.Mastercard,
    cvc: '123',
    pan: '5432 2341 6374 5445',
    expiry: '12/26',
    state: CardState.Active
  };
  cardDetailsState.value = CardDetailsState.Available;
  isDataShown.value = true;
}

onMounted(() => {
  requestCardInfo();
});

watch(isDataShown, (isShown: boolean) => {
  details.value = isShown ? cardDetailsLoaded : cardDetailsHidden;
});

function onRequestCardDetails() {
  switch (cardDetailsState.value) {
    case CardDetailsState.Hidden:
    case CardDetailsState.Failed:
      requestCardDetails();
      return;
    case CardDetailsState.Requested:
      return;
    case CardDetailsState.Available:
      isDataShown.value = !isDataShown.value;
  }
}
</script>

<template>
  <div class="w-[384px] h-[242px] relative" :key="props.cardUuid">
    <div
        class="w-full h-full flex flex-col justify-between shadow text-white rounded-[20px] p-6"
        :style="blockBgStyle"
    >
      <div class="flex justify-between items-center">
        <IconCcBrandMastercard class="w-12 h-12"/>
        <transition name="slide-fade" mode="out-in">
          <span class="font-medium text-xl/[38px]" :key="details.pan">
            {{ details.pan }}
          </span>
        </transition>
      </div>
      <div class="flex justify-between items-center">
      <span class="font-light text-[40px]/[38px]">
        {{ formatAmount(balance.available, balance.currency, true) }}
      </span>
        <span class="font-semibold text-xl/[38px]">VIRTUAL</span>
      </div>
      <div class="flex justify-between items-center">
        <div class="font-medium flex flex-col justify-between">
          <span class="text-sm">EXP.</span>
          <transition name="slide-fade" mode="out-in">
            <span class="text-xl" :key="details.expiry">{{ details.expiry }}</span>
          </transition>
        </div>
        <div class="flex gap-6">
          <IconButton
              :disabled="cardDetailsState === CardDetailsState.Requested"
              @click="onRequestCardDetails">
            <IconEyeCrossed v-if="cardDetailsState === CardDetailsState.Available && isDataShown"/>
            <Spinner v-else-if="cardDetailsState === CardDetailsState.Requested"/>
            <IconEye v-else/>
          </IconButton>
          <IconButton>
            <IconCog/>
          </IconButton>
        </div>
        <div class="flex flex-col items-end justify-between">
          <span class="">CVC/CVV</span>
          <transition name="slide-fade" mode="out-in">
            <span :key="details.cvc">{{ details.cvc }}</span>
          </transition>
        </div>
      </div>
    </div>
    <div v-if="cardInfoState === CardInfoState.Requested"
         class="absolute top-0 left-0 w-full h-full backdrop-blur-sm bg-white/30 rounded-[20px] text-center">
      <div class="flex justify-center items-center w-full h-full">
        <Spinner></Spinner>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.slide-fade-enter-active {
  transition: all .3s ease;
}

.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>