<script setup lang="ts">
import {RouteLocationRaw} from "vue-router";
import IconEmptyList from "./icons/IconEmptyList.vue";
import {ref} from "vue";
import BasicTabLink from "./basic/BasicTabLink.vue";
import {type Component} from 'vue';

export type BasicCardTab = {
  key: string,
  title: string;
  node: Component;
}

type IProps = {
  title?: string;
  sidebarTitle?: string;
  showAll?: RouteLocationRaw | null;
  highlighted?: boolean,
  tabs?: BasicCardTab[],
};
const props = withDefaults(defineProps<IProps>(), {
  showAll: null,
  highlighted: false,
});

const activeTab = ref<BasicCardTab | null>(props.tabs ? props.tabs[0] : null);

function changeTab(key: string) {
  if (!props.tabs) {
    return;
  }

  for (const tab of props.tabs) {
    if (tab.key === key) {
      activeTab.value = tab;
      return;
    }
  }
}
</script>

<template>
  <div
      class="w-full h-full rounded-2xl flex"
      :class="{'bg-[var(--m-light)]': !props.highlighted, 'bg-[var(--m-blue-1)]': props.highlighted}"
  >
    <div
        class="h-full flex flex-col gap-6 p-6"
        :class="{'w-full': !$slots.sidebar, 'w-2/3': $slots.sidebar}"
    >
      <div class="w-full flex justify-between items-center">
        <div class="flex items-center gap-3">
          <h2 v-if="props.title" class="font-[Sora] font-bold text-2xl/[38px]">{{ props.title }}</h2>
          <slot name="titleWidget"></slot>
          <div v-if="props.tabs && activeTab" class="flex items-center gap-3">
            <BasicTabLink
                v-for="tab in props.tabs"
                :key="tab.key"
                :class="{active: tab.key === activeTab.key}"
                @click="changeTab(tab.key)"
            >
              {{ tab.title.toUpperCase() }}
            </BasicTabLink>
          </div>
        </div>
        <slot name="endWidget"></slot>
        <RouterLink
            v-if="props.showAll !== null"
            class="font-medium text-[16px]/[38px]"
            style="color: var(--m-primary-base)"
            :to="props.showAll"
        >
          {{ $t("Show all") }}
        </RouterLink>
      </div>

      <component v-if="activeTab" :is="activeTab?.node"/>
      <slot v-else>
        <div class="flex justify-center w-full h-full">
          <IconEmptyList/>
        </div>
      </slot>
    </div>
    <div class="w-1/3 flex flex-col gap-6 bg-[var(--m-blue-1)] p-6 rounded-r-2xl" v-if="$slots.sidebar">
      <h2 v-if="props.sidebarTitle" class="font-[Sora] font-bold text-2xl/[38px]">{{ props.sidebarTitle }}</h2>
      <slot name="sidebar">

      </slot>
      <div class="flex justify-center w-full h-full">
        <IconEmptyList/>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
