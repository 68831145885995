<script setup lang="ts">
import { ref } from "vue";

const shake = ref(false);

let timeout: NodeJS.Timeout | null = null;

function doShake() {
  shake.value = false;
  setTimeout(() => {
    shake.value = true;
  }, 100);
  timeout && clearTimeout(timeout);
  timeout = setTimeout(() => {
    shake.value = false;
  }, 1500);
}
</script>

<template>
  <div :class="{shake: shake}" @click="doShake">
    <slot />
  </div>
</template>

<style scoped lang="scss">
.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>