<script lang="ts">
import {RouteNames} from "../../utils/constants.ts";

export function getRouteXDashboard() {
  return {
    name: RouteNames.X_DASHBOARD
  };
}
</script>

<script setup lang="ts">
import BreadCrumbs from "../../components/BreadCrumbs.vue";
import MButton from "../../components/buttons/MButton.vue";
import MCard from "../../components/BasicCard.vue";

import {
  IconBtc,
  IconEth,
  IconUsdt,
  IconXrp
} from "@iconify-prerendered/vue-cryptocurrency-color";
import PortfolioTable from "../../components/functional/PortfolioTable.vue";
import TransactionsTableWidget from "../../widgets/TransactionsTableWidget.vue";
import DashboardBalancesWidget from "../../widgets/dashboard/DashboardBalancesWidget.vue";
import {getRouteXAccountsCardOrder} from "./accounts/card/OrderCard.vue";
import {getRouteXAccountsFiatSend} from "./accounts/fiat/Send.vue";
import {getRouteXAccountsCryptoSend} from "./accounts/crypto/SendCrypto.vue";
import {getRouteXAccountsCryptoBuy} from "./accounts/crypto/BuyCrypto.vue";
import {getRouteXAccountsCard} from "./accounts/Cards.vue";
import {getRouteXAccountsCrypto} from "./accounts/Crypto.vue";
import IconTopRight from "../../components/icons/IconTopRight.vue";
import IconDollarExchange from "../../components/icons/IconDollarExchange.vue";

const accounts: string[] = [];


type ICryptoAccount = {
  icon: any;
  title: string;
  price: number;
  change: number;
};

type ICurrency = {
  char: string;
  name: string;
  exponent: number;
};
type ICashAccount = {
  ccy: ICurrency;
  amount: number;
  color: string;
};
const accountsCrypto: ICryptoAccount[] = [
  {
    icon: IconBtc,
    title: "Bitcoin (BTC)",
    price: 52726.19,
    change: -3.94
  },
  {
    icon: IconEth,
    title: "Ethereum (ETH)",
    price: 52726.19,
    change: 3.94
  },
  {
    icon: IconUsdt,
    title: "TetherUS (USDT)",
    price: 52726.19,
    change: 3.94
  },
  {
    icon: IconXrp,
    title: "Ripple (XRP)",
    price: 52726.19,
    change: -3.94
  }
];

const accountsCash: ICashAccount[] = [
  {
    ccy: {
      char: "€",
      name: "EUR",
      exponent: 2
    },
    color: "#003399",
    amount: 0
  },
  {
    ccy: {
      char: "$",
      name: "USD",
      exponent: 2
    },
    color: "#6CDE07",
    amount: 0
  },
  {
    ccy: {
      char: "£",
      name: "GBP",
      exponent: 2
    },
    color: "#BC3FE0",
    amount: 0
  }
];

</script>

<template>
  <BreadCrumbs
      :title="$t('Dashboard')"
      :accounts="accounts"
      class="mb-5"
  >
    <template v-slot:buttons>

      <MButton size2="medium" kind="secondary" :to="getRouteXAccountsCryptoBuy()">Buy / Sell Crypto</MButton>

      <MButton size2="medium" kind="secondary" :to="getRouteXAccountsCrypto()">Receive Crypto</MButton>

      <div
          class="px-6 py-2 bg-gray-100 rounded-lg flex-col justify-start items-start gap-2.5 inline-flex"
      >
        <div class="justify-start items-center gap-2 inline-flex">
          <div class="w-[38px] h-[38px] relative rounded-[50px]"></div>
          <div class="text-zinc-800 text-xl/[38px] font-medium font-['Poppins']">
            EUR
          </div>
          <div
              class="w-6 h-6 px-[2.52px] pt-[6.87px] pb-[6.90px] justify-center items-center flex"
          ></div>
        </div>
      </div>
    </template>
  </BreadCrumbs>

  <DashboardBalancesWidget/>

  <div class="mt-6 flex gap-6 space-between">
    <div class="flex flex-col gap-6 w-2/3">
      <div class="h-[164px]">
        <MCard title="Payments and Transfers" class="">
          <div class="justify-end items-end gap-4 flex">
            <MButton
                kind="secondary"
                size2="medium"
                :to="getRouteXAccountsFiatSend()"
                class="flex-1"
            >
              Send Cash
              <IconTopRight/>
            </MButton>
            <MButton
                kind="secondary"
                size2="medium"
                :to="getRouteXAccountsCryptoSend()"
                class="flex-1"
            >
              Send Crypto
              <IconTopRight/>
            </MButton>
            <MButton
                kind="default"
                size2="medium"
                :to="getRouteXAccountsCryptoBuy()"
                class="flex-1"
            >
              <IconDollarExchange/>
              Exchange Cash
            </MButton>
          </div>
        </MCard>
      </div>
      <MCard title="Transactions">
        <TransactionsTableWidget/>
      </MCard>

      <PortfolioTable/>
    </div>

    <div class="flex flex-col gap-6 w-96">
      <MCard title="Cards" :highlighted="true" :show-all="getRouteXAccountsCard()">
        <MButton
            kind="dashed"
            size2="medium"
            class="flex-1"
            :to="getRouteXAccountsCardOrder()"
        >
          Add New Card
        </MButton>
      </MCard>

      <MCard title="Cash Accounts" show-all="accounts.fiat">
        <div class="flex flex-col w-full gap-6">
          <div v-for="account of accountsCash" class="flex justify-between">
            <div class="flex items-center justify-between gap-2">
              <div
                  class="w-10 h-10 rounded-full flex justify-center text-white items-center select-none font-bold text-2xl"
                  :style="{ backgroundColor: account.color }"
              >
                {{ account.ccy.char }}
              </div>
              <div class="flex flex-col gap-0">
                <div class="text-zinc-800 text-xl">
                  {{ account.ccy.name }} account
                </div>
                <div class="text-gray-400 text-base]">{{ account.amount }}</div>
              </div>
            </div>
          </div>
        </div>
      </MCard>

      <MCard title="Crypto Wallets" show-all="accounts.crypto">
        <div class="flex flex-col w-full gap-6">
          <div v-for="account of accountsCrypto" class="flex justify-between">
            <div class="flex items-center justify-between gap-2">
              <component :is="account.icon" class="w-10 h-10"/>
              <div class="flex flex-col gap-0">
                <div class="text-zinc-800 text-xl">
                  {{ account.title }}
                </div>
                <div class="text-gray-400 text-base]">{{ account.price }}</div>
              </div>
            </div>

            <div
                class="text-base/[38px]"
                :class="account.change > 0 ? 'text-green-400' : 'text-red-400'"
            >
              {{ account.change }}
            </div>
          </div>
        </div>
      </MCard>
    </div>
  </div>
</template>
