<script lang="ts">
import { RouteNames } from "../../../utils/constants.ts";

export function getRouteLogin() {
  return {
    name: RouteNames.AUTH_LOGIN
  };
}
</script>

<script setup lang="ts">
import { ref, watch } from "vue";

import FirstSignupForm from "./FirstSignupForm.vue";
import LoginForm from "./LoginForm.vue";

import Slide1 from "../../../assets/images/sliders/guest/slide1.png";
import Slide2 from "../../../assets/images/sliders/guest/slide2.png";
import Slide3 from "../../../assets/images/sliders/guest/slide3.png";
import SliderBg from "../../../assets/images/sliders/guest/bg.png";
import Slider, { ISlideItem } from "../../../components/Slider.vue";
import { getRouteSignupEmail } from "../Signup/Onboard/Email.vue";

const slides: ISlideItem[] = [
  {
    title: "Crypto",
    subtitle:
      "Crypto wallet with over 350 cryptocurrencies to buy, sell, store and transfer",
    img: Slide1
  },
  {
    title: "Banking",
    subtitle:
      "Payment account with EUR, GBP AND USD.<br/>Send and receive money worldwide.",
    img: Slide2
  },
  {
    title: "Card",
    subtitle:
      "No monthly feels and fee delivery! Grab you Free Moneterum Card today!",
    img: Slide3
  }
];


const isLoginForm = ref(true);
const pageTitle = ref("");
const pageSubtitle = ref("");
const preText = ref("");
const linkText = ref("");

const setVars = (value: boolean) => {
  pageTitle.value = value ? "auth.login.page-title" : "auth.signup.page-title";
  pageSubtitle.value = value
    ? "auth.login.page-subtitle"
    : "auth.signup.page-subtitle";
  preText.value = value ? "auth.login.signup-text" : "auth.signup.login-text";
  linkText.value = value ? "auth.login.signup-link" : "auth.signup.login-link";
};
setVars(isLoginForm.value);
watch(isLoginForm, setVars);
</script>

<template>
  <main
    class="h-full rounded-3xl flex items-center flex-1 justify-center px-4 sm:px-0"
  >
    <div class="flex rounded-3xl shadow-lg w-full bg-white sm:mx-0">
      <div
        class="hidden md:block md:w-1/2 rounded-l-3xl justify-center bg-cover"
        :style="[
          'background: url(\'' + SliderBg + '\') center center;',
        ]"
      >
        <Slider :slides="slides"></Slider>
      </div>
      <div class="flex flex-col md:w-1/2 rounded-r-3xl px-[90px] py-12">
        <div class="flex-col justify-start items-center gap-8 inline-flex">
          <div class="h-[74px] flex-col justify-start items-start gap-4 flex">
            <div
              class="text-zinc-800 text-4xl font-bold font-['Sora'] leading-[38px]"
            >
              {{ $t(pageTitle) }}
            </div>
            <div
              class="w-[420px] text-gray-400 text-sm font-normal font-['Poppins'] leading-tight"
            >
              {{ $t(pageSubtitle) }}
            </div>
          </div>

          <LoginForm v-if="isLoginForm"></LoginForm>
          <FirstSignupForm v-else></FirstSignupForm>

          <div class="justify-start items-center gap-6 inline-flex">
            <div class="text-zinc-800 text-base font-medium font-['Poppins'] leading-[38px]">
              {{ $t(preText) }}
            </div>
            <div
              class="w-[62px] h-3.5 pt-0.5 pb-px justify-center items-center flex"
            >
              <RouterLink
                :to="getRouteSignupEmail()"
                class="cursor-pointer text-center text-blue-500 text-base font-medium font-['Poppins'] leading-[38px]"
              >
                {{ $t(linkText) }}
              </RouterLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<style scoped lang="scss"></style>
