<script setup lang="ts">
import {SVGAttributes} from "vue";

interface IconProps extends/* @vue-ignore */ SVGAttributes {
}

const props = defineProps<IconProps>();
</script>

<template>
  <svg v-bind="props" width="48" height="48" viewBox="0 0 48 48" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M24 0L36 3.21796L44.782 12L48 24L44.782 36L36 44.782L24 48L12 44.782L3.21796 36L0 24L3.21796 12L12 3.21796L24 0ZM13.9837 6.65143L6.65143 13.9837L3.96735 24L6.65143 34.0163L13.9837 41.3486L24 44.0327L34.0163 41.3486L41.3486 34.0163L44.0327 24L41.3486 13.9837L34.0163 6.65143L24 3.96735L13.9837 6.65143Z" fill="currentColor"/>
    <g clip-path="url(#clip0_1266_1652)">
      <path d="M20.3201 21.5999H32.2001V26.4H20.3201C20.8759 29.1388 23.2973 31.2 26.2001 31.2C27.6766 31.2 29.0283 30.6669 30.0735 29.7823L34.1559 32.5039C32.1819 34.6531 29.3482 36 26.2001 36C20.641 36 16.0629 31.8 15.4659 26.4H13V21.5999H15.4659C16.0629 16.2001 20.641 12 26.2001 12C29.3482 12 32.1819 13.3471 34.1559 15.4962L30.0737 18.2179C29.0285 17.3333 27.6766 16.8 26.2001 16.8C23.2973 16.8 20.8759 18.8613 20.3201 21.5999Z" fill="currentColor"/>
    </g>
    <defs>
      <clipPath id="clip0_1266_1652">
        <rect width="24" height="24" fill="currentColor" transform="translate(12 12)"/>
      </clipPath>
    </defs>
  </svg>

</template>

<style scoped lang="scss"></style>
