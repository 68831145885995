<script setup lang="ts">

import {IconLogout} from "@iconify-prerendered/vue-carbon";
import MButton from "../components/buttons/MButton.vue";
import {useUserStore} from "../store";
import {storeToRefs} from "pinia";
import {watch} from "vue";



const userStore = useUserStore();
const {getUser} = storeToRefs(userStore);
const {logout} = userStore;

console.log('getUser?.id', getUser.value?.id);
watch(getUser, (user) => {
  console.log('HeaderLogoutButton watch user', user);
})

</script>

<template>
  <MButton v-if="getUser?.id" size2="medium" kind="secondary" @click="logout">
    {{ $t("Logout") }}
    <IconLogout class="inline"/>
  </MButton>
</template>

<style scoped lang="scss">

</style>