<script setup lang="ts">
import {SVGAttributes} from "vue";

interface IconProps extends/* @vue-ignore */ SVGAttributes {
}

const props = defineProps<IconProps>();
</script>

<template>
  <svg v-bind="props" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.825 11.1371H12.825V8.17461C12.825 7.72461 12.45 7.34961 11.9625 7.34961C11.5125 7.34961 11.1375 7.72461 11.1375 8.21211V11.1746H8.17498C7.72498 11.1746 7.34998 11.5496 7.34998 12.0371C7.34998 12.4871 7.72498 12.8621 8.21248 12.8621H11.175V15.8246C11.175 16.2746 11.55 16.6496 12.0375 16.6496C12.4875 16.6496 12.8625 16.2746 12.8625 15.7871V12.8246H15.825C16.275 12.8246 16.65 12.4496 16.65 11.9621C16.65 11.5121 16.275 11.1371 15.825 11.1371Z" fill="currentColor"/>
    <path d="M12 0.674805C5.73749 0.674805 0.674988 5.7373 0.674988 11.9998C0.674988 18.2623 5.77499 23.3623 12.0375 23.3623C18.3 23.3623 23.4 18.2623 23.4 11.9998C23.3625 5.7373 18.2625 0.674805 12 0.674805ZM12 21.6748C6.67499 21.6748 2.36249 17.3248 2.36249 11.9998C2.36249 6.67481 6.67499 2.3623 12 2.3623C17.325 2.3623 21.675 6.67481 21.675 11.9998C21.675 17.3248 17.325 21.6748 12 21.6748Z" fill="currentColor"/>
  </svg>
</template>

<style scoped lang="scss"></style>
