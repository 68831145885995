import {ref, Ref, shallowRef} from "vue";

export enum RequestState {
    None,
    Loading,
    Failed,
    Ready
}

export class RequestError extends Error {
    public constructor(public readonly errorCode: string, public readonly errorMessage: string) {
        super();
    }

    public getErrorCode(): string {
        return this.errorCode;
    }

    public getErrorMessage(): string {
        return this.errorMessage;
    }
}

export type IServiceRefs<T> = {
    state: Ref<RequestState>;
    value: Ref<T>;
    error: Ref<RequestError | null>;
}

type IDefaultValues<T> = {
    value: T;
    error: RequestError | null;
}

export default class BaseService<T> {
    protected refs: Map<string, IServiceRefs<T>> = new Map();
    protected defaults: Map<string, IDefaultValues<T>> = new Map();

    public getRefs(key: string, defaultValue: T, defaultError: RequestError | null = null): IServiceRefs<T> {
        if (this.refs.has(key)) {
            return this.refs.get(key)!;
        } else {
            this.defaults.set(key, {
                value: defaultValue,
                error: defaultError,
            });
            const _ref: IServiceRefs<T> = {
                state: ref<RequestState>(RequestState.None),
                value: shallowRef<T>(defaultValue),
                error: ref(defaultError),
            }
            this.refs.set(key, _ref);
            return _ref;
        }
    }

    public updateRefState(key: string, value: RequestState) {
        if (this.refs.has(key)) {
            const _refs = this.refs.get(key)!;
            _refs.state.value = value;
        }

        return self;
    }

    public updateRefValue(key: string, value: any) {
        if (this.refs.has(key)) {
            const _refs = this.refs.get(key)!;
            _refs.value.value = value;
        }

        return self;
    }

    public updateRefError(key: string, value: RequestError | null) {
        if (this.refs.has(key)) {
            const _refs = this.refs.get(key)!;
            _refs.error.value = value;
        }

        return self;
    }

    public clearRefError(key: string) {
        if (this.refs.has(key)) {
            const _refs = this.refs.get(key)!;
            _refs.error.value = this.defaults.get(key)!.error;
        }

        return self;
    }

    public clearRefValue(key: string) {
        if (this.refs.has(key)) {
            const _refs = this.refs.get(key)!;
            _refs.value.value = this.defaults.get(key)!.value;
        }

        return self;
    }
}