<script lang="ts">
export type ISlideItem = {
  title: string;
  subtitle: string;
  img: string;
};
</script>

<script setup lang="ts">
import { nextTick, Ref, ref } from "vue";

type IProps = {
  slides: ISlideItem[]
}
const props = defineProps<IProps>();

const touchstartX: Ref<number> = ref(0);
const touchstartY: Ref<number> = ref(0);
const touchendX: Ref<number> = ref(0);
const touchendY: Ref<number> = ref(0);
const previousIndex: Ref<number> = ref(0);
const currentIndex: Ref<number> = ref(0);
const previousTransition: { start: string | null, end: string | null } = {
  start: null,
  end: null
};
const currentTransition: { start: string | null, end: string | null } = {
  start: null,
  end: null
};

function handleSwipe() {
  // Stop
  if (touchstartX.value - touchendX.value > -20 && touchstartX.value - touchendX.value < 20) {
    return;
  }
  // Set previous index
  previousIndex.value = currentIndex.value;
  // Swipe
  if (touchendX < touchstartX) {
    // Swipe left
    currentIndex.value = Math.min(props.slides.length - 1, currentIndex.value + 1);
    previousTransition.start = "translate-x-none opacity-1";
    previousTransition.end = "-translate-x-1/4 opacity-0";
    currentTransition.start = "translate-x-1/4 opacity-0";
    currentTransition.end = "translate-x-none opacity-1";
  } else {
    // Swipe right
    currentIndex.value = Math.max(0, currentIndex.value - 1);
    previousTransition.start = "translate-x-none opacity-1";
    previousTransition.end = "translate-x-1/4 opacity-0";
    currentTransition.start = "-translate-x-1/4 opacity-0";
    currentTransition.end = "translate-x-none opacity-1";
  }
  // Check previous index
  if (previousIndex !== currentIndex) {
    //showSlide();
  }
}

function pointerDown(e: MouseEvent) {
  touchstartX.value = e.screenX;
  touchstartY.value = e.screenY;
}

function pointerUp(e: MouseEvent) {
  touchendX.value = e.screenX;
  touchendY.value = e.screenY;
  nextTick(() => handleSwipe());
}


</script>

<template>
  <div class="w-full h-full flex-col gap-0">
    <div class="w-full justify-center items-center flex">
      <div
        class="mx-[52px] cursor-grab"
        @mousedown="pointerDown"
        @mouseup="pointerUp"
      >
        <template
                    v-for="(item, index) in props.slides"
                    :key="index">
          <div class="flex-col justify-center items-center flex select-none"
               v-if="currentIndex == index">
            <img :alt="item.title" class="w-full h-full" :src="item.img" />
            <div class="flex-col justify-center items-center gap-3 inline-flex text-center ">
              <div class="text-[#467df5] text-[32px]/[38px] font-bold font-['Sora']" v-html="item.title" />
              <div class="text-[#25262a] text-xl font-normal font-['Poppins']" v-html="item.subtitle" />
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="justify-center items-center gap-3 flex mt-[30px] mb-[34px] absolut bottom-0 left-0">
      <template v-for="(_, index) in slides">
          <span @click="currentIndex = index" class="w-12 h-1 mx-1 cursor-pointer"
                :class="{'bg-main':index==currentIndex,'bg-white':index!==currentIndex}"></span>
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">

.cursor-grab {
  cursor: grab;
}

</style>