import {type SVGAttributes, VNode} from "vue";

export default class Currency {
    constructor(
        public readonly code: string,
        public readonly exponent: number,
        public readonly name: string,
        public readonly icon: (p?: SVGAttributes) => VNode,
        public readonly symbol: string,
    ) {
    }
}
