const nodeEnv: string = 'dev';//import.meta.env.NODE_ENV;

console.log("NodeENV: `%s`", nodeEnv);

let env: string;
switch (nodeEnv) {
  case "production":
    env = "production";
    break;
  case "demo":
    env = "demo";
    break;
  case "test":
    env = "test";
    break;
  case "staging":
  case "unstable":
    env = "staging";
    break;
  case "development":
  case "dev":
  default:
    env = "development";
}

export function isProduction(): boolean {
  return env === "production";
}

export function isStaging(): boolean {
  return env === "staging";
}

export function isDevelopment(): boolean {
  return env === "development";
}

export function isTesting(): boolean {
  return env === "test";
}

export function isDemo(): boolean {
  return env === "demo";
}

function getWebSocketDomain() {
  return document.location.origin
    .replace("https://", "wss://")
    .replace("http://", "ws://");
}

export function getDevConfig() {
  return appConfig;
}

export let appConfig = {
  dataToken: "",

  loginUrl: "/invest/login",
  logoutUrl: "/invest/logout",

  testsPlatformUrl: "/tests",
  mediaUrl: "",
  apiUrl: "/invest/api",
  apiBasePath: "/invest/api",
  apiWebSocketBase: `${getWebSocketDomain()}/invest/ws`,

  switchUrls: {
    borrower: "/lend/switch/",
    investor: "/invest/switch/",
    partner: "/partner/switch/",
  },
  createUrls: {
    borrower: "/lend/create/",
    investor: "/invest/create/",
    partner: "/partner/create/",
  },

  app: {
    gitRev: "HH",
    name: "appName",
    nodeVersion: "vv",
    version: ["1"],
  },
  apiPath: "FRONTEND_APIPATH",
  notificationBanner: "FRONTEND_NOTIFICATION_BANNER",
  oidc: {
    authority: "FRONTEND_OIDC_AUTHORITY",
    clientId: "FRONTEND_OIDC_CLIENTID",
  },
  idpList: [],
  endpoints: ["/api/v1"],
  versions: [1],
};

if (isDemo()) {
  appConfig = {
    ...appConfig,
    testsPlatformUrl: 'TEST___!!',//`${import.meta.env.TARGET_HOST || "https://lend.rootapi.dev"}/tests`,
    mediaUrl: 'MEDIA___!!',//`${import.meta.env.TARGET_HOST || "https://lend.rootapi.dev"}`,
    apiUrl: 'API___!!',//`${import.meta.env.TARGET_HOST || "https://lend.rootapi.dev"}/invest/api`,
  };
}

if (!isProduction() && !isStaging() && !isDemo()) {
  appConfig = {
    ...appConfig,
    testsPlatformUrl:
      'tests',//import.meta.env.TESTS_PLATFORM_URL || "https://stage-lend.rootapi.dev/tests",
    mediaUrl: "http://localhost:8001",
    apiUrl: "http://localhost:8001/invest/api",
    apiWebSocketBase: "ws://localhost:9001/invest/ws",
  };
}

if ((window as any).runtime_env) {
  appConfig = {
    ...appConfig,
    mediaUrl:
      (window as any).runtime_env?.REACT_MEDIA_URL || appConfig.mediaUrl,
    apiUrl: (window as any).runtime_env?.REACT_API_URL || appConfig.apiUrl,
    apiWebSocketBase:
      (window as any).runtime_env?.REACT_WEBSOCKET_URL_BASE ||
      appConfig.apiWebSocketBase,
  };
}
