<script setup lang="ts">
import {SVGAttributes} from "vue";

interface IconProps extends/* @vue-ignore */ SVGAttributes {
}

const props = defineProps<IconProps>();
</script>

<template>
  <svg v-bind="props" width="23" height="24" viewBox="0 0 23 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.8875 7.3499H1.2625C0.8125 7.3499 0.4375 7.7249 0.4375 8.1749C0.4375 8.6249 0.8125 8.9999 1.2625 8.9999H2.875V9.3749C2.875 9.8249 3.25 10.1999 3.7 10.1999C4.15 10.1999 4.525 9.8249 4.525 9.3749V8.9249C5.4625 8.6624 6.1375 7.7999 6.1375 6.7499C6.1375 5.5124 5.125 4.4999 3.8875 4.4999H2.6875C2.35 4.4999 2.125 4.2374 2.125 3.9374C2.0875 3.6374 2.35 3.3749 2.6875 3.3749H5.3125C5.7625 3.3749 6.1375 2.9999 6.1375 2.5499C6.1375 2.0999 5.7625 1.7249 5.3125 1.7249H4.525V1.3499C4.525 0.899902 4.15 0.524902 3.7 0.524902C3.25 0.524902 2.875 0.899902 2.875 1.3499V1.7249H2.6875C1.45 1.7249 0.4375 2.7374 0.4375 3.9749C0.4375 5.2124 1.45 6.2249 2.6875 6.2249H3.925C4.2625 6.2249 4.4875 6.4874 4.4875 6.7874C4.4875 7.0874 4.225 7.3499 3.8875 7.3499Z" fill="currentColor"/>
    <path d="M22 21.8249C21.325 21.8249 20.65 21.5624 20.125 21.1499H21.175C21.625 21.1499 22 20.7749 22 20.3249C22 19.8749 21.625 19.4999 21.175 19.4999H18.9625C18.8875 19.2374 18.85 18.9374 18.85 18.6749C18.85 18.5249 18.85 18.3749 18.8875 18.2249H21.175C21.625 18.2249 22 17.8499 22 17.3999C22 16.9499 21.625 16.4999 21.175 16.4999H19.7125C20.3125 15.8624 21.1375 15.4874 22 15.4874C22.45 15.4874 22.825 15.1124 22.825 14.6624C22.825 14.2124 22.45 13.8374 22 13.8374C20.125 13.8374 18.475 14.9624 17.65 16.5374H16.6C16.15 16.5374 15.775 16.9124 15.775 17.3624C15.775 17.8124 16.15 18.1874 16.6 18.1874H17.125C17.125 18.3374 17.0875 18.4874 17.0875 18.6374C17.0875 18.9374 17.125 19.1999 17.1625 19.4624H16.5625C16.1125 19.4624 15.7375 19.8374 15.7375 20.2874C15.7375 20.7374 16.1125 21.1124 16.5625 21.1124H17.875C18.7375 22.4999 20.275 23.4749 22 23.4749C22.45 23.4749 22.825 23.0999 22.825 22.6499C22.825 22.1999 22.45 21.8249 22 21.8249Z" fill="currentColor"/>
    <path d="M10.3001 4.125H18.2876C18.7751 4.125 19.1501 4.5 19.1501 4.9875V9.375L18.6251 8.85C18.2876 8.5125 17.7626 8.5125 17.4251 8.85C17.0876 9.1875 17.0876 9.7125 17.4251 10.05L19.4126 12.0375C19.5626 12.1875 19.7876 12.3 20.0126 12.3C20.2376 12.3 20.4626 12.225 20.6126 12.0375L22.5626 10.0875C22.9001 9.75 22.9001 9.225 22.5626 8.8875C22.2251 8.55 21.7001 8.55 21.3626 8.8875L20.8751 9.375V4.9875C20.8751 3.6 19.7501 2.4375 18.3251 2.4375H10.3001C9.8501 2.4375 9.4751 2.8125 9.4751 3.2625C9.4751 3.7125 9.8501 4.125 10.3001 4.125Z" fill="currentColor"/>
    <path d="M12.7001 21.0748H4.6751C4.1876 21.0748 3.8126 20.6998 3.8126 20.2123V15.8248L4.3376 16.3498C4.4876 16.4998 4.7126 16.6123 4.9376 16.6123C5.1626 16.6123 5.3876 16.5373 5.5376 16.3498C5.8751 16.0123 5.8751 15.4873 5.5376 15.1498L3.5501 13.1623C3.2126 12.8248 2.6876 12.8248 2.3501 13.1623L0.400098 15.1123C0.0625976 15.4498 0.0625976 15.9748 0.400098 16.3123C0.737598 16.6498 1.2626 16.6498 1.6001 16.3123L2.1251 15.7873V20.2123C2.1251 21.5998 3.2501 22.7623 4.6751 22.7623H12.6626C13.1126 22.7623 13.4876 22.3873 13.4876 21.9373C13.4876 21.4873 13.1501 21.0748 12.7001 21.0748Z" fill="currentColor"/>
  </svg>

</template>

<style scoped lang="scss"></style>
