<script setup lang="ts">
import { SVGAttributes } from "vue";

interface IconProps extends/* @vue-ignore */ SVGAttributes {
}

const props = defineProps<IconProps>();
</script>

<template>
  <svg v-bind="props" width="336" height="76" viewBox="0 0 336 76" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M25.3519 0C5.81766 0 0 8.77603 0 19.6776V41.4188L86.2096 41.5383C109.241 41.5703 118.672 48.5164 128.27 58.2357C130.714 60.711 133.687 64.0659 136.591 66.2446C145.261 72.7501 156.63 76 168 76C179.37 76 190.739 72.7501 199.409 66.2446C202.313 64.0659 205.286 60.711 207.73 58.2357C217.328 48.5164 226.758 41.5702 249.79 41.5383L336 41.4188V19.6776C336 8.77603 330.182 0 310.648 0H25.3519Z" fill="currentColor"/>
  </svg>
</template>

<style scoped lang="scss"></style>
