<script setup lang="ts">
import {
  IconFacebook,
  IconLinkedin,
  IconTwitter,
  IconInstagram,
  IconTelegramPlane,
} from "@iconify-prerendered/vue-fa-brands";

type FooterSocialLink = {
  icon: any;
  url: string;
  title: string;
};
const links: FooterSocialLink[] = [
  {
    icon: IconFacebook,
    title: "Facebook",
    url: "#",
  },
  {
    icon: IconLinkedin,
    title: "LinkedIn",
    url: "#",
  },
  {
    icon: IconTwitter,
    title: "Twitter",
    url: "#",
  },
  {
    icon: IconInstagram,
    title: "Instagram",
    url: "#",
  },
  {
    icon: IconTelegramPlane,
    title: "Telegram",
    url: "#",
  },
];

type IBottomLink = {
  url: string;
  title: string;
};
const linksBottom: IBottomLink[] = [
  {
    url: "#",
    title: "footer.links.terms-conditions",
  },
  {
    url: "#",
    title: "footer.links.privacy-policy",
  },
  {
    url: "#",
    title: "footer.links.cookies-policy",
  },
];
</script>

<template>
  <footer class="h-[120px] w-full mt-4 bg-white">
    <div
      class="mx-auto w-[1200px] h-full md:flex md:items-center md:justify-between"
    >
      <div class="flex items-center justify-between">
        <svg
          width="180"
          height="40"
          viewBox="0 0 180 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_660_3536)">
            <mask
              id="mask0_660_3536"
              style="mask-type: luminance"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="180"
              height="40"
            >
              <path d="M180 0H0V40H180V0Z" fill="white" />
            </mask>
            <g mask="url(#mask0_660_3536)">
              <path
                d="M59.8179 19.1225C59.625 17.2899 59.4804 16.0205 59.3799 15.3103C59.2795 15.7225 59.0465 16.596 58.6808 17.9307C58.3152 19.2572 57.9857 20.3797 57.6924 21.298L55.3058 28.4001H52.0031L49.6527 21.298C49.1625 19.8123 48.6723 18.1062 48.1902 16.1837L47.9411 15.3103L47.2661 21.298L46.3782 28.996H42.6898L45.0161 10.6123H49.4438L52.0835 18.6245C52.4411 19.8164 52.7183 20.7633 52.9072 21.4735C53.2165 22.6654 53.4697 23.547 53.6706 24.1184C53.8795 23.5429 54.1366 22.6613 54.446 21.4735C54.6389 20.7633 54.9081 19.8164 55.2576 18.6245L57.8974 10.6123H62.2969L64.6594 28.996H60.971L60.0469 21.298L59.8219 19.1225H59.8179Z"
                fill="#25262A"
              />
              <path
                d="M72.454 15.0979C74.6638 15.0979 76.3554 15.7101 77.5326 16.9346C78.7098 18.151 79.2964 19.8979 79.2964 22.1755C79.2964 24.453 78.7098 26.2081 77.5326 27.4408C76.3554 28.6734 74.6638 29.2897 72.454 29.2897C70.2442 29.2897 68.5848 28.6775 67.4036 27.453C66.2183 26.2204 65.6277 24.4612 65.6277 22.1755C65.6277 19.8897 66.2143 18.151 67.3915 16.9346C68.5688 15.7101 70.2563 15.0979 72.454 15.0979ZM72.454 18.0081C70.4772 17.9999 69.4888 19.3877 69.4888 22.1714C69.4888 24.955 70.4772 26.351 72.454 26.351C74.4308 26.351 75.4313 24.9591 75.4313 22.1714C75.4313 19.3836 74.4388 17.9999 72.454 18.0081Z"
                fill="#25262A"
              />
              <path
                d="M84.7647 17.2978C85.95 15.8978 87.5049 15.1958 89.4294 15.1958C90.8317 15.1958 91.9085 15.6121 92.6678 16.4489C93.4272 17.2774 93.8049 18.4489 93.8049 19.9672V28.9917H89.9678V20.1795C89.9678 19.3876 89.8152 18.8121 89.5058 18.457C89.1964 18.1019 88.7022 17.9264 88.0192 17.9264C87.4004 17.9264 86.8259 18.0693 86.2955 18.355C85.7611 18.6325 85.3312 19.0121 85.0058 19.4938V28.9876H81.1567V15.404H84.3589L84.7727 17.2897L84.7647 17.2978Z"
                fill="#25262A"
              />
              <path
                d="M99.1366 22.9306C99.3375 25.2449 100.511 26.4 102.664 26.4C103.331 26.4 104.002 26.3224 104.677 26.1714C105.352 26.0122 106.1 25.7591 106.915 25.4122L107.839 27.8285C105.806 28.7836 103.926 29.253 102.198 29.2449C100.081 29.2449 98.4174 28.6204 97.2081 27.3714C95.9987 26.1224 95.396 24.3877 95.396 22.1673C95.396 19.9469 95.9866 18.2326 97.1719 17.004C98.3572 15.7632 100.017 15.1428 102.15 15.1428C106.16 15.1428 108.165 17.4408 108.165 22.0408V22.9265H99.1366V22.9306ZM104.637 20.6775C104.529 18.7877 103.709 17.8408 102.174 17.8408C101.322 17.8408 100.651 18.0775 100.161 18.551C99.679 19.0163 99.3616 19.7224 99.213 20.6775H104.641H104.637Z"
                fill="#25262A"
              />
              <path
                d="M118.378 28.4775C117.221 28.9591 116.112 29.1999 115.051 29.1999C112.098 29.1999 110.624 27.7754 110.624 24.9224V18.1387H108.41V15.4162H110.873L111.612 11.5305H114.437V15.4162H118.101V18.1387H114.437V24.3795C114.437 25.0734 114.561 25.5673 114.81 25.8611C115.067 26.155 115.505 26.306 116.124 26.306C116.65 26.306 117.213 26.1999 117.812 25.9877L118.374 28.4815L118.378 28.4775Z"
                fill="#25262A"
              />
              <path
                d="M122.661 22.9306C122.862 25.2449 124.035 26.4 126.189 26.4C126.856 26.4 127.527 26.3224 128.202 26.1714C128.877 26.0122 129.624 25.7591 130.44 25.4122L131.364 27.8285C129.331 28.7836 127.45 29.253 125.723 29.2449C123.605 29.2449 121.942 28.6204 120.733 27.3714C119.523 26.1224 118.921 24.3877 118.921 22.1673C118.921 19.9469 119.511 18.2326 120.696 17.004C121.882 15.7632 123.537 15.1428 125.675 15.1428C129.684 15.1428 131.689 17.4408 131.689 22.0408V22.9265H122.661V22.9306ZM128.162 20.6775C128.053 18.7877 127.233 17.8408 125.699 17.8408C124.847 17.8408 124.176 18.0775 123.686 18.551C123.204 19.0163 122.886 19.7224 122.737 20.6775H128.166H128.162Z"
                fill="#25262A"
              />
              <path
                d="M137.033 17.2978C137.877 15.8978 139.054 15.1958 140.573 15.1958C140.898 15.1958 141.171 15.2162 141.396 15.2611C141.629 15.2938 141.927 15.3631 142.284 15.4652L141.846 18.857C141.131 18.5958 140.496 18.4693 139.946 18.4774C139.347 18.4774 138.837 18.5876 138.419 18.808C138.001 19.0203 137.652 19.355 137.371 19.8203V28.9999H133.517V15.4162H136.567L137.029 17.3019L137.033 17.2978Z"
                fill="#25262A"
              />
              <path
                d="M146.993 24.2244C146.993 24.9999 147.146 25.5673 147.455 25.9224C147.765 26.2775 148.259 26.453 148.942 26.453C149.541 26.453 150.107 26.3183 150.642 26.0489C151.184 25.7714 151.614 25.3877 151.927 24.8979V15.4163H155.78V28.9999H152.606L152.168 27.102C151.602 27.7959 150.935 28.3183 150.167 28.6734C149.4 29.0285 148.556 29.204 147.628 29.204C146.178 29.204 145.069 28.7959 144.301 27.9754C143.534 27.155 143.152 25.9795 143.152 24.4448V15.4203H146.989V24.2326L146.993 24.2244Z"
                fill="#25262A"
              />
              <path
                d="M162.358 17.2978C163.475 15.8978 164.961 15.1958 166.821 15.1958C167.782 15.1958 168.601 15.3999 169.284 15.804C169.975 16.1999 170.494 16.7795 170.835 17.5387C171.47 16.7468 172.169 16.1591 172.937 15.7795C173.704 15.3917 174.568 15.1958 175.524 15.1958C176.958 15.1958 178.063 15.6121 178.839 16.4489C179.614 17.2774 180 18.4489 180 19.9672V28.9917H176.163V20.1795C176.163 19.3876 176.01 18.8121 175.701 18.457C175.392 18.1019 174.897 17.9264 174.214 17.9264C173.596 17.9264 173.033 18.0652 172.527 18.3427C172.017 18.6121 171.595 18.9958 171.253 19.4938C171.253 19.5264 171.261 19.608 171.277 19.7346C171.293 19.8529 171.301 19.9917 171.301 20.1509V28.9876H167.464V20.1754C167.464 19.3836 167.312 18.808 167.002 18.4529C166.693 18.0978 166.199 17.9223 165.516 17.9223C164.917 17.9223 164.358 18.0652 163.84 18.3509C163.322 18.6285 162.912 19.008 162.603 19.4897V28.9836H158.766V15.3999H161.968L162.37 17.2856L162.358 17.2978Z"
                fill="#25262A"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M19.6875 0L29.5312 2.68163L36.7353 10L39.375 20L36.7353 30L29.5312 37.3184L19.6875 40L9.84375 37.3184L2.63973 30L0 20L2.63973 10L9.84375 2.68163L19.6875 0ZM11.471 5.54286L5.45625 11.6531L3.25446 20L5.45625 28.3469L11.471 34.4571L19.6875 36.6939L27.904 34.4571L33.9187 28.3469L36.1205 20L33.9187 11.6531L27.904 5.54286L19.6875 3.30612L11.471 5.54286Z"
                fill="#25262A"
              />
              <path
                d="M12.8571 17.9591L19.6875 22.0407L26.5178 17.9591L19.6875 6.53052L12.8571 17.9591Z"
                fill="#25262A"
              />
              <path
                d="M8.44152 17.9592L12.8571 20.5248L19.6875 24.4898L26.5179 20.5248L30.9375 17.9592L28.4987 30.6449L25.7143 23.6735L19.6875 33.4694L13.6607 23.6735L10.8763 30.6449L8.4375 17.9592H8.44152Z"
                fill="#25262A"
              />
            </g>
          </g>
          <defs>
            <clipPath id="clip0_660_3536">
              <rect width="180" height="40" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <span class="copyright grow">Moneterum © 2024</span>
      </div>
      <div class="flex grow items-center justify-center">
        <RouterLink
          v-for="link of linksBottom"
          :to="link.url"
          class="flex flex-1 justify-center"
        >
          {{ $t(link.title) }}
        </RouterLink>
      </div>
      <div class="flex gap-2 md:order-2 items-center space-between w-[164px]">
        <a
          :href="link.url"
          class="text-gray-500 hover:text-gray-400"
          v-for="link in links"
        >
          <span class="sr-only">{{ link.title }}</span>
          <component :is="link.icon" class="w-[24px]" />
        </a>
      </div>
    </div>
  </footer>
</template>

<style scoped lang="scss">
.copyright {
  font-weight: 500;
  font-size: 14px;
  line-height: 38px;
  color: var(--m-neutral-700);
  margin: 1px auto 1px 32px;
}
</style>
