import { defineStore } from "pinia";
import { computed, ref } from "vue";

import type { Ref } from "vue";
import { IUserState, IUserStep } from "../repositories/dto/user/IUserState.ts";

export enum OnboardingLegalType {
  personal = "personal",
  business = "business",
}

function getOnboardingStepFromString(type: string): OnboardingStep | null {
  for (const step of Object.values(OnboardingStep)) {
    if (step.toString() === type) {
      return step;
    }
  }

  return null;
}

export enum OnboardingStep {
  DONE = "done",

  LEGAL_TYPE = "legal-type",

  EMAIL_PASSWORD = "email-password",
  EMAIL_VERIFY = "email-verify",

  PHONE = "phone",
  PHONE_VERIFY = "phone-verify",

  APPLICATION_PERSONAL = "application-personal",
  APPLICATION_COMPANY = "application-company",
  APPLICATION_COMPANY_QUESTIONNAIRE = "application-company-questionnaire",
  APPLICATION_COMPANY_OWNERS = "application-company-owners",
  APPLICATION_PERSONAL_QUESTIONNAIRE = "application-personal-questionnaire",
}

export type OnboardingStoreState = {
  legalType: Ref<OnboardingLegalType>;
  steps: Ref<OnboardingStep[]>;
  currentStep: Ref<OnboardingStep>;
};

export const useOnboardingStore = defineStore("onboarding", () => {
  // State
  const state: OnboardingStoreState = {
    legalType: ref(OnboardingLegalType.personal),
    steps: ref([]),
    currentStep: ref(OnboardingStep.DONE),
  };

  // Getters
  const getters = {
    getSteps: computed(() => state.steps),
    getCurrentStep: computed(() => state.currentStep),
    getCurrentStepIdx: computed(() =>
      state.steps.value.indexOf(state.currentStep.value),
    ),
  };

  // Actions
  function loadState(userState: IUserState) {
    state.steps.value = userState.steps
      .map((step: IUserStep) => getOnboardingStepFromString(step.key))
      .filter((step: OnboardingStep | null) => step) as OnboardingStep[];
    state.currentStep.value =
      getOnboardingStepFromString(userState.currentStep) ??
      OnboardingStep.LEGAL_TYPE;
  }

  return {
    // State
    ...state,

    // Getters
    ...getters,

    // Actions
    loadState,
  };
});

export default useOnboardingStore;
