<script setup lang="ts">
import { RouteLocationRaw } from "vue-router";
import { ButtonHTMLAttributes } from "vue";

interface IProps extends/* @vue-ignore */ ButtonHTMLAttributes {
  size2?: "large" | "medium";
  kind?: "primary" | "secondary" | "default" | "text" | "dashed";
  to?: RouteLocationRaw;
  disabled?: boolean;
}

const props = withDefaults(defineProps<IProps>(), {
  size2: "large",
  kind: "primary",
  disabled: false
});
const classType = "b-" + props.kind;
const classSize =
  props.size2 === "large"
    ? "px-8 py-4 text-xl/[38px]"
    : "px-4 py-2 text-lg/[38px]";
const classes = [
  classType,
  classSize,
  "btn",
  "w-full",
  "h-full",
  //"h-[54px]",
  "font-medium",
  "select-none",
  "text-nowrap",
  "rounded-lg",
  "justify-center",
  "items-center",
  "gap-2.5",
  "inline-flex"
];
</script>

<template>
  <div>
    <RouterLink v-bind="$props" v-if="props.to" :to="props.to" :class="classes">
      <slot></slot>
    </RouterLink>
    <button v-bind="$props" v-else :class="classes">
      <slot></slot>
    </button>
  </div>
</template>

<style scoped lang="scss">
.btn:hover {
  @apply cursor-pointer;
}

.btn:disabled:hover {
  @apply cursor-not-allowed;
}

.b-primary {
  background-color: var(--m-primary-base);
  color: #fff;
}

.b-primary:hover {
  background-color: var(--m-blue-7);
}

.b-primary:active {
  background-color: var(--m-blue-8);
}

.b-secondary {
  background-color: var(--m-blue-1);
  color: var(--m-primary-base);
}

.b-secondary:hover {
  background-color: var(--m-blue-2);
}

.b-secondary:active {
  background-color: var(--m-blue-3);
}

.b-default {
  background-color: var(--m-light);
  color: var(--m-primary-base);
  border: var(--m-primary-base) 1px solid;
}

.b-default:hover {
  background-color: var(--m-blue-2);
}

.b-default:active {
  background-color: var(--m-blue-3);
}

.b-default:disabled {
  border: var(--m-neutral-700) 1px solid;
}

.b-text {
  background-color: var(--m-light);
  color: var(--m-primary-base);
}

.b-text:hover {
  border: var(--m-blue-7) 1px solid;
}

.b-text:active {
  background-color: var(--m-blue-3);
}

.b-dashed {
  background-color: transparent;
  border: var(--m-primary-base) 1px dashed;
  color: var(--m-primary-base);
}

.b-dashed:hover {
  background-color: var(--m-blue-2);
  border: var(--m-blue-7) 1px dashed;
}

.b-dashed:active {
  background-color: var(--m-blue-3);
  border: var(--m-blue-8) 1px dashed;
}

.b-dashed:disabled {
  color: var(--m-neutral-700);
  border: var(--m-neutral-700) 1px dashed;
}

.btn:disabled {
  background-color: var(--m-neutral-200);
  color: var(--m-neutral-700);
}
</style>
