<script lang="ts">
import { RouteNames } from "../../../utils/constants.ts";

export function getRoutePasswordReset() {
  return {
    name: RouteNames.AUTH_PASSWORD_RESET,
  };
}
export default {}
</script>

<script setup lang="ts"></script>

<template></template>

<style scoped lang="scss"></style>
