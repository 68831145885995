<script setup lang="ts">
import {SVGAttributes} from "vue";

interface IconProps extends/* @vue-ignore */ SVGAttributes {
}

const props = defineProps<IconProps>();
</script>

<template>
  <svg v-bind="props" width="25" height="24" viewBox="0 0 25 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.125 19.2377C5.9 19.2377 5.675 19.1627 5.525 18.9752C5.1875 18.6377 5.1875 18.1127 5.525 17.7752L16.8875 6.4127H7.8125C7.3625 6.4127 6.9875 6.0377 6.9875 5.5877C6.9875 5.1377 7.3625 4.7627 7.8125 4.7627H18.875C19.325 4.7627 19.7 5.1377 19.7 5.5877V16.7252C19.7 17.1752 19.325 17.5502 18.875 17.5502C18.425 17.5502 18.05 17.1752 18.05 16.7252V7.6877L6.725 19.0127C6.575 19.1627 6.35 19.2377 6.125 19.2377Z" fill="currentColor"/>
  </svg>
</template>

<style scoped lang="scss"></style>
