<script lang="ts">
import {RouteNames} from "../../../../utils/constants.ts";

export function getRouteSignupEmail() {
  return {
    name: RouteNames.SIGNUP_EMAIL
  };
}
</script>
<script setup lang="ts">
import Signup from "../../../../components/layout/GuestLayout/Signup.vue";
import {TextInput} from "../../../../components/form";
import InputCheckbox from "../../../../components/form/InputCheckbox.vue";
import BackButton from "../../../../components/buttons/BackButton.vue";
import NextButton from "../../../../components/buttons/NextButton.vue";
import {Ref, ref, watch} from "vue";

import {useUserStore} from "../../../../store";
import ShakeAnimation from "../../../../components/animation/ShakeAnimation.vue";
import {error, success} from "../../../../utils/toast.ts";
import {getRouteLogin} from "../../Auth/LoginForm.vue";

const email = ref("");
const emailError: Ref<string | null> = ref("");
const password = ref("");
const passwordError: Ref<string | null> = ref("");

const agreeMarketing = ref(false);
const agreeTerms = ref(false);
const agreeShare = ref(false);

const allowContinue = ref(false);

const inSignup: Ref<boolean> = ref(false);

watch([email, password, agreeMarketing, agreeTerms, agreeShare], () => {
  allowContinue.value = !!(
      email.value &&
      password.value &&
      agreeMarketing.value &&
      agreeTerms.value &&
      agreeShare.value &&
      !inSignup.value
  );
});

watch([email], () => {
  emailError.value = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email.value)
      ? null : "Incorrect email";

});

watch([password], () => {
  passwordError.value = /^.{9,}$/.test(password.value)
      ? null : "Incorrect password";

});

const userStore = useUserStore();

const onSignup = async () => {
  inSignup.value = true;
  await userStore.signUp(email.value, password.value);
  inSignup.value = false;

  if (userStore.getIsAuthenticated) {
    success("Account created");
  } else {
    error("Error creating account");
  }
};
</script>

<template>
  <Signup>
    <div
        class="mx-auto mt-[40px] w-[636px] flex-col justify-center items-center gap-8 flex"
    >
      <div class="flex-col justify-start items-center gap-8 flex">
        <div
            class="text-center text-zinc-800 text-4xl font-bold font-['Sora'] leading-[38px]"
        >
          {{ $t("Individual Account") }}
        </div>
        <div class="justify-start items-start gap-8 inline-flex">
          <div class="flex-col justify-start items-start gap-3 inline-flex">
            <TextInput
                name="e-mail"
                v-model="email"
                label="E-mail"
                type="email"
                placeholder="Enter Your E-mail"
                :error="emailError"
            />
          </div>
          <div
              class="grow shrink basis-0 flex-col justify-start items-start gap-3 inline-flex"
          >
            <TextInput
                name="password"
                v-model="password"
                label="Password"
                type="password"
                placeholder="Enter Your Password"
                :error="passwordError"
            />
          </div>
        </div>
        <div class="h-3.5 pt-0.5 pb-px justify-center items-center inline-flex">
          <ShakeAnimation>
            <div
                class="text-center text-blue-500 text-base font-medium font-['Poppins'] leading-[38px]"
            >
              Do you have a referral code?
            </div>
          </ShakeAnimation>
        </div>
      </div>
      <div class="h-[107px] flex-col justify-start items-start gap-4 flex">
        <div
            class="w-[636px] h-[25px] pr-9 py-[0.50px] justify-start items-center inline-flex"
        >
          <div class="justify-start items-center gap-3 inline-flex">
            <InputCheckbox name="agree_promo" v-model="agreeMarketing">
              I agree to receive news and promotional emails consistent with
              <span class="text-blue-500">Privacy & Cookie Policy</span>
            </InputCheckbox>
          </div>
        </div>
        <div
            class="w-[636px] h-[25px] pr-[287px] py-[0.50px] justify-start items-center inline-flex"
        >
          <div class="justify-start items-center gap-3 inline-flex">
            <InputCheckbox name="agree_promo" v-model="agreeTerms">
              I agree to
              <span class="text-blue-500">Terms & Conditions</span> and
              <span class="text-blue-500">Privacy Policy</span>
            </InputCheckbox>
          </div>
        </div>
        <div class="w-[636px] h-[25px] pr-[139px] py-[0.50px] justify-start items-center inline-flex">
          <div class="justify-start items-center gap-3 inline-flex">
            <InputCheckbox name="agree_promo" v-model="agreeShare">
              I agree to <span class="text-blue-500">Share my Name</span> with other Moneterum users for transactions
            </InputCheckbox>
          </div>
        </div>
      </div>
      <div class="justify-center items-start gap-4 inline-flex">
        <BackButton :to="getRouteLogin()"/>
        <NextButton @click="onSignup" :disabled="!allowContinue"/>
      </div>
    </div>
  </Signup>
</template>

<style scoped lang="scss"></style>
