<script setup lang="ts">
import {SVGAttributes} from "vue";

interface IconProps extends/* @vue-ignore */ SVGAttributes {
}
const props = defineProps<IconProps>();
</script>

<template>
  <svg
      v-bind="props" width="48" height="30" viewBox="0 0 48 30" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M22.686 27.062C22.802 27.16 22.926 27.258 23.048 27.354C20.694 28.92 17.868 29.83 14.834 29.83C6.64 29.832 0 23.192 0 15C0 6.80997 6.64 0.167969 14.832 0.167969C17.868 0.167969 20.694 1.07997 23.042 2.64397C22.922 2.74597 22.802 2.83997 22.712 2.94397C19.2 5.97797 17.19 10.376 17.19 15C17.19 19.622 19.192 24.02 22.686 27.062ZM33.168 0.167969C30.128 0.167969 27.306 1.07997 24.958 2.64397C25.078 2.74597 25.198 2.83997 25.288 2.94397C28.8 5.97797 30.81 10.376 30.81 15C30.81 19.62 28.808 24.014 25.314 27.062C25.198 27.16 25.074 27.258 24.952 27.354C27.306 28.92 30.128 29.83 33.166 29.83C41.36 29.832 48 23.192 48 15C48 6.81197 41.36 0.167969 33.168 0.167969ZM24 3.34797C23.808 3.49797 23.622 3.64797 23.44 3.80997C20.312 6.52797 18.338 10.53 18.338 15C18.338 19.472 20.312 23.472 23.44 26.19C23.62 26.35 23.81 26.506 24 26.654C24.192 26.506 24.378 26.35 24.56 26.19C27.686 23.472 29.662 19.472 29.662 15C29.662 10.53 27.688 6.52797 24.56 3.80997C24.38 3.64997 24.192 3.49797 24 3.34797Z"
        fill="currentColor"/>
  </svg>
</template>

<style scoped lang="scss">

</style>