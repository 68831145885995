<script lang="ts">
import {RouteNames} from "../../../utils/constants.ts";

export function getRouteXAccountsCard() {
  return {
    name: RouteNames.X_ACCOUNTS_CARD
  };
}
</script>
<script setup lang="ts">
import BreadCrumbs from "../../../components/BreadCrumbs.vue";
import InputSelect from "../../../components/form/InputSelect.vue";
import {ref, VNode} from "vue";
import InputSearch from "../../../components/form/InputSearch.vue";
import {IconMoney, IconArrowLeft, IconArrowRight} from "@iconify-prerendered/vue-carbon";
import TransactionsTableWidget from "../../../widgets/TransactionsTableWidget.vue";
import CardsListWidget from "../../../widgets/CardsListWidget.vue";
import BasicCard from "../../../components/BasicCard.vue";

export type IBalanceBlockItem = {
  icon: VNode;
  text: string;
  amount: string;
}

const cards = {
  "card1": "••••  ••••  ••••  5445",
  "card2": "••••  ••••  ••••  5442",
  "card3": "••••  ••••  ••••  5443"
};
const selectedCard = ref(Object.keys(cards)[0]);


const BalanceBlocks: IBalanceBlockItem[] = [
  {
    text: "Payments",
    amount: "€ 372.00",
    icon: IconMoney()
  },
  {
    text: "Deposits",
    amount: "€ 302.00",
    icon: IconArrowLeft()
  },
  {
    text: "Withdrawals",
    amount: "€ 72.00",
    icon: IconArrowRight()
  }
];

</script>

<template>
  <div class="flex flex-col gap-6">
    <BreadCrumbs :title="$t('Cards')"/>

    <CardsListWidget/>

    <BasicCard title="Transactions">
      <template v-slot:titleWidget>
        <InputSelect class="w-[253px]" :list="cards" v-model="selectedCard"/>
      </template>
      <template v-slot:endWidget>
        <InputSearch class="w-[253px]" placeholder="Search"/>
      </template>

      <div class="bg-[var(--m-grey-background)] rounded-lg h-[124px] flex items-center p-6 gap-16">
        <div v-for="block in BalanceBlocks"
             class="flex gap-4 justify-center items-center select-none font-['Sora'] font-bold">
          <div class="py-2.5 flex">
            <div class="h-full w-full rounded-full bg-[var(--m-blue-2)]">
              <component :is="block.icon" class="w-8 h-8 text-[var(--m-primary-base)] m-4"></component>
            </div>
          </div>
          <div class="flex flex-col gap-2">
            <span class="text-base/[38px] text-[var(--m-primary-base)]">{{ block.text }}</span>
            <span class="text-2xl/[38px]">{{ block.amount }}</span>
          </div>
        </div>
      </div>

      <TransactionsTableWidget :show-filters="true" :show-pagination="true"/>
    </BasicCard>
  </div>

</template>

<style scoped lang="scss"></style>
