<script setup lang="ts">

import {ref, watch} from "vue";
import {IStringArray} from "../../interfaces/IStringArray.ts";

type IProps = {
  list: IStringArray;
  showSelected?: boolean;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
}

type ListItemInternal = {
  key: string;
  value: string;
  isSelected: boolean;
}

const model = defineModel<string>({
  required: true,
});

const props = withDefaults(defineProps<IProps>(), {
  showSelected: false,
  placeholder: '',
  label: '',
  disabled: false,
});


function getInternalListFromProps(list: IStringArray, selectedKey: string | null): ListItemInternal[] {
  return Object.keys(list).reduce((accum: ListItemInternal[], key: string) => {
    const isSelected = key === selectedKey;

    if (!props.showSelected && isSelected) {
      return accum;
    }

    accum.push({
      value: list[key],
      key,
      isSelected,
    });

    return accum;
  }, []);
}

const list = ref<ListItemInternal[]>(getInternalListFromProps(props.list, model.value));

function getValueByKey(values: IStringArray, key: string): string | null {
  if (values.hasOwnProperty(key)) {
    return values[key];
  }

  return null;
}

function handleItemSelect(key: string): void {
  model.value = key;
  isOpen.value = false;
}

const currentValue = ref(getValueByKey(props.list, model.value));
watch(model, (value: string) => {
  currentValue.value = getValueByKey(props.list, value);
  list.value = getInternalListFromProps(props.list, model.value);
});

const isOpen = ref(false);
</script>

<template>

  <div>
    <label v-if="props.label" id="listbox-label" class="block text-sm/[38px] font-normal text-gray-900">
      {{ props.label }}
    </label>
    <div class="relative">
      <button type="button"
              @click="isOpen = !isOpen"
              class="relative w-full cursor-pointer rounded-lg px-2 py-4 bg-[var(--m-neutral-200)] hover:bg-[var(--m-neutral-300)] text-left shadow-sm sm:text-sm sm:leading-6"
              :class="{'text-[var(--m-neutral-800)]': !currentValue, 'text-[var(--m-dark)]': currentValue}"
              aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label">
        <span class="flex items-center">
          <span class="ml-3 block truncate">{{ currentValue || props.placeholder }}</span>
        </span>
        <span class="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
          <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 12" fill="currentColor" aria-hidden="true">
            <path
                d="M10 11.1C9.775 11.1 9.5875 11.025 9.4 10.875L0.775 2.40001C0.4375 2.06251 0.4375 1.53751 0.775 1.20001C1.1125 0.862512 1.6375 0.862512 1.975 1.20001L10 9.03751L18.025 1.12501C18.3625 0.787512 18.8875 0.787512 19.225 1.12501C19.5625 1.46251 19.5625 1.98751 19.225 2.32501L10.6 10.8C10.4125 10.9875 10.225 11.1 10 11.1Z"
                fill="#25262A"/>
          </svg>
        </span>
      </button>


      <!--
        Select popover, show/hide based on select state.

        Entering: ""
          From: ""
          To: ""
        Leaving: "transition ease-in duration-100"
          From: "opacity-100"
          To: "opacity-0"
      -->
      <transition
          enter-active-class="transition-all duration-200 ease-out"
          leave-active-class="transition-all duration-750 ease-in"
          enter-class="opacity-0 scale-75"
          enter-to-class="opacity-100 scale-100"
          leave-class="opacity-100 scale-100"
          leave-to-class="opacity-0 scale-75"
      >
        <ul v-if="isOpen"
            class="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            tabindex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-option-3">
          <!--
            Select option, manage highlight styles based on mouseenter/mouseleave and keyboard navigation.

            Highlighted: "bg-indigo-600 text-white", Not Highlighted: "text-gray-900"
          -->
          <li
              v-for="item in list"
              class="relative cursor-pointer select-none py-2 pl-3 pr-9 text-gray-900 hover:bg-indigo-600 hover:text-white"
              :id="'listbox-option-' + item.key"
              role="option"
              @click="() => handleItemSelect(item.key)"
          >
            <div class="flex items-center">
              <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
              <span class="ml-3 block truncate"
                    :class="{'font-normal': !item.isSelected,'font-semibold': item.isSelected}">
              {{ item.value }}
            </span>
            </div>

            <!--
              Checkmark, only display for selected option.

              Highlighted: "text-white", Not Highlighted: "text-indigo-600"
            -->
            <span v-if="item.isSelected" class="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600">
            <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                    clip-rule="evenodd"/>
            </svg>
          </span>
          </li>
        </ul>
      </transition>

      <button
          type="button"
          v-if="isOpen"
          @click="isOpen = false" class="fixed top-0 left-0 h-full w-full"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>