<script setup lang="ts">

import {ISortDirection} from "../../interfaces/ISortDirection.ts";
import {IconChevronSort, IconChevronSortDown, IconChevronSortUp} from "@iconify-prerendered/vue-carbon";
import {ref, watch} from "vue";

type IProps = {
  sortKey: string;
}

const props = defineProps<IProps>();

const sortBy = defineModel<string>('sortBy', {required: true});
const sortDirection = defineModel<ISortDirection>('sortDirection', {required: true});



function getIconByState(sortKey: string, sortBy: string, sortDirection: string) {
  return sortBy != sortKey
      ? IconChevronSort
      : sortDirection == 'desc'
          ? IconChevronSortDown
          : IconChevronSortUp;
}

const icon = ref(getIconByState(props.sortKey, sortBy.value, sortDirection.value));

function handleClick() {
  if (sortBy.value === props.sortKey) {
    sortDirection.value = (sortDirection.value === 'asc') ? 'desc' : 'asc';
  } else {
    sortBy.value = props.sortKey;
  }
}

watch([
  sortBy,
  sortDirection,
], () => {
  icon.value = getIconByState(props.sortKey, sortBy.value, sortDirection.value);
});

</script>

<template>
  <component
      :is="icon"
      class="w-6 h-6 cursor-pointer"
      @click="handleClick"
  />
</template>

<style scoped lang="scss">

</style>