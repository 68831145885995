<script setup lang="ts">
import { IconCheckmark } from "@iconify-prerendered/vue-carbon";
import { ClientDTO } from "../../../repositories/dto/client/ClientDTO.ts";
import { legalTypeToText } from "../../../utils/utils.ts";
import BasicAvatar from "../../basic/BasicAvatar.vue";

type IProps = {
  client: ClientDTO;
  selected: boolean;
}

const props = defineProps<IProps>();

</script>

<template>
  <div class="rounded-full w-full items-center justify-between inline-flex">
    <div class="self-stretch justify-start items-center gap-3 inline-flex">
      <BasicAvatar :client="props.client" />
      <div class="flex-col justify-start items-start gap-1 inline-flex font-['Poppins'] leading-[38px]">
        <div class="text-[#25262a] text-base font-medium">
          {{ props.client.profile.fullName }}
        </div>
        <div class="text-[#757c89] text-sm font-normal">
          {{ legalTypeToText(props.client.legalType) }}
        </div>
      </div>
    </div>
    <IconCheckmark v-if="props.selected" class="text-[var(--m-green)] w-6 h-6" />
  </div>
</template>