<script setup lang="ts">
import {SVGAttributes} from "vue";

interface IconProps extends/* @vue-ignore */ SVGAttributes {
}

const props = defineProps<IconProps>();
</script>

<template>
  <svg v-bind="props" width="48" height="48" viewBox="0 0 48 48" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M24 0L36 3.21796L44.782 12L48 24L44.782 36L36 44.782L24 48L12 44.782L3.21796 36L0 24L3.21796 12L12 3.21796L24 0ZM13.9837 6.65143L6.65143 13.9837L3.96735 24L6.65143 34.0163L13.9837 41.3486L24 44.0327L34.0163 41.3486L41.3486 34.0163L44.0327 24L41.3486 13.9837L34.0163 6.65143L24 3.96735L13.9837 6.65143Z" fill="currentColor"/>
    <g clip-path="url(#clip0_1266_2778)">
      <path d="M21.0001 32H15V16H21.0001V12H25.0001V16H27.0001C29.7615 16 32.0001 18.2386 32.0001 20.9999C32.0001 22.1255 31.6281 23.1643 31.0005 24C31.6281 24.8358 32.0001 25.8744 32.0001 27C32.0001 29.7616 29.7615 32 27.0001 32H25.0001V36H21.0001V32ZM19.0001 26V28H27.0001C27.5523 28 28.0001 27.5524 28.0001 27C28.0001 26.4478 27.5523 26 27.0001 26H19.0001ZM19.0001 19.9999V21.9999H27.0001C27.5523 21.9999 28.0001 21.5523 28.0001 20.9999C28.0001 20.4477 27.5523 19.9999 27.0001 19.9999H19.0001Z" fill="currentColor"/>
    </g>
    <defs>
      <clipPath id="clip0_1266_2778">
        <rect width="24" height="24" fill="currentColor" transform="translate(12 12)"/>
      </clipPath>
    </defs>
  </svg>

</template>

<style scoped lang="scss"></style>
