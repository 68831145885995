import ISessionRepository from "../interfaces/ISessionRepository.ts";
import { IAuthorization } from "../dto/auth/Authorization.ts";

export default class SessionRepository implements ISessionRepository {
  async info(): Promise<IAuthorization> {
    const auth: IAuthorization = {
      authKeyId: "",
      scope: [],
      isAuthenticated: false,
      next_factor_required: false,
      next_factor_type: null,
      user: null,
      email: null,
      phone: null,
    };

    return Promise.resolve(auth);
  }

  kill(): Promise<boolean> {
    return Promise.resolve(false);
  }

  list(): Promise<boolean> {
    return Promise.resolve(false);
  }

  logout(): Promise<boolean> {
    return Promise.resolve(false);
  }

  ping(): Promise<boolean> {
    return Promise.resolve(false);
  }
}
