<script setup lang="ts">
import { SVGAttributes } from "vue";

interface IconProps extends/* @vue-ignore */ SVGAttributes {
}

const props = defineProps<IconProps>();
</script>

<template>
  <svg
    v-bind="props"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.8827 18.2968C15.1814 19.3755 13.1638 20.0002 11.0003 20.0002C5.60812 20.0002 1.12215 16.1204 0.181641 11.0002C0.618321 8.62283 1.81932 6.5129 3.52047 4.93457L0.393661 1.80777L1.80788 0.393555L21.6069 20.1925L20.1927 21.6068L16.8827 18.2968ZM4.9356 6.3497C3.60673 7.56015 2.6378 9.1672 2.22278 11.0002C3.14022 15.0521 6.7646 18.0002 11.0003 18.0002C12.5997 18.0002 14.112 17.5798 15.4243 16.8384L13.396 14.8101C12.7023 15.2472 11.8808 15.5002 11.0003 15.5002C8.51498 15.5002 6.50026 13.4854 6.50026 11.0002C6.50026 10.1196 6.75317 9.2981 7.19031 8.60442L4.9356 6.3497ZM11.9139 13.328L8.67246 10.0866C8.5613 10.3696 8.50026 10.6777 8.50026 11.0002C8.50026 12.3809 9.6196 13.5002 11.0003 13.5002C11.3227 13.5002 11.6309 13.4391 11.9139 13.328ZM19.8068 15.5925L18.376 14.1617C19.0319 13.2268 19.5154 12.1586 19.7777 11.0002C18.8603 6.94818 15.2359 4.00016 11.0003 4.00016C10.1544 4.00016 9.3329 4.11773 8.55249 4.33818L6.97446 2.76015C8.22127 2.26959 9.5793 2.00016 11.0003 2.00016C16.3924 2.00016 20.8784 5.87993 21.8189 11.0002C21.5067 12.6998 20.8038 14.2628 19.8068 15.5925ZM10.7229 6.50857C10.8146 6.503 10.9071 6.50016 11.0003 6.50016C13.4855 6.50016 15.5003 8.51488 15.5003 11.0002C15.5003 11.0933 15.4974 11.1858 15.4919 11.2775L10.7229 6.50857Z"
      fill="currentColor"
    />
  </svg>
</template>

<style scoped lang="scss"></style>
