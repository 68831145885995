import ISessionRepository from "../interfaces/ISessionRepository.ts";
import { IAuthorization } from "../dto/auth/Authorization.ts";
import { TokenService } from "../../services";
import { getMockSession } from "./AuthRepositoryMock.ts";

export default class SessionRepositoryMock implements ISessionRepository {
  async info(): Promise<IAuthorization> {
    const token = TokenService.getToken();

    const authEmpty: IAuthorization = {
      authKeyId: "",
      scope: [],
      email: null,
      phone: null,
      isAuthenticated: false,
      next_factor_required: false,
      next_factor_type: null,
      user: null
    };

    const auth = getMockSession()!;

    return Promise.resolve(token ? auth : authEmpty);
  }

  kill(): Promise<boolean> {
    return Promise.resolve(false);
  }

  list(): Promise<boolean> {
    return Promise.resolve(false);
  }

  logout(): Promise<boolean> {
    return Promise.resolve(false);
  }

  ping(): Promise<boolean> {
    return Promise.resolve(false);
  }
}
