<script setup lang="ts">
import {storeToRefs} from "pinia";
import {useUserStore} from "../../store";
import {ref, watch} from "vue";
import Header from "./Header.vue";
import Footer from "./Footer.vue";
import XLayout from "./AuthLayout/XLayout.vue";
import {UserDTO} from "../../repositories/dto/user/UserDTO.ts";

const userStore = useUserStore();
const {getUser} = storeToRefs(userStore);

const isXLayout = ref(false);

let interval = null;
watch(getUser, (user: UserDTO | null) => {
  isXLayout.value = user !== null && user.profile !== null;
  console.log("user", user, interval);
});
</script>

<template>
  <Header/>

  <main class="w-[1200px] mx-auto">
    <XLayout v-if="isXLayout">
      <slot/>
    </XLayout>
    <slot v-else/>
  </main>

  <Footer class=""></Footer>
</template>
