//import injectionTarget from "../core/di/DecoratorInjectionTarget";
import type ILedgerRepository from "../repositories/interfaces/ILedgerRepository";
import Container from "../core/di/DependencyContainer.ts";
import BaseService, {RequestError, RequestState} from "./BaseService.ts";
import Currency from "../libs/ledger/Currency.ts";
import {Balance} from "../repositories/dto/ledger/balance/Balance.ts";
import baseCurrencies from "../libs/ledger/BaseCurrencies.ts";

export type GetTransactionsParams = {
    limit?: number;
    page?: number;
    accountId?: string;
    orderBy?: string;
};

export type GetAccountsParams = {
    type?: "fiat" | "crypto";
    full?: boolean;
};

export type GetCurrenciesByCodeParams = {
    currencies?: string[];
};

export type CryptoTicker = {
    code: string;
    value: number;
    change: number;
};

export type TransactionShort = {};

export type List<T> = {
    list: T[];
    meta: {
        records: number;
        currentPage: number;
        lastPage: number;
        minEntry: number;
        maxEntry: number;
    };
};

//@injectionTarget()
export default class LedgerService<T> extends BaseService<T> {
    ledgerRepository: ILedgerRepository;

    constructor(
        //@inject("ledgerRepository") ledgerRepository?: ILedgerRepository
    ) {
        super();
        let ledgerRepository: ILedgerRepository | null = Container.get("ledgerRepository");
        // ensures userRepository exists and no checks for undefined are required throughout the class
        if (ledgerRepository == null) {
            throw Error("No LedgerRepository provided or injected.");
        }
        this.ledgerRepository = ledgerRepository;
    }

    async getBalances(/*inCurrency: string*/) {
    }

    async getTransactions(
        params: GetTransactionsParams
    ): Promise<List<TransactionShort>> {
        const list = await this.ledgerRepository.getTransactions({
            accountId: params.accountId,
            limit: params.limit,
            page: params.page,
            orderBy: params.orderBy
        });
        return {
            list: list.list,
            meta: list.meta
        };
    }

    async getAccounts(/*params: GetAccountsParams*/) {
    }

    async getCryptoTicker(cryptoCode: string): Promise<CryptoTicker> {
        return new Promise((resolve, _) => {
            const ticker: CryptoTicker = {
                code: cryptoCode,
                value: 530000.0,
                change: 3.57
            };
            setTimeout(() => resolve(ticker), 3000);
        });
    }

    async getTotalCryptoBalance(refKey: string, currency: Currency): Promise<void> {
        this.updateRefState(refKey, RequestState.Loading);
        try {
            const balance = await this.ledgerRepository.getTotalBalance('crypto', currency.code);
            const value: Balance = {
                available: balance.availableAmount,
                currency: baseCurrencies.codeToCurrency(balance.currencyCode),
                onhold: balance.onholdAmount,
                total: balance.totalAmount,
            }

            this.updateRefValue(refKey, value);
            this.updateRefState(refKey, RequestState.Ready);
        } catch (e) {
            this.updateRefError(refKey, new RequestError(e.code, e.message));
            this.updateRefState(refKey, RequestState.Failed);
        }
    }
}
