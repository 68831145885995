<script setup lang="ts">

import { IconUser, IconToolBox } from "@iconify-prerendered/vue-carbon";
import { ClientDTO, ClientLegalType } from "../../repositories/dto/client/ClientDTO.ts";
import {computed, ComputedRef} from "vue";

type IProps = {
  client: ClientDTO;
  size?: "big" | "small"
};

const props = withDefaults(defineProps<IProps>(), {
  size: "small"
});

const image: ComputedRef<string | null> = computed(() => props.client.id === "1"
  ? "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
  : null
);
const classes = props.size === 'small' ? 'w-12 h-12' : 'w-[90px] h-[90px]';
</script>

<template>
  <div :class="classes">
    <img :src="image" :class="classes" class="rounded-full" v-if="image" :alt="client.id" />
    <IconUser class="w-6 h-6" v-else-if="props.client.legalType === ClientLegalType.natural" />
    <IconToolBox class="w-6 h-6" v-else />
  </div>
</template>

<style scoped lang="scss">
div {
  @apply rounded-full flex justify-center items-center select-none font-bold text-2xl cursor-pointer;
  background-color: #edeff2;
  color: var(--m-dark);
}
</style>
