<script setup lang="ts">
import { onBeforeUpdate, onMounted, ref, Ref, watch } from "vue";
import { useFocus, onStartTyping } from "@vueuse/core";

type IProps = {
  default?: string;
  length: number;
  isError?: boolean;
}
const props = withDefaults(defineProps<IProps>(), {
  length: 1,
  default: "",
  isError: false
});

const refs: any[] = [];
const models: Ref<number | null>[] = [];
const focuses: Ref<any>[] = [];

const model = defineModel<string>();

for (let i = 0; i < props.length; i++) {
  let _def = props.default.length > i
    ? parseInt(props.default.charAt(i), 10)
    : null;
  models[i] = ref(_def);
}

onStartTyping(() => {
  if (!focuses[0].value.active) {
    focuses[0].value.focus();
  }
});

watch(models, (vals) => {
  const code = vals.join("").trim();
  model.value = code;
});

const handleKeyDown = (event: KeyboardEvent, index: number) => {
  if (event.key !== "Tab" &&
    event.key !== "ArrowRight" &&
    event.key !== "ArrowLeft"
  ) {
    event.preventDefault();
  }

  if (event.key === "Backspace") {
    models[index].value = null;

    if (index != 0) {
      focuses[index - 1].value = true;
    }

    return;
  }

  if ((new RegExp("^([0-9])$")).test(event.key)) {
    models[index].value = parseInt(event.key, 10);

    if (index != props.length - 1) {
      focuses[index + 1].value = true;
    }
  }
};

onMounted(() => {
  for (let i in refs) {
    let { focused } = useFocus(refs[i], { initialValue: parseInt(i) === 0 });
    focuses[i] = focused;
  }
});
onBeforeUpdate(() => {
  while (refs.length > 0) {
    refs.pop();
  }
});
</script>

<template>
  <div class="flex gap-3">
    <div
      v-for="(_, ind) in models"
      class="flex justify-center items-center"
    >
      <input
        @keydown="(event) => handleKeyDown(event, ind)"
        :ref="el => { refs[ind] = el; }"
        class="w-[54px] h-[54px] self-stretch px-4 py-2 bg-gray-100 rounded-lg text-center"
        type="number"
        :class="{ 'error': props.isError }"
        :key="ind"
        v-model.number="models[ind].value"
        :autofocus="ind === 0"
        :placeholder="(ind + 1) + ''"
        maxlength="1"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.error {
  border: 1px solid var(--m-red);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  caret-color: transparent;
}
</style>