import { defineStore } from "pinia";
import type { Ref } from "vue";
import {computed, ComputedRef, ref, watch} from "vue";
import { UserDTO } from "../repositories/dto/user/UserDTO.ts";
import UserService from "../services/user.service.ts";
import { IUserState, IUserStep } from "../repositories/dto/user/IUserState.ts";
import { IChallengeResponse } from "../repositories/dto/user/IChallengeResponse.ts";
import { ISolutionResponse } from "../repositories/dto/user/ISolutionResponse.ts";
import { IRepositoryError } from "../repositories/dto/errors/IRepositoryError.ts";
import { useStorage } from "@vueuse/core";
import {IAuthorization} from "../repositories/dto/auth/Authorization.ts";
import {AuthService, TokenService} from "../services";
import SessionService from "../services/session.service.ts";

export type UserStoreState = {
  user: Ref<UserDTO | null>;
  steps: Ref<IUserStep[]>;
  currentStep: Ref<string | null>;
  auth: Ref<IAuthorization | null>;
  isAuthenticated: Ref<boolean>;
  error: Ref<Error | null>;
};

export const useUserStore = defineStore("user", () => {
  const userService = new UserService();
  const currentClientUuid: Ref<string | null> = useStorage("currentClientUuid", null);
  const authService = new AuthService();
  const sessionService = new SessionService();

  console.log("useUserStore.constructed");

  // State
  const state: UserStoreState = {
    user: ref(null),
    steps: ref([]),
    currentStep: ref(null),
    auth: ref(null),
    isAuthenticated: ref(false),
    error: ref(null)
  };

  watch(state.auth, () => {
    const token = state.auth.value?.authKeyId;

    if (token === null) {
      TokenService.removeToken();
    } else {
      TokenService.saveToken(token!);
    }
  });

  // Getters
  const getters: {
    getUser: ComputedRef<UserDTO | null>;
    getCurrentStep: ComputedRef<string | null>;
    currentClientUuid: Ref<string | null>;
    getAuthKeyId: ComputedRef<string | null>;
    getIsAuthenticated: ComputedRef<boolean>;
    getScope: ComputedRef<string[] | null>;
    getNextFactorRequired: ComputedRef<boolean>;
    getNextFactorType: ComputedRef<string | null>;
  } = {
    getUser: computed(() => state.user.value),
    getCurrentStep: computed(() => state.currentStep.value),
    currentClientUuid: currentClientUuid,
    getAuthKeyId: computed(() => state.auth.value?.authKeyId || null),
    getIsAuthenticated: computed(() => {
      return state.user.value != null;
    }),
    getScope: computed(() => state.auth.value?.scope || null),
    getNextFactorRequired: computed(
        () => state.auth.value?.next_factor_required || false
    ),
    getNextFactorType: computed(
        () => state.auth.value?.next_factor_type || null
    )
  };

  // Actions
  function _registerEvents() {
  }

  async function init() {

    //await AuthService.init();
    _registerEvents();
    await getState();
  }

  function _mapUserState(userState: IUserState | null): void {
    if (userState !== null) {
      state.steps.value = userState.steps ?? [];
      state.currentStep.value = userState.currentStep ?? null;
      state.user.value = userState.person ?? null;
    }
  }


  async function signIn(email: string, password: string) {
    const auth = await authService.signIn(email, password);

    if (auth instanceof Error) {
      state.error.value = auth;
    } else {
      _mapAuthorization(auth);
      await this.getState();
    }
  }

  function _mapAuthorization(auth: IAuthorization | null): void {
    state.auth.value = auth;
    state.isAuthenticated.value = auth?.isAuthenticated ?? false;
    state.error.value = null;
  }

  async function signUp(email: string, password: string): Promise<void> {
    const auth = await authService.signUp(email, password);

    if (auth instanceof Error) {
      console.log('Error signUp', auth)
      state.error.value = auth;
    } else {
      await this.getState();
    }
  }

  async function logout() {
    await authService.logout();
    await this.getState();
  }

  async function getState() {
    console.log("userStore", "getState");
    const userState = await userService.getState();
    console.log("userStore", "userState", userState);
    _mapUserState(userState);

    const auth = await sessionService.info();

    _mapAuthorization(auth);
  }

  async function getChallenge(challengeType: string, challengeCode: string | null = null): Promise<IChallengeResponse | IRepositoryError> {
    return await userService.getChallenge(challengeType, challengeCode);
  }

  async function verifySolution(challengeCode: string, solutionCode: string): Promise<ISolutionResponse | IRepositoryError> {
    const result = await userService.verifySolution(challengeCode, solutionCode);
    await this.getState();
    return result;
  }

  async function addPhone(countryCode: string, phone: string): Promise<IChallengeResponse | IRepositoryError> {
    const result = await userService.addPhone(countryCode, phone);
    await this.getState();
    return result;
  }

  return {
    // State
    ...state,

    // Getters
    ...getters,

    // Actions
    _registerEvents,
    init,
    getState,
    addPhone,
    getChallenge,
    verifySolution,
    logout,
    signIn,
    signUp
  };
});

export default useUserStore;
