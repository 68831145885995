<script setup lang="ts">

import {IconCopy} from "@iconify-prerendered/vue-fa";
import {useClipboard} from '@vueuse/core'
import {watch} from "vue";
import {success} from "../utils/toast.ts";


type Props = {
  toCopy: string;
};

const props = defineProps<Props>();

const {text, copy, copied, isSupported} = useClipboard()

watch(copied, (isCopied: boolean) => {
  if (isCopied) {
    success('Copied to clipboard: ' + text.value);
  }
});

const copyToClipboard = () => {
  if (isSupported) {
    copy(props.toCopy);
  }
};
</script>

<template>
  <Button @click="copyToClipboard">
    <div class="flex gap-2 items-center">
      <slot/>
      <IconCopy class="inline"/>
    </div>
  </Button>
</template>

<style scoped lang="scss">

</style>