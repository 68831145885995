<script lang="ts">
import { RouteNames } from "../../../../utils/constants.ts";

export function getRouteXAccountsCryptoBuy() {
  return {
    name: RouteNames.X_BUY_CRYPTO,
  };
}
</script>
<script setup lang="ts">
import BreadCrumbs from "../../../../components/BreadCrumbs.vue";
</script>

<template>
  <BreadCrumbs :title="$t('Buy Crypto')" class="mb-5"></BreadCrumbs>
</template>

<style scoped lang="scss"></style>
